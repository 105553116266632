import "core-js/modules/es.json.stringify.js";

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _vm.goodsList.length ? _c("div", {
    staticClass: "com-goods-gallery mt2"
  }, [_c("van-swipe", {
    attrs: {
      "indicator-color": "#D9D9D9",
      "show-indicators": "",
      loop: "",
      width: "100%"
    }
  }, _vm._l(_vm.goodsList, function (goods, index) {
    return _c("van-swipe-item", {
      key: index,
      staticClass: "grid grid-cols-3 gap-1 justify-between"
    }, _vm._l(goods, function (goodsItem, itemIndex) {
      return _c("div", {
        directives: [{
          name: "track",
          rawName: "v-track:exposure",
          arg: "exposure"
        }],
        key: itemIndex,
        staticClass: "flex justify-center goods-item",
        attrs: {
          "track-params": JSON.stringify(_vm.getEventInfo(goodsItem, itemIndex, index))
        },
        on: {
          click: function click($event) {
            return _vm.goodClick(goodsItem, itemIndex, index);
          }
        }
      }, [_c("div", [_c("div", {
        staticClass: "relative flex justify-center items-center"
      }, [_c("img", {
        attrs: {
          src: goodsItem.imgUrl,
          alt: ""
        }
      }), goodsItem.stockNum <= 0 ? _c("img", {
        staticClass: "dw_img",
        attrs: {
          src: require("base/assets/img/dw.png")
        }
      }) : _vm._e()]), _c("div", {
        staticClass: "van-ellipsis fs14 mt1 goods-name"
      }, [_vm._v(_vm._s(goodsItem.goodsName))]), _c("div", {
        staticClass: "flex fs13 color_primary"
      }, [_vm._v(_vm._s(goodsItem.price) + "元")])])]);
    }), 0);
  }), 1)], 1) : _c("div", [_vm._v("暂无推荐")]);
};

var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };