var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("transition", {
    attrs: {
      name: "fade"
    }
  }, [_vm.visible ? _c("div", {
    staticClass: "mask",
    on: {
      touchmove: function touchmove($event) {
        $event.preventDefault();
      }
    }
  }, [_c("div", {
    staticClass: "dialog-content flex flex-column justify-center items-center"
  }, [_c("img", {
    attrs: {
      width: "103.5",
      height: "64.5",
      src: require("base/assets/img/get_coupon_success.png")
    }
  }), _c("div", {
    staticClass: "title"
  }, [_vm._v("领取成功")]), _c("div", {
    staticClass: "subtitle"
  }, [_vm._v("正在跳转中...")])])]) : _vm._e()]);
};

var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };