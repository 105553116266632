import _defineProperty from "/root/workspace/dev-client-a/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "vant/es/tabbar-item/style";
import _TabbarItem from "vant/es/tabbar-item";
import "vant/es/tabbar/style";
import _Tabbar from "vant/es/tabbar";

var _components;

import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.find-index.js";
import { trackEvent } from 'base/utils/rp';
import { IconFont } from 'components';
import utils from 'base/utils';
import { sendQuickTrackingEvent, EventType } from 'base/utils/quickTracking';
export default {
  name: 'ActivityFootBar',
  components: (_components = {}, _defineProperty(_components, _Tabbar.name, _Tabbar), _defineProperty(_components, _TabbarItem.name, _TabbarItem), _defineProperty(_components, "IconFont", IconFont), _components),
  props: {
    midTabbar: {
      type: Object,
      default: function _default() {}
    },
    activeColor: {
      type: String,
      default: ''
    },
    inactiveColor: {
      type: String,
      default: ''
    },
    labelColor: {
      type: String,
      default: function _default() {
        return '#ff2841';
      }
    }
  },
  data: function data() {
    return {
      footBarActive: this.$route.path,
      tabbarList: [{
        path: '/home',
        text: '首页',
        icon: 'iconshouye'
      }, this.midTabbar, {
        path: '/primary',
        text: this.renderBrandElement({
          id: 'primary.tabbarName'
        }),
        icon: this.renderBrandElement({
          id: 'primary.tabbarIcon'
        })
      }]
    };
  },
  created: function created() {},
  updated: function updated() {
    this.footBarActive = this.$route.path;
  },
  methods: {
    onTabChange: function onTabChange(path) {
      var tabIndex = this.tabbarList.findIndex(function (tab) {
        return tab.path === path;
      });
      var tab = this.tabbarList[tabIndex];
      var tabName = tab && tab.text;
      trackEvent({
        category: "\u70B9\u51FB-\u5E95\u90E8\u5BFC\u822A-".concat(tabName)
      });
    },
    onTabClick: function onTabClick(item, index) {
      if (index === 1) {
        utils.scroll('body', 150);
      }

      var midTabbar = this.midTabbar;
      sendQuickTrackingEvent({
        eventName: 'ducuecmc_hc_bottom_tabbar_clk',
        eventType: EventType.Click,
        pageName: 'dacuecmc_all_page',
        params: {
          tab_name: item.text,
          module_name: midTabbar.text
        }
      });
    }
  }
};