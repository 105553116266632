import "core-js/modules/es.number.to-fixed.js";
import "core-js/modules/es.number.constructor.js";

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "point-center-banner relative"
  }, [_c("div", {
    staticClass: "banner relative",
    on: {
      click: _vm.clickBanner
    }
  }, [_c("img", {
    attrs: {
      src: _vm.topBanner,
      alt: ""
    }
  }), _c("div", {
    staticClass: "buttons absolute flex flex-row justify-between mx2"
  }, [_c("div", {
    staticClass: "button white",
    on: {
      click: function click($event) {
        return _vm.trackEventBannerClkHandler("正品保障");
      }
    }
  }, [_vm._v("正品保障")]), _c("div", {
    staticClass: "button white",
    on: {
      click: function click($event) {
        return _vm.trackEventBannerClkHandler("七天无理由");
      }
    }
  }, [_vm._v("七天无理由")]), _c("div", {
    staticClass: "button white",
    on: {
      click: function click($event) {
        return _vm.trackEventBannerClkHandler("退换无忧");
      }
    }
  }, [_vm._v("退换无忧")]), _c("div", {
    staticClass: "flex flex-row items-center justify-between mx2",
    on: {
      click: function click($event) {
        $event.stopPropagation();
        return _vm.showRulePopup("规则");
      }
    }
  }, [_c("div", {
    staticClass: "button-rule white"
  }, [_vm._v("规则")]), _vm._m(0)])])]), _c("div", {
    staticClass: "point"
  }, [_c("div", {
    staticClass: "point-bg px2 pt2"
  }, [_c("div", {
    staticClass: "point-card p-[10px]"
  }, [!_vm.topPointShowMoney ? _c("div", {
    staticClass: "point-area flex justify-between items-center"
  }, [_c("div", {
    staticClass: "flex flex-col items-center"
  }, [_vm.points.activePoints !== null ? _c("div", {
    staticClass: "text-[#E54635] text-[22px] font-semibold"
  }, [_c("span", [_vm._v(_vm._s(_vm.points.activePoints))]), _c("span", {
    staticClass: "fs12 font-normal text-[#4d4d4d] ml-[2px]"
  }, [_vm._v(_vm._s(_vm.globalPointName))])]) : _vm._e(), _c("div", {
    staticClass: "text-14 text-[#999]"
  }, [_vm._v("剩下的可用")])]), _c("div", {
    staticClass: "vertical-line"
  }), _c("div", {
    staticClass: "flex flex-col items-center"
  }, [_vm.points.activePoints !== null ? _c("div", {
    staticClass: "text-[#E54635] text-[22px] font-semibold"
  }, [_c("span", [_vm._v(_vm._s((_vm.points.activePoints / 100).toFixed(2)))]), _c("span", {
    staticClass: "fs12 font-normal text-[#4d4d4d] ml-[2px]"
  }, [_vm._v("元")])]) : _vm._e(), _c("div", {
    staticClass: "text-14 text-[#999]"
  }, [_vm._v("预计省")])]), _c("div", {
    staticClass: "vertical-line"
  }), _c("div", {
    staticClass: "exchange-button flex items-center text-[14px]",
    on: {
      click: _vm.toBuy
    }
  }, [_vm._v("点击" + _vm._s(_vm.globalExchangeWord))])]) : _c("div", {
    staticClass: "point-area flex justify-between items-center"
  }, [_c("div", {
    staticClass: "flex flex-col"
  }, [_vm.points.activePoints !== null ? _c("div", {
    staticClass: "text-[#E54635] text-[28px] font-semibold"
  }, [_c("span", [_vm._v(_vm._s(_vm.points.activePoints))]), _c("span", {
    staticClass: "text-[16px] text-[#333333] font-semibold ml-[4px]"
  }, [_vm._v(_vm._s(_vm.globalPointName))])]) : _vm._e()]), _c("div", {
    staticClass: "exchange-button flex items-center text-[18px]",
    on: {
      click: _vm.toBuy
    }
  }, [_vm._v("立即" + _vm._s(_vm.globalExchangeWord))])]), _vm.renderBrandElement({
    id: "pointCenter.tipText"
  }) ? _c("div", {
    staticClass: "mt1 flex color_text fs14 items-center mx2 countdown"
  }, [_vm._v(" " + _vm._s(_vm.renderBrandElement({
    id: "pointCenter.tipText"
  })) + " ")]) : _c("div", {
    staticClass: "notice-bar flex items-center justify-between px2 mt1"
  }, [_c("div", {
    staticClass: "flex items-center w-full"
  }, [_c("div", {
    staticClass: "text-[#E54635] font-semibold fs14 w-[21%]"
  }, [_vm._v("通知:")]), _c("div", {
    staticClass: "w-[76%]"
  }, [_c("NoticeBar", {
    attrs: {
      "enable-vertical-scroll": false,
      speed: 60,
      scrollable: true,
      "left-icon": "",
      "notice-list": _vm.pointNotice,
      "notice-bar-style": {
        height: "28px",
        fontSize: "12px",
        width: "100%",
        background: "#ffffff",
        color: "#4D4D4D",
        padding: "0px"
      }
    }
  })], 1)]), _c("count-down", {
    attrs: {
      "end-time": Number(_vm.countDownTime),
      "bg-color": "#E54635",
      "colon-color": "#E54635"
    }
  })], 1)])])])]);
};

var staticRenderFns = [function () {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "icon-more"
  }, [_c("img", {
    attrs: {
      src: require("base/assets/img/icon/icon-more@2x.png"),
      alt: ""
    }
  })]);
}];
render._withStripped = true;
export { render, staticRenderFns };