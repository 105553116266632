var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "com-section-title flex items-center"
  }, [_c("div", {
    staticClass: "flex-auto flex bold"
  }, [_c("div", {
    staticClass: "dot"
  }), _c("div", {
    staticClass: "title fs16"
  }, [_vm._v(_vm._s(_vm.title))])]), _vm._t("default")], 2);
};

var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };