export default {
  name: 'GetCouponDialog',
  props: {},
  data: function data() {
    return {
      visible: true
    };
  },
  computed: {},
  created: function created() {
    var _this = this;

    setTimeout(function () {
      _this.hide();

      _this.onClose();
    }, this.duration || 1000);
  },
  methods: {
    closeDialog: function closeDialog() {
      this.visible = false;
    },
    hide: function hide() {
      this.visible = false;
    }
  }
};