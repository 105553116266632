import _objectSpread from "/root/workspace/dev-client-a/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.json.stringify.js";
import { trackUserEvent } from 'base/utils/rp';
import { ImageThemed } from 'components'; // import ImageThemed from 'components/ImageThemed.vue'

import xEnum from 'base/utils/enum';
import { mapState } from 'vuex';
export default {
  name: 'GoodsNormalItem',
  components: {
    ImageThemed: ImageThemed
  },
  props: {
    itemStyle: {
      type: Object,
      default: function _default() {}
    },
    source: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    index: {
      type: Number,
      default: 0
    },
    pageSource: {
      type: Number,
      default: function _default() {
        return xEnum.ENUM_PAGE_SOURCE.UNKNOWN.getValue();
      }
    },
    showRankTag: {
      type: Boolean,
      default: function _default() {
        return false;
      }
    },
    onClickItem: {
      type: Function,
      default: function _default() {}
    }
  },
  data: function data() {
    return {
      isAnimated: false,
      goods: {},
      activeIndex: 0
    };
  },
  computed: _objectSpread({}, mapState({
    pId: function pId(state) {
      return state.appState.pId;
    },
    userId: function userId(state) {
      return state.user.userId;
    },
    phone: function phone(state) {
      return state.user.phone;
    },
    openid: function openid(state) {
      return state.user.openid;
    },
    unionId: function unionId(state) {
      return state.user.unionId;
    },
    webFingerPrint: function webFingerPrint(state) {
      return state.user.webFingerPrint;
    }
  })),
  watch: {
    activeIndex: {
      handler: function handler(index) {
        this.goods = this.source.goodsList[index];
      }
    }
  },
  mounted: function mounted() {
    var _this = this;

    this.$refs.container.addEventListener('animationend', function () {
      _this.isAnimated = true;
    });
  },
  methods: {
    goGoodsDetail: function goGoodsDetail(goods) {
      console.log('点击推荐商品', goods);
      var eventInfo = {
        eventType: xEnum.ENUM_RP_EVENT_TYPE.RECOMMEND_GOODS_CLICK.value,
        pointId: xEnum.ENUM_WEBFUNNY_POINT_ID.GOODS_EXPOSE.value,
        eventInfo: {
          pId: this.pId,
          userId: this.userId,
          goodsId: goods.id,
          dynamicRecommendId: goods.dynamicRecommendId,
          goodsPrice: goods.price,
          score: goods.score,
          goodsGroupId: this.type,
          webUrl: window.location.href,
          index: (this.source.i + 1) * (this.source.j + 1) - 1
        }
      };
      trackUserEvent(eventInfo);
      this.$router.push({
        path: "/goodsProfile/".concat(goods.id),
        query: {
          type: 27,
          dynamicRecommendId: goods.dynamicRecommendId
        }
      });
    },
    getEventInfo: function getEventInfo(goods) {
      return JSON.stringify({
        eventType: xEnum.ENUM_RP_EVENT_TYPE.RECOMMEND_GOODS_EP.value,
        pointId: xEnum.ENUM_WEBFUNNY_POINT_ID.GOODS_EXPOSE.value,
        eventInfo: {
          pId: this.pId,
          userId: this.userId,
          goodsId: goods.id,
          dynamicRecommendId: goods.dynamicRecommendId,
          goodsPrice: goods.price,
          score: goods.score,
          goodsGroupId: this.type,
          webUrl: window.location.href,
          index: (this.source.i + 1) * (this.source.j + 1) - 1
        }
      });
    }
  }
};