var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("transition", {
    attrs: {
      name: "fade"
    }
  }, [_vm.visible ? _c("div", {
    staticClass: "mask",
    on: {
      touchmove: function touchmove($event) {
        $event.preventDefault();
      },
      click: _vm.clickDialog
    }
  }, [_c("div", {
    staticClass: "dialog-content relative"
  }, [_c("img", {
    staticClass: "image",
    attrs: {
      src: "".concat(_vm.imgBaseUrl, "/875135802277888")
    }
  }), _c("div", {
    staticClass: "text bold absolute"
  }, [_vm._v(" 再次获得 "), _c("span", {
    staticStyle: {
      color: "#f33805"
    }
  }, [_vm._v(_vm._s(_vm.morePoints))]), _vm._v(" 分 ")]), _c("div", {
    staticClass: "close-btn",
    attrs: {
      id: "close"
    },
    on: {
      click: _vm.closeDialog
    }
  }, [_c("van-icon", {
    attrs: {
      size: "30",
      name: "close"
    }
  })], 1)])]) : _vm._e()]);
};

var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };