var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isVisible,
      expression: "isVisible"
    }],
    staticClass: "com-back-top flex items-center center justify-center",
    on: {
      click: _vm.goTop
    }
  }, [_c("IconFont", {
    attrs: {
      size: "25px",
      icon: "iconi-back-top",
      color: "#DDDDDD"
    }
  })], 1);
};

var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };