var recommend = {
  state: {
    insertIndex: -1,
    cacheGoods: {},
    cacheIndex: -1,
    needTriggerRecommend: false
  },
  mutations: {
    CACHE_GOODS: function CACHE_GOODS(state, goods) {
      state.cacheGoods = goods;
    },
    CACHE_INDEX: function CACHE_INDEX(state, index) {
      state.cacheIndex = index;
    },
    SET_NEED_TRIGGER_RECOMMEND: function SET_NEED_TRIGGER_RECOMMEND(state, bool) {
      state.needTriggerRecommend = bool;
    }
  },
  actions: {}
};
export default recommend;