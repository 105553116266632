import "core-js/modules/es.number.constructor.js";

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _vm.isShow ? _c("div", {
    staticClass: "deal-recent mx2 px2 py2"
  }, [_vm.activityType !== "drawPrize" && _vm.activityType !== "payOnDeliveryFreeReceive" ? _c("div", {
    staticClass: "count-down-container flex justify-between items-center mb2"
  }, [_c("div", [_vm.activityType === "singleProduct" ? _c("div", {
    staticClass: "bold fs20"
  }, [_vm._v("用户评价")]) : _c("div", {
    staticClass: "count-down-container-title color_title bold"
  }, [_vm._v(" 厂家直供，仅剩 "), _c("span", {
    staticClass: "color_primary"
  }, [_vm._v(" " + _vm._s(_vm.goods.goodsDetailEx.stockNum) + " ")]), _vm._v("件 ")])]), _vm.timeLimitSwitch ? _c("div", {
    staticClass: "flex"
  }, [_c("div", {
    staticClass: "flex items-center"
  }, [_c("IconFont", {
    staticClass: "mr1",
    attrs: {
      size: "14px",
      icon: "iconnaozhong",
      color: "#df4b2e"
    }
  }), _c("span", {
    staticClass: "mr1 title color_primary fs12"
  }, [_vm._v("限时兑换")])], 1), _c("CountDown", {
    staticClass: "count-down",
    attrs: {
      "bg-color": "#FFCCCC",
      "colon-color": "#E54635",
      "font-color": "#FFFFFF",
      "block-style": {
        background: "#E54635",
        padding: "1px 3px"
      },
      "colon-style": {
        margin: "0 1px"
      },
      "end-time": Number(_vm.countDownEnd)
    }
  })], 1) : _vm._e()]) : _vm._e(), _c("div", [_vm.visible ? _c("van-swipe", {
    ref: "swipe",
    staticStyle: {
      width: "100%",
      margin: "0 auto",
      padding: "0 0"
    },
    style: {
      height: _vm.activityType === "singleProduct" ? "5.8rem" : "3.4rem"
    },
    attrs: {
      "show-indicators": false,
      autoplay: 3000,
      vertical: true,
      touchable: false
    }
  }, _vm._l(_vm.sourceList, function (item, index) {
    return _c("van-swipe-item", {
      key: index
    }, [_vm.activityType === "singleProduct" ? _c("div", {
      staticClass: "com-deal-recent",
      staticStyle: {
        height: "140px",
        "margin-bottom": "5px"
      }
    }, [_c("div", {
      staticClass: "deep_black flex flex-column",
      staticStyle: {
        "font-size": "0.56rem"
      }
    }, [_c("div", {
      staticClass: "flex items-center"
    }, [_c("img", {
      staticClass: "userIcon mr2",
      attrs: {
        src: item[0].userLogo,
        alt: ""
      }
    }), _c("span", {
      staticClass: "fs17 bold"
    }, [_vm._v(_vm._s(item[0].userName))])]), _c("div", {
      staticClass: "flex items-center",
      staticStyle: {
        height: "35px"
      }
    }, [_c("span", {
      staticClass: "deal-news ml4 text-ellipsis fs15"
    }, [_vm._v(_vm._s(item[0].content))])])]), _c("div", {
      staticClass: "deep_black flex flex-column",
      staticStyle: {
        "font-size": "0.56rem"
      }
    }, [_c("div", {
      staticClass: "flex items-center"
    }, [_c("img", {
      staticClass: "userIcon mr2",
      attrs: {
        src: item[1].userLogo,
        alt: ""
      }
    }), _c("span", {
      staticClass: "fs17 bold"
    }, [_vm._v(_vm._s(item[1].userName))])]), _c("div", {
      staticClass: "flex items-center",
      staticStyle: {
        height: "35px"
      }
    }, [_c("span", {
      staticClass: "deal-news ml4 text-ellipsis fs15"
    }, [_vm._v(_vm._s(item[1].content))])])])]) : _c("div", {
      staticClass: "com-deal-recent",
      staticStyle: {
        height: "3.4rem"
      }
    }, [_c("div", {
      staticClass: "deep_black flex justify-between items-center",
      staticStyle: {
        "font-size": "0.56rem"
      }
    }, [_c("div", {
      staticClass: "flex items-center"
    }, [_c("img", {
      staticClass: "userIcon mr2 flex-none",
      attrs: {
        src: item[0].avatars,
        alt: ""
      }
    }), _c("div", {
      staticClass: "fs12"
    }, [_c("span", {
      staticClass: "deal-news"
    }, [_vm._v(_vm._s(item[0].desc) + _vm._s(_vm.purchaseWord) + "了该商品")]), _vm.activityType !== "drawPrize" ? _c("span", [_vm._v(" ,省了" + _vm._s(Number(_vm.goods.goodsDetailEx.originalPrice) - Number(_vm.goods.goodsDetailEx.price)) + "元 ")]) : _vm._e()])]), _vm.activityType !== "drawPrize" ? _c("div", {
      staticClass: "newestBuy fs14",
      on: {
        click: _vm.toBuy
      }
    }, [_vm._v(" 去" + _vm._s(_vm.purchaseWordSub) + " ")]) : _vm._e()]), _c("div", {
      staticClass: "deep_black flex justify-between items-center mt2",
      staticStyle: {
        "font-size": "0.56rem"
      }
    }, [_c("div", {
      staticClass: "flex items-center"
    }, [_c("img", {
      staticClass: "userIcon mr2 flex-none",
      attrs: {
        src: item[1].avatars,
        alt: ""
      }
    }), _c("div", {
      staticClass: "fs12"
    }, [_c("span", {
      staticClass: "deal-news"
    }, [_vm._v(_vm._s(item[1].desc) + _vm._s(_vm.purchaseWord) + "了该商品")]), _vm.activityType !== "drawPrize" ? _c("span", [_vm._v(" ,省了" + _vm._s(Number(_vm.goods.goodsDetailEx.originalPrice) - Number(_vm.goods.goodsDetailEx.price)) + "元 ")]) : _vm._e()])]), _vm.activityType !== "drawPrize" ? _c("div", {
      staticClass: "newestBuy fs14",
      on: {
        click: _vm.toBuy
      }
    }, [_vm._v(" 去" + _vm._s(_vm.purchaseWordSub) + " ")]) : _vm._e()])])]);
  }), 1) : _vm._e()], 1)]) : _vm._e();
};

var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };