import Vue from 'vue';
import PaySuccessfulDialog from './PaySuccessfulDialog';
var Customized = Vue.extend(PaySuccessfulDialog);
export var paySuccessfulDialog = function paySuccessfulDialog(option) {
  var instance = new Customized({
    data: function data() {
      return {
        onClick: typeof option.onClick === 'function' ? option.onClick : function () {}
      };
    }
  }).$mount(); // console.log(instance.$el);

  document.body.appendChild(instance.$el);
};