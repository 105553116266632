import Vue from 'vue';
import GoodsProfilePointDialog from './goodsProfilePointDialog';
var Customized = Vue.extend(GoodsProfilePointDialog);
export var goodsProfilePointDialog = function goodsProfilePointDialog(option) {
  var instance = new Customized({
    data: function data() {
      return {
        onClick: typeof option.onClick === 'function' ? option.onClick : function () {}
      };
    }
  }).$mount(); // console.log(instance.$el);

  document.body.appendChild(instance.$el);
};