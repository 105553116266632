import "core-js/modules/es.json.stringify.js";

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "com-home-swipe-container"
  }, [_c("div", [_c("van-swipe", {
    staticClass: "my-swipe",
    attrs: {
      loop: true,
      "indicator-color": "white"
    }
  }, _vm._l(_vm.swipeItems, function (item, index) {
    return _c("van-swipe-item", {
      directives: [{
        name: "track",
        rawName: "v-track:exposure",
        arg: "exposure"
      }],
      key: item.id,
      attrs: {
        "track-params": JSON.stringify(_vm.getEventInfo(item, index))
      },
      on: {
        click: function click($event) {
          return _vm.swipeClick(item, index);
        }
      }
    }, [_c("ImageThemed", {
      staticStyle: {
        height: "275px"
      },
      attrs: {
        "image-class-name": "sbImg1",
        src: item.img
      }
    })], 1);
  }), 1)], 1)]);
};

var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };