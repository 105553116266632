var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "container",
    on: {
      click: _vm.onClick
    }
  }, [_c("van-image", {
    staticClass: "In_c_Img",
    attrs: {
      src: _vm.source.imgUrl,
      "lazy-load": true
    },
    scopedSlots: _vm._u([{
      key: "loading",
      fn: function fn() {
        return [_c("ImageThemed", {
          attrs: {
            width: 100,
            "image-id": "goodsNormalItem.loading"
          }
        })];
      },
      proxy: true
    }])
  }), _vm.source.stockNum <= 0 ? _c("img", {
    staticClass: "dw_img",
    attrs: {
      src: require("base/assets/img/dw.png")
    }
  }) : _vm._e(), _c("div", {
    staticClass: "goods-info flex flex-column pt2"
  }, [_c("div", {
    staticClass: "name van-ellipsis"
  }, [_vm._v(_vm._s(_vm.source.goodsName))]), _c("div", {
    staticClass: "flex items-end"
  }, [_c("div", {
    staticClass: "color_primary bold mr2"
  }, [_c("span", {
    staticClass: "point"
  }, [_vm._v("补贴价")]), _c("span", {
    staticClass: "orginal-price"
  }, [_vm._v("￥" + _vm._s(_vm.source.price) + "元")])])]), _c("div", {
    staticClass: "goods-info-price flex"
  }, [_c("div", {
    staticClass: "sale-desc"
  }, [_vm._v(_vm._s(_vm._f("formatSaleDesc")(_vm.source.saleDesc)))]), _c("div", {
    staticClass: "price"
  }, [_vm._v("￥" + _vm._s(_vm.source.originalPrice))])])])], 1);
};

var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };