export default {
  name: 'IconFont',
  props: {
    msg: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    width: {
      type: String,
      default: ''
    },
    height: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: '14px'
    }
  }
};