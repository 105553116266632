import "core-js/modules/es.object.to-string.js";
// vm.$mount(el) => document.body.appendChild();
// Vue.extend() 继承 【子类继承父类的方法】
// 父类 Vue--Vue.extend--子类 定制化的 Vue 构造函数
import Vue from 'vue';
import PresentPointDialog from './presentPointDialog';
import store from '@/store';
var Customized = Vue.extend(PresentPointDialog);
export var presentPointDialog = function presentPointDialog() {
  var option = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return new Promise(function (resolve, reject) {
    var res = new Customized({
      data: function data() {
        return {
          point: option.point || 6000,
          // pointName: option.pointName || '积分',
          onClick: typeof option.onClick === 'function' ? option.onClick : function () {},
          isPresentAgain: option.isPresentAgain || false
        };
      },
      methods: {
        resolve: resolve,
        reject: reject
      },
      store: store
    }).$mount(); // console.log(res.$el);

    document.body.appendChild(res.$el);
  });
};