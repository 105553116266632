import { render, staticRenderFns } from "./HomeKingKongThemed.vue?vue&type=template&id=376a05f2&scoped=true&"
import script from "./HomeKingKongThemed.vue?vue&type=script&lang=js&"
export * from "./HomeKingKongThemed.vue?vue&type=script&lang=js&"
import style0 from "./HomeKingKongThemed.vue?vue&type=style&index=0&id=376a05f2&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "376a05f2",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/workspace/dev-client-a/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('376a05f2')) {
      api.createRecord('376a05f2', component.options)
    } else {
      api.reload('376a05f2', component.options)
    }
    module.hot.accept("./HomeKingKongThemed.vue?vue&type=template&id=376a05f2&scoped=true&", function () {
      api.rerender('376a05f2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/widgets/HomeKingKongThemed.vue"
export default component.exports