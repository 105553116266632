import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import store from '@/store';
import { KEEP_POINTS_ROUTES } from '@/utils/xConfig';

function updatePidWhenRouteChange(path) {
  if (KEEP_POINTS_ROUTES.includes(path)) return;

  try {
    store.dispatch('GetPoints');
  } catch (err) {
    console.log('get points while route change failed', err);
  }
}

export { updatePidWhenRouteChange };