import "core-js/modules/es.json.stringify.js";

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "waterfall-container"
  }, [!_vm.loading ? _c("div", {}, [_c("waterfall", {
    ref: "waterfall",
    staticStyle: {
      "min-height": "700px"
    },
    attrs: {
      "goods-list": _vm.goodsList,
      finished: _vm.finished
    },
    on: {
      "load-more": _vm.onLoad
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var source = _ref.source;
        return [source !== null && source !== void 0 && source.img ? _c("div", {
          directives: [{
            name: "track",
            rawName: "v-track:exposure",
            arg: "exposure"
          }],
          staticClass: "adv-img",
          attrs: {
            "track-params": JSON.stringify(_vm.getEventInfo(source))
          },
          on: {
            click: function click($event) {
              return _vm.onClickAdv(source);
            }
          }
        }, [_c("img", {
          attrs: {
            src: source === null || source === void 0 ? void 0 : source.img,
            alt: ""
          }
        })]) : source.isRecommend ? _c("GoodsRecommendItem", {
          attrs: {
            source: source
          }
        }) : _c("GoodsNormalItem", {
          ref: "goodsItem",
          attrs: {
            source: source,
            type: _vm.type,
            "on-click-item": _vm.onClickItem,
            "page-source": _vm.pageSource,
            "item-style": {
              width: "calc((100% - 3%))"
            }
          }
        })];
      }
    }], null, false, 379230224)
  }), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.pullLoading && !_vm.finished,
      expression: "pullLoading && !finished"
    }],
    staticClass: "flex justify-center items-center my2"
  }, [_c("van-loading", {
    attrs: {
      size: "24px"
    }
  }, [_vm._v("商品加载中...")])], 1)], 1) : _c("div", {
    staticClass: "loading-container flex flex-column items-center"
  }, [_c("img", {
    staticStyle: {
      "margin-top": "10vh"
    },
    attrs: {
      width: "100",
      src: _vm.renderBrandElement({
        id: "goodsList.loading",
        type: "image"
      })
    }
  }), _c("span", {
    staticClass: "loading-container-text",
    style: _vm.styleLoadingText
  }, [_vm._v("更多超值商品加载中")])]), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.finished,
      expression: "finished"
    }],
    staticClass: "flex justify-center items-center m2",
    staticStyle: {
      color: "#969799"
    }
  }, [_vm._v("我也是有底线的喔~")])]);
};

var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };