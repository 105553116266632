export default {
  name: 'RecommendContent',
  props: {
    goods: {
      type: Object,
      default: function _default() {}
    }
  },
  data: function data() {
    return {};
  },
  created: function created() {},
  mounted: function mounted() {},
  methods: {}
};