var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("van-action-sheet", {
    attrs: {
      "cancel-text": "完成",
      title: "请选择优惠券"
    },
    model: {
      value: _vm.visible,
      callback: function callback($$v) {
        _vm.visible = $$v;
      },
      expression: "visible"
    }
  }, [_c("div", {
    staticClass: "coupon-purchase-form px2 pt1"
  }, _vm._l(_vm.couponList, function (coupon, index) {
    return _c("div", {
      key: index
    }, [_c("div", {
      staticClass: "flex item px3 py2 items-center"
    }, [_c("div", {
      staticClass: "flex flex-auto items-center"
    }, [_c("div", {
      staticClass: "color_primary mr3"
    }, [_c("div", [_c("span", {
      staticClass: "fs24 bold"
    }, [_vm._v(_vm._s(coupon.money))]), _c("span", {
      staticClass: "fs14 ml1"
    }, [_vm._v("元")])]), _c("div", [_vm._v("满" + _vm._s(coupon.minUsePrice) + "元使用")])]), _c("div", [_c("div", {
      staticClass: "bold"
    }, [_vm._v("专属优惠券")]), _c("div", [_vm._v(_vm._s(coupon.validDateStr))])])]), _c("van-radio-group", {
      model: {
        value: _vm.selectCouponIndex,
        callback: function callback($$v) {
          _vm.selectCouponIndex = $$v;
        },
        expression: "selectCouponIndex"
      }
    }, [_c("van-radio", {
      attrs: {
        name: 0,
        "checked-color": "#e00"
      }
    })], 1)], 1)]);
  }), 0)]);
};

var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };