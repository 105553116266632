var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _vm.visible ? _c("div", {
    staticClass: "wx-mask",
    on: {
      click: _vm.clearMask
    }
  }, [_c("img", {
    attrs: {
      src: _vm.shareImage,
      alt: ""
    }
  })]) : _vm._e();
};

var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };