var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "flex items-center justify-center"
  }, [_c("img", {
    staticStyle: {
      width: "16px",
      height: "16px"
    },
    attrs: {
      src: require("base/assets/img/icon/beishuicon@2x.png"),
      alt: ""
    }
  }), _c("div", {
    style: {
      color: _vm.color,
      fontSize: _vm.size,
      flexBasis: _vm.flexBasis,
      textAlign: _vm.textAlign
    }
  }, [_vm._v(_vm._s(_vm.text))])]);
};

var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };