var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "com-bottom-padding",
    style: {
      height: _vm.height + "px",
      backgroundColor: _vm.bgColor
    }
  });
};

var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };