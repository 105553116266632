var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _vm.isShow ? _c("div", {
    staticClass: "mask-rule"
  }, [_c("div", {
    staticClass: "dialog-content flex flex-column"
  }, [_c("div", [_c("div", {
    staticClass: "flex justify-between"
  }, [_c("div", {
    staticClass: "cards flex items-center justify-center service-card",
    class: {
      active: _vm.showContent == true
    },
    on: {
      click: function click($event) {
        return _vm.changeContent($event);
      }
    }
  }, [_vm._v(" 服务 ")]), _c("div", {
    staticClass: "cards flex items-center justify-center rule-card",
    class: {
      active: _vm.showContent == false
    },
    on: {
      click: function click($event) {
        return _vm.changeContent($event);
      }
    }
  }, [_vm._v(" 规则 ")])]), _vm.showContent ? _c("div", [_vm._m(0), _vm._m(1), _vm._m(2)]) : _c("div", {
    staticClass: "rule-box px3 py3"
  }, [_c("div", {
    staticClass: "rule-text"
  }, [_c("p", {
    domProps: {
      innerHTML: _vm._s(_vm.message)
    }
  })])])]), _c("div", {
    staticClass: "close-btn",
    attrs: {
      id: "close"
    },
    on: {
      click: _vm.closeRulePopup
    }
  }, [_c("van-icon", {
    attrs: {
      size: "24",
      color: "#cccccc",
      name: "close"
    }
  })], 1)])]) : _vm._e();
};

var staticRenderFns = [function () {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "service-content flex items-center background-quality",
    staticStyle: {
      "padding-left": "50px"
    }
  }, [_c("div", {
    staticClass: "service-icon"
  }, [_c("img", {
    attrs: {
      src: require("base/assets/img/icon/icon-quality.png"),
      alt: ""
    }
  })]), _c("div", {
    staticClass: "service-text"
  }, [_c("p", {
    staticClass: "text-top"
  }, [_vm._v("正品保障")]), _c("p", {
    staticClass: "text-bottom"
  }, [_vm._v("平台承诺商品为官方正品")])])]);
}, function () {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "service-content flex items-center background-week",
    staticStyle: {
      "padding-left": "50px"
    }
  }, [_c("div", {
    staticClass: "service-icon"
  }, [_c("img", {
    attrs: {
      src: require("base/assets/img/icon/icon-week.png"),
      alt: ""
    }
  })]), _c("div", {
    staticClass: "service-text"
  }, [_c("p", {
    staticClass: "text-top"
  }, [_vm._v("七天无理由")]), _c("p", {
    staticClass: "text-bottom"
  }, [_vm._v("平台承诺七天无理由退换货")])])]);
}, function () {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "service-content flex items-center background-postage",
    staticStyle: {
      "padding-left": "50px"
    }
  }, [_c("div", {
    staticClass: "service-icon"
  }, [_c("img", {
    attrs: {
      src: require("base/assets/img/icon/icon-postage.png"),
      alt: ""
    }
  })]), _c("div", {
    staticClass: "service-text"
  }, [_c("p", {
    staticClass: "text-top"
  }, [_vm._v("全国包邮")]), _c("p", {
    staticClass: "text-bottom"
  }, [_vm._v("新疆、西藏等偏远地区除外")])])]);
}];
render._withStripped = true;
export { render, staticRenderFns };