var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    attrs: {
      id: "app"
    }
  }, [_c("div", {
    ref: "globalCopyClipboard",
    attrs: {
      id: "global-copy-clipboard",
      "data-clipboard-text": _vm.copyText
    },
    on: {
      click: _vm.clickCopyClipboard
    }
  }), _vm.appReady ? _c("div", [_c("keep-alive", {
    attrs: {
      include: _vm.includeList
    }
  }, [_c("router-view", {
    directives: [{
      name: "wechat-title",
      rawName: "v-wechat-title",
      value: _vm.pIdTitle || _vm.$route.meta.title || "".concat(_vm.brand),
      expression: "pIdTitle || $route.meta.title || `${brand}`"
    }]
  })], 1)], 1) : _c("div", {
    staticClass: "loading-container"
  }, [_c("van-loading", {
    attrs: {
      type: "spinner"
    }
  }), _c("div", {
    staticClass: "tips"
  }, [_vm._v("正在为您准备商品...")])], 1), _vm.orderInfo ? _c("van-notify", {
    attrs: {
      type: "success",
      background: "#fff",
      "class-name": "justify-between notify",
      color: "#000"
    },
    model: {
      value: _vm.showNotify,
      callback: function callback($$v) {
        _vm.showNotify = $$v;
      },
      expression: "showNotify"
    }
  }, [_c("div", {
    staticClass: "flex justify-between notify-content",
    on: {
      click: _vm.goPay
    }
  }, [_c("div", {
    staticClass: "notify-desc"
  }, [_c("div", {
    staticClass: "fs16 bold"
  }, [_vm._v("商品待兑换通知")]), _c("div", {
    staticClass: "mt1"
  }, [_vm._v("您有商品未兑换成功，优惠价" + _vm._s(_vm.orderInfo.goodsTotalAmount) + "元")])]), _c("div", {
    staticClass: "notify-image"
  }, [_c("img", {
    attrs: {
      src: _vm.orderInfo.imgUrl,
      alt: ""
    }
  })])])]) : _vm._e()], 1);
};

var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };