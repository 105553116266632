var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return !_vm.hidden && _vm.isValidPoint && _vm.enable ? _c("div", {
    staticClass: "com-point-notice-mask top",
    style: {
      background: _vm.jinriBackground
    }
  }, [_c("van-notice-bar", {
    staticStyle: {
      height: "27px"
    },
    attrs: {
      speed: 60,
      scrollable: true,
      color: "rgba(255, 255, 255, 0.9)",
      background: _vm.jinriBackground
    }
  }, [_c("div", {
    staticClass: "t_txt flex items-center"
  }, [_c("img", {
    staticClass: "t_Img",
    attrs: {
      src: require("base/assets/img/laba02.gif")
    }
  }), _c("span", [_vm._v(" 您的 "), _c("span", [_vm._v(_vm._s(_vm.points.expirePoints))]), _vm._v(" " + _vm._s(_vm.globalPointName) + "将在 ")]), _c("van-count-down", {
    staticClass: "white fs12 px1",
    attrs: {
      millisecond: true,
      format: "HH:mm:ss",
      time: _vm.countDownTime
    }
  }), _c("span", [_vm._v("后过期")])], 1), !_vm.points.expirePoints && _vm.points.activePoints > 0 ? _c("div", {
    staticClass: "t_txt"
  }, [_vm._v(" 您还有 "), _c("span", [_vm._v(_vm._s(_vm.points.activePoints))]), _vm._v(" " + _vm._s(_vm.globalPointName) + "未使用 请尽快使用 ")]) : _vm._e()])], 1) : _vm._e();
};

var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };