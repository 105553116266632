export default {
  name: 'BottomButton',
  props: {
    title: {
      type: String,
      default: ''
    },
    styleWrap: {
      type: Object,
      default: function _default() {}
    },
    shadow: {
      type: Boolean,
      default: false
    }
  },
  methods: {}
};