import store from '@/store';
import { sendQuickTrackingEvent, EventType } from 'base/utils/quickTracking';
export default {
  name: 'PaySuccessfulDialog',
  data: function data() {
    return {
      visible: true,
      morePoints: store.state.appState.morePoints
    };
  },
  methods: {
    hide: function hide() {
      this.visible = false;
    },
    closeDialog: function closeDialog() {
      this.onClick();
      this.visible = false;
    },
    clickDialog: function clickDialog() {
      this.hide();
      this.onClick(); // 积分弹窗

      var morePoints = this.morePoints;
      sendQuickTrackingEvent({
        eventName: 'ducuecmc_hc_points_windows_clk',
        eventType: EventType.Click,
        pageName: 'dacuecmc_all_page',
        params: {
          point_value: morePoints,
          get_point_type: '积分不足获得积分'
        }
      });
    }
  }
};