var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    ref: "header",
    staticClass: "pt-[11px]",
    attrs: {
      id: "home-header"
    }
  }, [_c("div", {
    staticClass: "flex items-center px2"
  }, [_c("div", {
    staticClass: "flex-auto flex items-center search-bar mr3 pl2",
    on: {
      click: _vm.toSearch
    }
  }, [_c("IconFont", {
    staticClass: "icon",
    attrs: {
      size: "16px",
      icon: _vm.searchIcon,
      color: _vm.searchIconColor
    }
  }), _c("van-swipe", {
    staticStyle: {
      width: "96%",
      margin: "0 0 0 4px",
      padding: "0 0",
      height: "18px"
    },
    attrs: {
      autoplay: 3000,
      "show-indicators": false,
      "initial-swipe": _vm.initialIndex,
      vertical: true,
      touchable: false
    },
    on: {
      change: _vm.recommendWordChange
    }
  }, _vm._l(_vm.recommendWords, function (item, index) {
    return _c("van-swipe-item", {
      key: index,
      staticClass: "flex swipe-item items-center",
      staticStyle: {
        height: "18px"
      }
    }, [_c("div", {
      staticClass: "buyInfo fs14 color_tips"
    }, [_vm._v(_vm._s(item))])]);
  }), 1)], 1), (_vm.hasCustom = true) ? _c("div", {
    staticClass: "service-icon flex flex-column justify-center items-center",
    on: {
      click: _vm.toCustomService
    }
  }, [_c("img", {
    staticStyle: {
      width: "20px",
      height: "20px"
    },
    attrs: {
      src: require("base/assets/img/icon/customerservice 1@2x.png")
    }
  }), _c("div", {
    staticClass: "white title line-height-1"
  }, [_vm._v("客服")])]) : _vm._e()])]);
};

var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };