import _objectSpread from "/root/workspace/dev-client-a/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.string.trim.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.match.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.json.stringify.js";
import axios from 'axios';
import _ from 'lodash';
import utils from 'base/utils';
import xEnum from 'base/utils/enum';
import { checkJson } from './index'; // import store from '@/store'

/*
(function() {
    var scrs = document.getElementsByTagName("script");
    var loadUrl=scrs[scrs.length-1].getAttribute("src");
    function getQueryVariable(query,variable){
        if (query.startsWith("http")){
            query=new URL(query).search.substring(1);
        }else {
            query=query.substring(query.indexOf("?")+1);
        }
        var vars = query.split("&");
        for (var i=0;i<vars.length;i++) {
            var pair = vars[i].split("=");
            if(pair[0] == variable){return pair[1];}
        }
        return null;
    }
    var baidu=getQueryVariable(loadUrl,"baiduId"),umeng=getQueryVariable(loadUrl,"umengId");
    rpInit({umengId: umeng, baiduId: baidu});
})();
*/
// 全渠道聚合基线
// https://tongji.baidu.com/sc-web/10000171383/home/site/getjs?siteId=16024277

export function rpInit(data) {
  var umeng = data === null || data === void 0 ? void 0 : data.umengId,
      baidu = data === null || data === void 0 ? void 0 : data.baiduId,
      pId = data === null || data === void 0 ? void 0 : data.pId;

  if (!window._czc || window._czc instanceof Array) {
    window._czc = [];

    if (umeng != null && umeng.trim().length > 0 && umeng !== 'null') {
      //1278599417
      var hm = document.createElement('script');
      hm.src = ('https:' == document.location.protocol ? ' https://' : ' http://') + 'v1.cnzz.com/z_stat.php?id=' + umeng + '&web_id=' + umeng;
      var s = document.getElementsByTagName('script')[0];
      s.parentNode.insertBefore(hm, s);
    }
  }

  if (!window._hmt || window._hmt instanceof Array) {
    window._hmt = [];

    if (baidu != null && baidu.trim().length > 0 && baidu !== 'null') {
      //da39f118d2bb15170eb5abf946b81a63
      var hm1 = document.createElement('script');
      hm1.src = 'https://hm.baidu.com/hm.js?' + baidu;
      var s1 = document.getElementsByTagName('script')[0];
      s1.parentNode.insertBefore(hm1, s1); // 百度统计设置
      // window._hmt.push(['_setAccount', '0123456789ABCDEF0123456789ABCDEF'])

      window._hmt.push(['_setCustomVar', 1, 'pId', pId, 1]);
    }
  }
}
export function rpPush(param) {
  if (location.hostname == 'localhost') {
    // console.log(param)
    return;
  }

  if (window._czc) {
    window._czc.push(param);
  }

  if (window._hmt && !(window._hmt instanceof Array)) {
    window._hmt.push(param);
  }
}
export function trackPageview(url, p) {
  rpPush(['_trackPageview', url, p || '/']);
}
export function trackEvent(data) {
  try {
    if (data && data.category) {
      rpPush(['_trackEvent', data.category, data.action || '无', data.opt_label, data.opt_value]);
    }
  } catch (e) {
    console.info('友盟增加事件错误');
  }
}
export function setCustomVar(data) {
  if (data && data.index && data.name && data.value) {
    rpPush(['_setCustomVar', data.index, data.name, data.value, data.opt_scope]);
  }
}
var rpFlag = false;
export function trackUserEvent(params) {
  var _buryPointConfig$even, _buryPointConfig, _localStorage$getItem, _localStorage$getItem2;

  if (!rpFlag) return;
  var appType = xEnum.ENUM_APP_TYPE.H5.getValue(); // console.log('process.env.VUE_APP_BRAND', process.env.VUE_APP_ENABLE_MONITOR, Number(process.env.VUE_APP_ENABLE_MONITOR))

  if (!Number(process.env.VUE_APP_ENABLE_MONITOR)) {
    return;
  }
  /**
   * saas版中传给后端用于做数据上报来源的 source 不再使用打包命令中 --brand 所生成的品牌名来赋枚举值。
   * 后期理想化的是前端多品牌架构是需要去掉的（即所有可配置项都由后台来配置），打包命令也不需要加 --brand 等额外参数。
   * 目前这里数据上报要用到的来源参数 source 暂时在环境配置文件中加一个全局变量枚举值来由于做上报数据来源区分。
   */


  var currentSourceEnum = Number(process.env.VUE_APP_SOURCE_ENUM);

  var sourceXEnum = _.find(xEnum.ENUM_DATA_REPORT_SOURCES, function (item) {
    return item.value === currentSourceEnum;
  });

  var source = sourceXEnum.value;
  var eventType = params.eventType,
      eventInfo = params.eventInfo;
  var buryPointConfig = {
    exposeDuration: 300,
    exposeMinAreaRate: 0.8,
    pushInterval: 2000,
    queueSize: 20,
    eventTypes: [Number(xEnum.ENUM_RP_EVENT_TYPE.ROUTE_TO_PAGE.value)]
  };

  if (checkJson(window.localStorage.getItem('buryPointConfig'))) {
    buryPointConfig = JSON.parse(window.localStorage.getItem('buryPointConfig'));
  }

  if (!((_buryPointConfig$even = (_buryPointConfig = buryPointConfig) === null || _buryPointConfig === void 0 ? void 0 : _buryPointConfig.eventTypes) !== null && _buryPointConfig$even !== void 0 ? _buryPointConfig$even : []).includes(Number(eventType))) {
    return;
  }

  if (eventInfo !== null && eventInfo !== void 0 && eventInfo.webUrl) {
    eventInfo.webUrl = "(".concat(eventInfo.webUrl, ")");
  } // 用户信息


  var userInfo = {
    tel: (_localStorage$getItem = localStorage.getItem('phone')) !== null && _localStorage$getItem !== void 0 ? _localStorage$getItem : '',
    openId: (_localStorage$getItem2 = localStorage.getItem('openid')) !== null && _localStorage$getItem2 !== void 0 ? _localStorage$getItem2 : ''
  };
  var pId = window.localStorage.getItem('pId');
  var nonce = utils.randomString(8);
  var timestamp = new Date().getTime(); //创建用户唯一会话id

  var userSessionId = '';

  if (window.sessionStorage.getItem('userSessionId')) {
    userSessionId = window.sessionStorage.getItem('userSessionId');
  } else {
    userSessionId = utils.getSessionId();
    window.sessionStorage.setItem('userSessionId', userSessionId);
  }

  var paramsObj = _.omitBy(_objectSpread(_objectSpread(_objectSpread({
    // 渠道号
    pId: pId,
    // 商品ID
    goodsId: eventInfo === null || eventInfo === void 0 ? void 0 : eventInfo.goodsId,
    // 商品组类型
    goodsGroupId: eventInfo === null || eventInfo === void 0 ? void 0 : eventInfo.goodsGroupId,
    // 页面url
    // nonce防篡改
    nonce: nonce,
    // goodsIndex排名索引
    // score排名权重
    // userId
    // goodsPrice商品价格
    // eventType
    appType: appType,
    source: source,
    eventType: eventType,
    timestamp: timestamp
  }, eventInfo), userInfo), {}, {
    userSessionId: userSessionId
  }), function (param) {
    return param === null || param === undefined;
  });

  var signature = utils.genSignature({
    pId: pId,
    timestamp: timestamp,
    eventType: eventType
  });
  axios.defaults.headers['Accept-Locale'] = signature;
  return new Promise(function (resolve, reject) {
    axios.get("https://bigdata.szgchw.cn/collect/odsCollect", {
      params: paramsObj
    }).then(function (res) {
      resolve(res); // console.log(res)
    }).catch(function (err) {
      reject(err); // console.log(err)
    });
  });
} // 监听浏览器关闭事件钩子函数 defect: cb函数api异步调用之前，浏览器线程 destroyed

export function listenUnloadEvents(cb) {
  window.addEventListener('onbeforeunload', function () {
    cb();
    return function () {
      window.removeEventListener('onbeforeunload', cb());
    };
  });
}
export function webfunnyTrack(params) {
  var _webfunnyEvent$pointI;

  var appType = xEnum.ENUM_APP_TYPE.H5.getValue();

  if (!Number(process.env.VUE_APP_ENABLE_WEBFUNNY)) {
    return;
  }
  /**
   * saas版中传给后端用于做数据上报来源的 source 不再使用打包命令中 --brand 所生成的品牌名来赋枚举值。
   * 后期理想化的是前端多品牌架构是需要去掉的（即所有可配置项都由后台来配置），打包命令也不需要加 --brand 等额外参数。
   * 目前这里数据上报要用到的来源参数 source 暂时在环境配置文件中加一个全局变量枚举值来由于做上报数据来源区分。
   */


  var source = null;
  var currentSourceEnum = Number(process.env.VUE_APP_SOURCE_ENUM);

  var sourceXEnum = _.find(xEnum.ENUM_DATA_REPORT_SOURCES, function (item) {
    return item.value === currentSourceEnum;
  });

  source = sourceXEnum.value;
  var pointId = params.pointId,
      eventType = params.eventType,
      eventInfo = params.eventInfo;

  if (eventInfo !== null && eventInfo !== void 0 && eventInfo.webUrl) {
    var reg = /[(|)]/g;

    if (eventInfo.webUrl.match(reg)) {
      eventInfo.webUrl = eventInfo.webUrl.replace(reg, '');
    }
  } // eslint-disable-next-line no-undef


  (_webfunnyEvent$pointI = _webfunnyEvent[pointId]) === null || _webfunnyEvent$pointI === void 0 ? void 0 : _webfunnyEvent$pointI.trackEvent(_objectSpread(_objectSpread({}, eventInfo), {}, {
    appType: appType,
    source: source,
    eventType: eventType
  }));
} // 页面性能采集

export function getPagePerformance() {
  var timingInfo = JSON.parse(JSON.stringify(window.performance.timing)); // console.log(timingInfo)
  // DNS解析，DNS查询耗时

  var dnsStamp = (timingInfo === null || timingInfo === void 0 ? void 0 : timingInfo.domainLookupEnd) - (timingInfo === null || timingInfo === void 0 ? void 0 : timingInfo.domainLookupStart); // TCP连接耗时

  var connectStamp = timingInfo.connectEnd - (timingInfo === null || timingInfo === void 0 ? void 0 : timingInfo.connectStart); // 获得首字节耗费时间，也叫TTFB

  var ttfb = timingInfo.responseStart - (timingInfo === null || timingInfo === void 0 ? void 0 : timingInfo.navigationStart); // domReady时间(与DomContentLoad事件对应)

  var domReady = timingInfo.domContentLoadedEventStart - timingInfo.navigationStart; // DOM资源下载

  var domDownload = timingInfo.responseEnd - timingInfo.responseStart; // 准备新页面时间耗时

  var newPage = timingInfo.fetchStart - timingInfo.navigationStart; // 重定向耗时

  var redirect = timingInfo.redirectEnd - timingInfo.redirectStart; // Appcache 耗时

  var appCache = timingInfo.domainLookupStart - timingInfo.fetchStart; // unload 当前文档耗时

  var upload = timingInfo.unloadEventEnd - timingInfo.unloadEventStart; // request请求耗时

  var request = timingInfo.responseEnd - timingInfo.requestStart; // 请求完毕至DOM加载

  var domInteractive = timingInfo.domInteractive - timingInfo.responseEnd; // 解释dom树耗时

  var domComplete = timingInfo.domComplete - timingInfo.domInteractive; // 从开始至load总耗时

  var startToLoadEnd = timingInfo.loadEventEnd - timingInfo.navigationStart; // *: 白屏时间

  var blankView = timingInfo.responseStart - timingInfo.fetchStart; // *: 首屏时间

  var firstView = timingInfo.domComplete - timingInfo.fetchStart;
  return {
    dnsStamp: dnsStamp,
    connectStamp: connectStamp,
    ttfb: ttfb,
    domReady: domReady,
    domDownload: domDownload,
    newPage: newPage,
    redirect: redirect,
    appCache: appCache,
    upload: upload,
    request: request,
    domInteractive: domInteractive,
    domComplete: domComplete,
    startToLoadEnd: startToLoadEnd,
    blankView: blankView,
    firstView: firstView
  };
}