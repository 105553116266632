import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import { getPagingGoodsList } from 'base/api';
import xEnum from 'base/utils/enum';
export default {
  props: {
    goodsGroupType: {
      type: Number,
      default: xEnum.ENUM_GOODS_GROUP_TYPE.RATE_GROUP.getValue()
    },
    activityType: {
      type: String,
      default: function _default() {
        return '';
      }
    } // excludeGoodsIds: {
    //   type: Array,
    //   default: () => []
    // }

  },
  data: function data() {
    return {
      goodsList: [],
      itemType: this.renderBrandElement({
        id: 'goodsGallery.itemType'
      })
    };
  },
  computed: {
    pId: function pId() {
      return this.$store.state.appState.pId;
    },
    userId: function userId() {
      return this.$store.state.user.userId;
    }
  },
  created: function created() {// this.reload()
  },
  methods: {
    goodClick: function goodClick(goods) {
      // this.$store.dispatch('enterGoodsProfile', {
      //   module: '详情页精品推荐',
      //   index,
      //   type: this.goodsGroupType,
      //   goods
      // })
      this.$router.push({
        path: "/goodsProfile/".concat(goods.id),
        query: {
          type: this.goodsGroupType,
          activityType: this.activityType
        }
      });
    },
    reload: function reload() {
      var _this = this;

      var excludeGoodsIds = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      var params = {
        pId: this.pId,
        type: this.goodsGroupType,
        pageIndex: 1,
        pageSize: 20,
        userId: this.userId
      };
      getPagingGoodsList(params).then(function (response) {
        if (response.code === 200) {
          _this.goodsList = _.chain(response.data.list).filter(function (ele) {
            return !_.includes(excludeGoodsIds, ele.id);
          }).map(function (ele) {
            if (_this.itemType === 'seckill') {
              ele.saleDesc = _.replace(ele.saleDesc, '兑换', '抢购');
            } else {
              ele.saleDesc = _.replace(ele.saleDesc, '兑换', '抢购');
            }

            return ele;
          }).slice(0, 9) // 最多支持展示9个商品
          .value();
        }
      });
    }
  }
};