import { render, staticRenderFns } from "./PointNoticeMaskThemed.vue?vue&type=template&id=a7f1cf34&scoped=true&"
import script from "./PointNoticeMaskThemed.vue?vue&type=script&lang=js&"
export * from "./PointNoticeMaskThemed.vue?vue&type=script&lang=js&"
import style0 from "./PointNoticeMaskThemed.vue?vue&type=style&index=0&id=a7f1cf34&lang=less&"
import style1 from "./PointNoticeMaskThemed.vue?vue&type=style&index=1&id=a7f1cf34&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a7f1cf34",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/workspace/dev-client-a/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('a7f1cf34')) {
      api.createRecord('a7f1cf34', component.options)
    } else {
      api.reload('a7f1cf34', component.options)
    }
    module.hot.accept("./PointNoticeMaskThemed.vue?vue&type=template&id=a7f1cf34&scoped=true&", function () {
      api.rerender('a7f1cf34', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/widgets/PointNoticeMaskThemed.vue"
export default component.exports