import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import GoodsWaterfallList from './GoodsWaterfallList';
import TabFilter from './TabFilter';
import GoodsSeckillItemThemed from './ListItem/GoodsSeckillItemThemed';
import GoodsNormalItem from './ListItem/GoodsNormalItem';
import { sendQuickTrackingEvent, EventType } from 'base/utils/quickTracking';
import xEnum from 'base/utils/enum';
var itemMap = {
  point: GoodsNormalItem,
  seckill: GoodsSeckillItemThemed
};
export default {
  name: 'HomeRecommendGoods',
  components: {
    TabFilter: TabFilter,
    GoodsWaterfallList: GoodsWaterfallList
  },
  props: {
    name: {
      type: String,
      default: '未知'
    },
    iconName: {
      type: String,
      default: 'icongouwu'
    },
    iconColor: {
      type: String,
      default: '#E15658'
    },
    title: {
      type: String,
      default: '猜你喜欢'
    },
    className: {
      type: String,
      default: ''
    },
    groupTypeIds: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    sticky: {
      type: Boolean,
      default: false
    },
    enableAutoScroll: {
      type: Boolean,
      default: true
    },
    tabFilterBgColor: {
      type: String,
      default: 'white'
    },
    tabFilterTitleColor: {
      type: String,
      default: null
    },
    styleActiveSubtitle: {
      type: Object,
      default: function _default() {}
    },
    styleLoadingText: {
      type: Object,
      default: function _default() {}
    }
  },
  data: function data() {
    return {
      goodsList: [],
      loading: false,
      tabs: this.renderBrandElement({
        id: 'guessYourFavor.tabs',
        type: 'object'
      }),
      activeTabIndex: 0,
      isStickyFixed: false,
      goodsItem: itemMap[this.renderBrandElement({
        id: 'guessYourFavor.listItemType'
      }) || 'point']
    };
  },
  computed: {
    pId: function pId() {
      return this.$store.state.appState.pId;
    },
    stickyId: function stickyId() {
      return "stickyZone-".concat(this.name);
    }
  },
  mounted: function mounted() {
    if (this.pId) {// this.onTabChange(0, false);
    }
  },
  methods: {
    // async fetchGoodsList(type) {
    //   try{
    //     this.loading = true
    //     const response = await getGoodList({
    //       pId: this.pId,
    //       type,
    //     })
    //     this.goodsList = response.data ? response.data.goodsList : []
    //   } catch (err) {
    //     console.log(err);
    //   }
    //   this.loading = false
    // },
    onClickGoodsListMore: function onClickGoodsListMore() {
      this.$emit('click-more');
    },
    loadGoodsList: function loadGoodsList(dataList) {
      var _this = this;

      dataList.forEach(function (item) {
        _this.goodsList.push(item);
      });
    },
    // eslint-disable-next-line no-unused-vars
    onTabChange: function onTabChange(index) {
      var pageIndex = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
      var scrollToTop = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
      this.activeTabIndex = index;
      this.goodsList = [];
      this.$refs.waterfallGoodsList.resetOnloadSetting(); // 首页资源位-商品瀑布流tab

      var groupTypeIds = this.groupTypeIds;
      sendQuickTrackingEvent({
        eventName: 'ducuecmc_hp_place_clk',
        eventType: EventType.Click,
        pageName: 'dacuecmc_hp_page',
        params: {
          place_type: "\u5546\u54C1\u7EC4\u6807\u9898\uFF1A".concat(groupTypeIds[index].title),
          place_name: '首页商品瀑布流tab',
          place_addr: index,
          place_detail: groupTypeIds[index].groupTypeId
        }
      });
    },
    onStickyScroll: function onStickyScroll(_ref) {
      var isFixed = _ref.isFixed;
      this.isStickyFixed = isFixed;
    },
    resetOnloadSetting: function resetOnloadSetting() {
      this.$refs.waterfallGoodsList.resetOnloadSetting();
    },
    getEventInfo: function getEventInfo(addr) {
      return {
        eventType: xEnum.ENUM_RP_EVENT_TYPE.HOME_PAGE_SOURCE_PLACE_EP.value,
        eventInfo: {
          place_type: '无',
          place_name: addr,
          place_addr: '无'
        }
      };
    }
  }
};