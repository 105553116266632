import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.number.to-fixed.js";
import "core-js/modules/es.number.constructor.js";

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    directives: [{
      name: "track",
      rawName: "v-track:exposure",
      arg: "exposure"
    }],
    staticClass: "time-limit-item flex p2 br10 mb2",
    attrs: {
      "track-params": JSON.stringify(_vm.getEventInfo(_vm.source, _vm.index))
    },
    on: {
      click: _vm.onClick
    }
  }, [_c("van-image", {
    staticClass: "left-part flex-none",
    attrs: {
      src: _vm.source.imgUrl,
      "lazy-load": true
    },
    scopedSlots: _vm._u([{
      key: "loading",
      fn: function fn() {
        return [_c("van-loading", {
          attrs: {
            type: "spinner"
          }
        })];
      },
      proxy: true
    }])
  }), _vm.source.stockNum <= 0 ? _c("div", {
    staticClass: "stock-empty"
  }, [_c("IconFont", {
    attrs: {
      size: "103px",
      icon: "iconyiqiangwan",
      color: "#AAAAAA"
    }
  })], 1) : _vm._e(), _c("div", {
    staticClass: "right-part flex-auto ml2"
  }, [_c("div", {
    staticClass: "text-ellipsis fs14 bold mb1"
  }, [_vm._v(_vm._s(_vm.source.goodsName))]), _c("div", {
    staticClass: "price"
  }, [_c("div", {
    staticClass: "absolute left-0 color_tips",
    staticStyle: {
      top: "5px"
    }
  }, [_vm._v(_vm._s(Number(_vm.source.originalPrice).toFixed(2)) + "元")]), _c("div", {
    staticClass: "absolute right-0 color_tips",
    staticStyle: {
      top: "5px"
    }
  }, [_vm._v(_vm._s(Number(_vm.source.originalPrice).toFixed(2)) + "元")]), _c("div", {
    staticClass: "absolute left-0 color_tips",
    staticStyle: {
      top: "65%"
    }
  }, [_vm._v("日常价")]), _c("div", {
    staticClass: "absolute right-0 color_tips",
    staticStyle: {
      top: "65%"
    }
  }, [_vm._v("日常价")]), _c("img", {
    staticClass: "fit",
    attrs: {
      src: require("base/assets/img/timeLimit/linepoint@2x.png")
    }
  }), _c("img", {
    staticClass: "special-price absolute left-0 right-0 animate__animated animate__infinite",
    attrs: {
      src: require("base/assets/img/timeLimit/superdeal@2x.png")
    }
  })]), _c("div", {
    staticClass: "bottom-part flex justify-end mt1"
  }, [_c("div", {
    staticClass: "flex items-center"
  }, [_c("div", {
    staticClass: "color_text fs12",
    staticStyle: {
      "margin-top": "3px"
    }
  }, [_vm._v("到手价")]), _c("div", {
    staticClass: "buy-price ml1 bold color_price fs18"
  }, [_c("span", {
    staticClass: "fs14"
  }, [_vm._v("￥")]), _vm._v(_vm._s(Number(_vm.source.price)))])]), _c("div", {
    staticClass: "buy-btn flex items-center px2 py1 white animate__animated animate__infinite animate__pulse animate__fast",
    class: {
      sellout: _vm.source.stockNum <= 0
    }
  }, [_vm._v(" " + _vm._s(Number(_vm.source.stockNum) > 0 ? "立即购买" : "等待下场") + " ")])])])], 1);
};

var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };