var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", [_c("van-swipe", {
    staticClass: "my-swipe",
    attrs: {
      autoplay: 3000,
      loop: true,
      "indicator-color": "white"
    }
  }, _vm._l(_vm.activityEntryData, function (item, index) {
    return _c("van-swipe-item", {
      key: item.img,
      on: {
        click: function click($event) {
          return _vm.onClick(item, index);
        }
      }
    }, [_c("van-image", {
      staticClass: "sbImg1",
      attrs: {
        src: item.img
      }
    })], 1);
  }), 1)], 1);
};

var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };