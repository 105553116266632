import "core-js/modules/es.object.to-string.js";
// vm.$mount(el) => document.body.appendChild();
// Vue.extend() 继承 【子类继承父类的方法】
// 父类 Vue--Vue.extend--子类 定制化的 Vue 构造函数
import Vue from 'vue';
import ShareDialog from './ShareDialog';
var Customized = Vue.extend(ShareDialog);
export var shareDialog = function shareDialog() {
  var option = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return new Promise(function (resolve, reject) {
    var res = new Customized({
      data: function data() {
        return {
          shareCopyDesc: option.shareCopyDesc || '快去粘贴给好友吧',
          // 默认为微信下分享文案
          shareBtnDesc: option.shareBtnDesc || '点击复制分享给好友',
          shareHint: option.shareHint,
          shareImage: option.shareImage,
          hidePoint: option.hidePoint
        };
      },
      methods: {
        resolve: resolve,
        reject: reject
      }
    }).$mount(); // console.log(res.$el);

    document.body.appendChild(res.$el);
  });
};