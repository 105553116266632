var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "mx2 px2 my2 py2 bg-white rounded-[10px]"
  }, [_c("div", {
    staticClass: "fs14 bg-[#f2f2f2] rounded-[5px] p2"
  }, [_c("div", {
    staticClass: "text-[#E54635]"
  }, [_vm._v("推荐理由")]), _c("div", {
    staticClass: "text-[#4d4d4d]"
  }, _vm._l(_vm.goods.recommendReasonList, function (item, index) {
    return _c("span", {
      key: index,
      staticClass: "item"
    }, [_vm._v(_vm._s(item))]);
  }), 0)])]);
};

var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };