import _objectSpread from "/root/workspace/dev-client-a/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.concat.js";
import mock from 'base/utils/mock';
import { trackEvent, trackUserEvent } from 'base/utils/rp';
import { IconFont, CountDown } from 'components';
import xEnum from 'base/utils/enum';
import { mapState } from 'vuex';
export default {
  name: 'DealRecent',
  components: {
    IconFont: IconFont,
    CountDown: CountDown
  },
  props: {
    goods: {
      type: Object,
      default: function _default() {}
    },
    activityType: {
      type: String,
      default: function _default() {
        return 'point';
      }
    },
    goodsCommentList: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      buyInfoList: [],
      visible: true,
      countDownEnd: dayjs().endOf('day'),
      delayer: null
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState({
    timeLimitSwitch: function timeLimitSwitch(state) {
      return state.appState.goodsProfileTimeLimitSwitch;
    }
  })), {}, {
    isShow: function isShow() {
      var id = this.renderBrandElement({
        id: 'global.directDeal'
      }); // console.log(11111111111, id)

      if (id == 'false') return false;else return true;
    },
    nowExchangeNum: function nowExchangeNum() {
      return Math.floor(Math.random() * 4000 + 1000);
    },
    purchaseWord: function purchaseWord() {
      if (this.activityType === 'point') {
        return this.globalPointName + this.globalExchangeWord;
      } else if (this.activityType === 'drawPrize') {
        return "\u4EE5\u539F\u4EF7\uFFE5".concat(this.goods.goodsDetailEx.originalPrice, "\u8D2D\u4E70");
      } else if (this.activityType === 'payOnDelivery') {
        return '购买';
      } else {
        return '兑换';
      }
    },
    purchaseWordSub: function purchaseWordSub() {
      if (this.activityType === 'payOnDelivery') {
        return '使用';
      } else {
        return this.globalExchangeWord;
      }
    },
    goodsCommentDescList: function goodsCommentDescList() {
      return _.chunk(this.goodsCommentList, 2);
    },
    sourceList: function sourceList() {
      return this.activityType === 'singleProduct' ? this.goodsCommentDescList : this.buyInfoList;
    },
    eventInfo: function eventInfo() {
      return {
        eventType: xEnum.ENUM_RP_EVENT_TYPE.GOODS_HEAT_EP.value,
        pointId: xEnum.ENUM_WEBFUNNY_POINT_ID.GOODS_EXPOSE.value,
        eventInfo: {
          pId: this.$store.state.appState.pId,
          userId: this.$store.state.user.userId,
          goodsId: this.goods.goodsDetailEx.goodsId,
          goodsPrice: this.goods.goodsDetailEx.price,
          score: this.goods.goodsDetailEx.score,
          goodsGroupId: this.$route.query.type,
          webUrl: window.location.href
        }
      };
    }
  }),
  watch: {
    buyInfoList: function buyInfoList(newVal) {
      var _this = this;

      if (this.isShow && newVal) {
        this.delayer = setTimeout(function () {
          _this.$refs.swipe.resize();
        }, 500);
      }
    }
  },
  created: function created() {},
  mounted: function mounted() {
    this.buyInfoList = _.chain(Array(10)).map(function () {
      var familyName = mock.familyNames[_.random(mock.familyNames.length - 1)];

      var phoneNumberStart = mock.phoneNumbersStart[_.random(mock.phoneNumbersStart.length - 1)];

      var phone = "".concat(phoneNumberStart, "****").concat(_.random(1000, 9999));

      var second = _.random(1, 12);

      var userIcon = mock.avatars[_.random(mock.avatars.length - 1)];

      return {
        avatars: userIcon,
        desc: "".concat(familyName, "**\uFF08").concat(phone, "\uFF09\u5728").concat(second, "\u5206\u949F\u524D")
      };
    }).chunk(2).value();
  },
  destroyed: function destroyed() {
    if (this.delayer) {
      clearTimeout(this.delayer);
    }
  },
  methods: {
    toBuy: function toBuy() {
      var _this$goods, _this$goods2, _this$goods3;

      trackEvent({
        category: "\u70B9\u51FB-\u8BE6\u60C5\u9875-\u53BB\u5151\u6362\u6309\u94AE",
        action: '总数'
      });
      trackUserEvent({
        eventType: xEnum.ENUM_RP_EVENT_TYPE.GOODS_HEAT_BUY_CLICK.value,
        eventInfo: {
          pId: this.$store.state.appState.pId,
          userId: this.$store.state.user.userId,
          goodsId: (_this$goods = this.goods) === null || _this$goods === void 0 ? void 0 : _this$goods.goodsId,
          goodsPrice: (_this$goods2 = this.goods) === null || _this$goods2 === void 0 ? void 0 : _this$goods2.price,
          score: (_this$goods3 = this.goods) === null || _this$goods3 === void 0 ? void 0 : _this$goods3.score,
          goodsGroupId: this.$route.query.type,
          webUrl: window.location.href
        }
      });
      this.$emit('click-buy');
    }
  }
};