var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("van-dialog", {
    staticClass: "wx-subscribe-dialog",
    attrs: {
      show: _vm.visible,
      "show-confirm-button": false
    }
  }, [_c("div", {
    staticClass: "panel py3"
  }, [_c("p", {
    staticClass: "title mb1 fs16"
  }, [_vm._v(_vm._s(_vm.title))]), _c("van-image", {
    staticClass: "wechat-touch-identify",
    attrs: {
      width: "150",
      height: "150",
      src: _vm.pubImage
    },
    on: {
      click: _vm.previeWxPubImage
    }
  }), _vm.isInWechat ? _c("p", {
    staticClass: "mt1"
  }, [_vm._v("长按识别上方二维码关注公众号")]) : _c("div", [_c("div", {
    staticClass: "mt1"
  }, [_vm._v(" 复制公众号名称"), _c("b", [_vm._v("「" + _vm._s(_vm.pubName) + "」")])]), _c("div", {
    staticClass: "mb2"
  }, [_vm._v("在微信中搜索并关注")]), _c("div", {
    staticClass: "add-quick-btn bg_primary white fs14",
    on: {
      click: _vm.copyWxPubAndOpenWechat
    }
  }, [_vm._v("复制并打开微信")])])], 1), _c("div", {
    staticClass: "close-btn",
    on: {
      click: _vm.close
    }
  }, [_c("van-icon", {
    attrs: {
      size: "40",
      name: "close"
    }
  })], 1)]);
};

var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };