import _objectSpread from "/root/workspace/dev-client-a/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "com-goods-list",
    style: _vm.styleWrap
  }, [_vm._t("header"), _vm._loading ? _c("div", {
    staticClass: "loading-container flex flex-column items-center"
  }, [_c("img", {
    staticStyle: {
      "margin-top": "10vh"
    },
    attrs: {
      width: "100",
      src: _vm.renderBrandElement({
        id: "goodsList.loading",
        type: "image"
      })
    }
  }), _c("span", {
    staticClass: "loading-container-text",
    style: _vm.styleLoadingText
  }, [_vm._v("更多超值商品加载中")])]) : _vm._e(), !_vm._loading ? _c("div", [_vm.useVirtualList ? _c("div", [_c("virtual-list", {
    attrs: {
      "item-class": _vm.cellPerRow !== 1 ? "In_c_div" : "",
      "item-style": _objectSpread({
        width: _vm.cellPerRow === 1 ? "100%" : "calc((100% - 3%) / ".concat(_vm.cellPerRow, ")")
      }, _vm.itemStyle),
      "wrap-class": "virtual-list-wrap",
      "data-key": _vm.dataKey,
      "data-sources": _vm.goodsList,
      "data-component": _vm.goodsItem,
      "extra-props": {
        onClickItem: _vm.onClickItem,
        pId: _vm.pId,
        type: _vm.type,
        pageSource: _vm.pageSource,
        keyWords: _vm.keyWords,
        showRankTag: _vm.showRankTag
      },
      keeps: _vm.goodsList.length
    }
  })], 1) : _c("div", _vm._l(_vm.goodsList, function (item) {
    return _c("DirectRenderItem", {
      key: item[_vm.dataKey],
      class: _vm.cellPerRow !== 1 ? "In_c_div" : "",
      style: _objectSpread({
        width: _vm.cellPerRow === 1 ? "100%" : "calc((100% - 3%) / ".concat(_vm.cellPerRow, ")")
      }, _vm.itemStyle),
      attrs: {
        source: item,
        "data-component": _vm.goodsItem,
        "extra-props": {
          onClickItem: _vm.onClickItem,
          pId: _vm.pId,
          type: _vm.type,
          pageSource: _vm.pageSource,
          keyWords: _vm.keyWords
        }
      }
    });
  }), 1), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.goodsList.length % 2 && _vm.cellPerRow !== 1 && _vm.showPlaceholderItem,
      expression: "goodsList.length % 2 && cellPerRow !== 1 && showPlaceholderItem"
    }],
    staticClass: "more-goods",
    style: {
      "background-image": "url(".concat(_vm.renderBrandElement({
        id: "goodsList.moreItemImg",
        type: "image"
      }), ")")
    },
    on: {
      click: _vm.onClickMore
    }
  })]) : _vm._e()], 2);
};

var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };