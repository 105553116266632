import _objectSpread from "/root/workspace/dev-client-a/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.search.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.url-search-params.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.map.js";

/* eslint-disable camelcase */
import store from '@/store';
import xEnum from 'base/utils/enum';
import utils from 'base/utils';
export var EventType = {
  Exp: 'EXP',
  Click: 'CLK',
  Other: 'OTHER',
  Pv: 'PV'
};

function getUserGlobalParams() {
  var currentSourceEnum = Number(process.env.VUE_APP_SOURCE_ENUM);

  var sourceXEnum = _.find(xEnum.ENUM_DATA_REPORT_SOURCES, function (item) {
    return item.value === currentSourceEnum;
  }); //创建用户唯一会话id


  var userSessionId = '';

  if (window.sessionStorage.getItem('userSessionId')) {
    userSessionId = window.sessionStorage.getItem('userSessionId');
  } else {
    userSessionId = utils.getSessionId();
    window.sessionStorage.setItem('userSessionId', userSessionId);
  }

  var queryString = window.location.search;
  var urlParams = new URLSearchParams(queryString);
  var paramsObj = {};
  urlParams.forEach(function (value, key) {
    paramsObj[key] = value;
  }); // 解决微信浏览器环境中pId后接?现象

  var pId = paramsObj.pId ? paramsObj.pId[paramsObj.pId.length - 1] === '?' ? paramsObj.pId.slice(0, paramsObj.pId.length - 1) : paramsObj.pId : '';
  var userGlobalParams = {
    project_id: pId || store.state.appState.pId,
    user_id: store.state.user.userId || localStorage.getItem('userId'),
    web_url: window.location.href,
    point: store.state.user.points.activePoints,
    source: sourceXEnum.value,
    app_type: store.state.appState.appType,
    phone: store.state.user.phone || '',
    app_branch_type: 'B',
    project_name: store.state.appState.projectName,
    user_session_id: userSessionId
  };
  return userGlobalParams;
} // 事件日志


export function sendQuickTrackingEvent(_ref) {
  var eventName = _ref.eventName,
      eventType = _ref.eventType,
      params = _ref.params;
  var _window = window,
      aliTracker = _window.aliTracker;
  if (!aliTracker) return;
  var userGlobalParams = getUserGlobalParams();
  var regex = /\/vantmall(.*)/;
  var result = regex.exec(window.location.pathname);
  var eventPagePath = '';

  if (result) {
    eventPagePath = result[1].includes('goodsProfile') ? '/goodsProfile' : result[1];
  }

  var forwardPathParams = store.state.appState.forwardPathParams;
  aliTracker.sendImmediate({
    eventName: eventName,
    eventType: eventType,
    gp: _objectSpread(_objectSpread({
      event_page_path: eventPagePath
    }, userGlobalParams), forwardPathParams),
    // 全局属性
    cusp: _objectSpread(_objectSpread({}, params), {}, {
      page_name: (qtPageMap === null || qtPageMap === void 0 ? void 0 : qtPageMap.get(eventPagePath)) || 'unknown'
    }),
    // 自定义属性
    user_agent: navigator.userAgent,
    timestamp: new Date().getTime()
  });
} // 页面日志

export function sendPageTrackEvent(pagePath, forwardPathParams, toPath) {
  var _window2 = window,
      aliTracker = _window2.aliTracker;
  if (!aliTracker) return;
  var purePath = utils.extractPathAndId(pagePath);
  var pageName = (qtPageMap === null || qtPageMap === void 0 ? void 0 : qtPageMap.get(purePath)) || 'unknown';
  var userGlobalParams = getUserGlobalParams();
  var currentWebUrl = window.location.origin + '/vantmall' + toPath;

  var globalParams = _objectSpread(_objectSpread({
    event_page_path: purePath
  }, userGlobalParams), {}, {
    web_url: currentWebUrl
  });

  var pvParams = {
    pageName: pageName,
    eventName: pageName,
    user_agent: navigator.userAgent,
    timestamp: new Date().getTime()
  };

  if (store.state.user.userId) {
    aliTracker.sendImmediate(_objectSpread({
      eventType: EventType.Pv,
      gp: _objectSpread(_objectSpread({}, globalParams), forwardPathParams)
    }, pvParams));
  } else {
    store.state.appState.cacheQtEvents.push({
      globalParams: globalParams,
      pvParams: pvParams
    });
  }
}
var qtPageMap = new Map([['/complain', 'dacuecmc_complaint_page'], ['/leftFirst', 'dacuecmc_crazy_rank_page'], ['/activity/brand', 'dacuecmc_event_page'], ['/activity/returnScore', 'dacuecmc_full_return_page'], ['/goodsProfile', 'dacuecmc_goods_detail_page'], ['/home', 'dacuecmc_hp_page'], ['/activity/timeLimit', 'dacuecmc_limited_time_flash_sale_page'], ['/activity/timeLimit', 'dacuecmc_limited_time_flash_sale_page'], ['/express', 'dacuecmc_logistics_page'], ['/myorder3', 'dacuecmc_myorder_page'], ['/customService1', 'dacuecmc_ocsp_page'], ['/payindex', 'dacuecmc_orderconfirm_page'], ['/ordersuccess', 'dacuecmc_payment_suc_page'], ['/mine', 'dacuecmc_personal_page'], ['/userInfo', 'dacuecmc_personal_page'], ['/primary1', 'dacuecmc_points_center_page'], ['/protocol', 'dacuecmc_privacy_policy_page'], ['/goodsSearch', 'dacuecmc_search_page'], ['/grantPhone', 'dacuecmc_authorization_page'], ['/centerPageA', 'dacuecmc_push_flow_page'], ['/centerPageB', 'dacuecmc_push_flow_page'], ['/fillRefundNumber', 'dacuecmc_fill_refund_number_page'], ['/serviceOrdersDetail', 'dacuecmc_service_orders_detail_page'], ['/cooperation', 'dacuecmc_cooperation_page'], ['/comment', 'dacuecmc_comment_page']]);
export var sendCacheQtEvents = function sendCacheQtEvents(userId) {
  var _window3 = window,
      aliTracker = _window3.aliTracker;
  if (!aliTracker) return;
  var userGlobalParams = getUserGlobalParams();
  store.state.appState.cacheQtEvents.forEach(function (event) {
    var globalParams = event.globalParams,
        pvParams = event.pvParams;
    aliTracker.sendImmediate(_objectSpread({
      eventType: EventType.Pv,
      gp: _objectSpread(_objectSpread(_objectSpread({}, globalParams), userGlobalParams), {}, {
        user_id: userId
      })
    }, pvParams));
  });
  store.state.appState.cacheQtEvents = [];
};
export default {
  sendQuickTrackingEvent: sendQuickTrackingEvent,
  sendPageTrackEvent: sendPageTrackEvent,
  sendCacheQtEvents: sendCacheQtEvents,
  qtPageMap: qtPageMap
};