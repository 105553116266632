import _objectSpread from "/root/workspace/dev-client-a/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import _createForOfIteratorHelper from "/root/workspace/dev-client-a/node_modules/@babel/runtime/helpers/esm/createForOfIteratorHelper.js";
import _toConsumableArray from "/root/workspace/dev-client-a/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.fill.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.array.sort.js";
import "core-js/modules/es.array.flat-map.js";
import "core-js/modules/es.array.unscopables.flat-map.js";
import "core-js/modules/es.array.find.js";
import { checkIntersectionObserver } from 'base/utils';
export default {
  props: {
    // 瀑布流数据
    goodsList: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    // 列数
    col: {
      type: Number,
      default: 2
    },
    finished: {
      type: Boolean,
      default: function _default() {
        return false;
      }
    },
    // 行、列间距
    gap: {
      type: Array,
      default: function _default() {
        return ['0px', '0px'];
      }
    },
    // 首屏开启并行渲染，减少白屏时间
    firstPageCount: {
      type: Number,
      default: 6
    },
    animation: {
      type: String,
      default: 'hapi' // hapi|osiris

    },
    // 扩展intersectionRect交叉区域，可以提前加载部分数据，优化用户浏览体验
    rootMargin: {
      type: String,
      default: '0px 0px 400px 0px'
    }
  },
  data: function data() {
    return {
      colData: [[], []],
      observerObj: null,
      minCol: 0,
      // 最小列索引
      innerData: [],
      // 瀑布流数据队列
      counts: 0,
      // 已经渲染的数量
      oIndex: 0
    };
  },
  watch: {
    goodsList: {
      handler: function handler(nVal) {
        this.innerData = [].concat(_toConsumableArray(this.innerData), _toConsumableArray(nVal));
        this.waterfall();
      },
      immediate: true
    }
  },
  created: function created() {
    var _this = this;

    // 不支持IntersectionObserver的场景下，动态引入polyfill
    var ioPromise = checkIntersectionObserver() ? Promise.resolve() : import('intersection-observer'); // console.log('ioPromise: ', checkIntersectionObserver())

    ioPromise.then(function () {
      // 瀑布流布局：取出数据源中最靠前的一个并添加到瀑布流高度最小的那一列，等图片完全加载后重复该循环
      _this.observerObj = new IntersectionObserver(function (entries) {
        var _iterator = _createForOfIteratorHelper(entries),
            _step;

        try {
          var _loop = function _loop() {
            var entry = _step.value;
            var target = entry.target,
                isIntersecting = entry.isIntersecting;

            if (isIntersecting) {
              var done = function done() {
                // console.log('innerData: ', innerData)
                if (_this.innerData.length) {
                  _this.waterfall();
                } else if (!_this.finished) {
                  _this.$emit('load-more');
                } // 停止观察，防止回拉时二次触发监听逻辑


                _this.observerObj.unobserve(target);
              }; // console.info('target.complete: ', target)


              if (target.complete) {
                done();
              } else {
                target.onerror = done;
                target.onload = done;
              }
            }
          };

          for (_iterator.s(); !(_step = _iterator.n()).done;) {
            _loop();
          }
        } catch (err) {
          _iterator.e(err);
        } finally {
          _iterator.f();
        }
      }, {
        rootMargin: _this.rootMargin
      });
    });
  },
  methods: {
    restRenderCounts: function restRenderCounts() {
      this.counts = 0;
    },
    updateMinCol: function updateMinCol() {
      // console.log('this.firstPageCount: ', this.firstPageCount)
      // console.log('counts: ', this.counts)
      // 并行渲染时，无法获取最小列，只能按列依次渲染
      if (this.counts <= this.firstPageCount) {
        this.minCol = this.counts % this.col;
        return;
      } // console.log("this.$refs['cols']: ", this.$refs['cols'])


      var heightList = this.$refs['cols'].map(function (item) {
        return item.offsetHeight;
      });
      var minHeight = Math.min.apply(Math, _toConsumableArray(heightList));
      this.minCol = heightList.indexOf(minHeight);
    },
    appendColData: function appendColData() {
      var colItem = this.innerData.shift(); // console.log('colItem: ', colItem)

      this.colData[this.minCol].push(_objectSpread(_objectSpread({}, colItem), {}, {
        oIndex: this.oIndex++
      }));
    },
    insertColData: function insertColData(index, newList) {
      var _this$innerData, _this$innerData2;

      this.recommendLockHandler(index);
      var spliceColData = new Array(this.col).fill(new Array());
      this.colData.forEach(function (col, colIndex) {
        var spliceIndex = col.findIndex(function (item) {
          return item.oIndex > index;
        });

        if (spliceIndex === -1) {
          spliceColData[colIndex] = [];
        } else {
          spliceColData[colIndex] = col.splice(spliceIndex);
        }
      }); // 把需要插入的 newList 往innerData里插入

      (_this$innerData = this.innerData).unshift.apply(_this$innerData, _toConsumableArray(spliceColData.flatMap(function (item) {
        return item;
      }).sort(function (a, b) {
        return a.oIndex - b.oIndex;
      }))); // console.log('this.colData', JSON.parse(JSON.stringify(this.colData)))


      (_this$innerData2 = this.innerData).unshift.apply(_this$innerData2, _toConsumableArray(newList)); // console.log('this.innerData', JSON.parse(JSON.stringify(this.innerData)))
      // 重置已渲染的数量


      this.counts = index + 1;
      this.oIndex = index + 1; // rerender

      this.waterfall();
    },
    recommendLockHandler: function recommendLockHandler(index) {
      var isLocked = false;
      var i = 0;

      while (!isLocked) {
        var anchor = this.colData[i].find(function (item) {
          return item.oIndex === index;
        });

        if (anchor) {
          anchor.recommendLock = true;
          isLocked = true;
        }

        i++;
      }
    },
    startObserver: function startObserver() {
      // 开始监测新增加的瀑布流元素
      var nodes = this.$refs['cols'][this.minCol].querySelectorAll('.van-image__img');
      var lastNode = nodes[nodes.length - 1];
      this.observerObj.observe(lastNode);
    },
    waterfall: function waterfall() {
      var _this2 = this;

      // 更新瀑布流高度最小列
      this.updateMinCol(); // 取出数据源中最靠前的一个并添加到瀑布流高度最小的那一列

      this.appendColData(); // 首屏采用并行渲染，非首屏采用串行渲染

      if (++this.counts < this.firstPageCount) {
        this.$nextTick(function () {
          return _this2.waterfall();
        });
      } else {
        this.$nextTick(function () {
          return _this2.startObserver();
        });
      }
    }
  }
};