import { render, staticRenderFns } from "./CopyRightThemed.vue?vue&type=template&id=b845600e&scoped=true&"
import script from "./CopyRightThemed.vue?vue&type=script&lang=js&"
export * from "./CopyRightThemed.vue?vue&type=script&lang=js&"
import style0 from "./CopyRightThemed.vue?vue&type=style&index=0&id=b845600e&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b845600e",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/workspace/dev-client-a/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('b845600e')) {
      api.createRecord('b845600e', component.options)
    } else {
      api.reload('b845600e', component.options)
    }
    module.hot.accept("./CopyRightThemed.vue?vue&type=template&id=b845600e&scoped=true&", function () {
      api.rerender('b845600e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/widgets/CopyRightThemed.vue"
export default component.exports