import "core-js/modules/es.number.constructor.js";
import { IconFont } from 'components';
import { sendQuickTrackingEvent, EventType } from 'base/utils/quickTracking';
export default {
  name: 'HomeHeader',
  components: {
    IconFont: IconFont
  },
  props: {
    title: {
      type: String,
      default: function _default() {
        return '';
      }
    },
    searchIcon: {
      type: String,
      default: function _default() {
        return 'iconsousuo';
      }
    },
    searchIconColor: {
      type: String,
      default: function _default() {
        return '#d92e21';
      }
    },
    recommendWords: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    hasCustom: {
      type: Boolean,
      default: function _default() {
        return true;
      }
    },
    initialIndex: {
      type: Number,
      default: function _default() {
        return 0;
      }
    },
    recommendWord: {
      type: String,
      default: function _default() {
        return '';
      }
    }
  },
  data: function data() {
    return {};
  },
  methods: {
    toSearch: function toSearch() {
      this.$router.push({
        path: '/goodsSearch',
        query: {
          recommendWord: this.recommendWord
        }
      });
      sendQuickTrackingEvent({
        eventName: 'ducuecmc_hp_searchbar_clk',
        eventType: EventType.Click,
        pageName: 'dacuecmc_hp_page',
        params: {}
      });
    },
    toCustomService: function toCustomService() {
      this.$router.push({
        path: '/customService1',
        query: {
          location: window.location.href
        }
      });
      sendQuickTrackingEvent({
        eventName: 'ducuecmc_hp_humanservice_clk',
        eventType: EventType.Click,
        pageName: 'dacuecmc_hp_page',
        params: {}
      });
    },
    recommendWordChange: function recommendWordChange(index) {
      this.$emit('recommend-word-change', index);
    }
  }
};