var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "copy-right",
    style: _vm.styleWrap
  }, [_c("p", {
    staticClass: "nogg",
    staticStyle: {
      "font-weight": "700",
      "margin-bottom": "10px"
    }
  }, [_vm._v("@本活动解释权归" + _vm._s(_vm.mall) + "所有")]), _c("a", {
    staticClass: "nogg block black",
    staticStyle: {
      "margin-bottom": "0px",
      "padding-bottom": "10px"
    },
    attrs: {
      href: "https://beian.miit.gov.cn/"
    }
  }, [_vm._v(_vm._s(_vm.record))]), _vm.showProtocol ? _c("p", {
    staticClass: "nogg",
    staticStyle: {
      "margin-bottom": "0px",
      "padding-bottom": "10px"
    },
    on: {
      click: _vm.toProtocol
    }
  }, [_vm._v(" 《隐私协议》 ")]) : _vm._e()]);
};

var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };