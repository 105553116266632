import _objectSpread from "/root/workspace/dev-client-a/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "notice-bar",
    staticStyle: {
      border: "none"
    }
  }, [!_vm.enableVerticalScroll ? _c("div", {}, [_c("van-notice-bar", {
    style: _objectSpread({}, _vm.noticeBarStyle),
    attrs: {
      "left-icon": _vm.leftIcon,
      speed: _vm.speed,
      scrollable: _vm.scrollable,
      text: _vm.noticeList
    }
  })], 1) : _c("div", {}, [_c("van-notice-bar", {
    style: _objectSpread({}, _vm.noticeBarStyle),
    attrs: {
      "left-icon": _vm.leftIcon,
      scrollable: false,
      speed: _vm.speed
    }
  }, [_c("van-swipe", {
    staticClass: "notice-swipe",
    attrs: {
      vertical: "",
      autoplay: 3000,
      "show-indicators": false
    }
  }, _vm._l(_vm.noticeList, function (item) {
    return _c("van-swipe-item", {
      key: item
    }, [_vm._v(_vm._s(item))]);
  }), 1)], 1)], 1)]);
};

var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };