import "core-js/modules/es.string.fixed.js";

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "com-deal-bubble"
  }, [_c("div", {
    staticClass: "trade-carousel",
    class: _vm.fixed ? "fixed" : "",
    style: _vm.containerStyle,
    attrs: {
      id: "trade-carousel"
    }
  }, [_c("div", {
    staticClass: "trade-carousel__swipe"
  }, [_c("div", {
    staticClass: "trade-carousel__swipe-item move-up",
    attrs: {
      id: "trade-carousel-item"
    }
  }, [_c("span", {
    staticClass: "trade-carousel__swipe-text"
  }, [_vm._v(_vm._s(_vm.qipao.msg))])])])])]);
};

var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };