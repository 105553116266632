import _objectSpread from "/root/workspace/dev-client-a/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { mapGetters } from 'vuex';
export default {
  name: 'CopyRightThemed',
  props: {
    styleWrap: {
      type: Object,
      default: function _default() {
        return {
          color: '#666'
        };
      }
    },
    showProtocol: {
      type: Boolean,
      default: function _default() {
        return false;
      }
    }
  },
  data: function data() {
    return {
      mall: this.renderBrandElement({
        id: 'global.mall'
      }),
      record: this.renderBrandElement({
        id: 'global.icp'
      })
    };
  },
  computed: _objectSpread({}, mapGetters({
    appState: 'appState'
  })),
  created: function created() {},
  mounted: function mounted() {},
  destroyed: function destroyed() {},
  methods: {
    toProtocol: function toProtocol() {
      this.$router.push({
        path: '/protocol'
      });
    }
  }
};