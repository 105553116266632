import _objectSpread from "/root/workspace/dev-client-a/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("van-count-down", {
    staticClass: "count-down flex items-center justify-center",
    style: {
      color: _vm.fontColor
    },
    attrs: {
      millisecond: "",
      format: _vm.format,
      time: _vm.countDownTime
    },
    scopedSlots: _vm._u([_vm.type === "normal" ? {
      key: "default",
      fn: function fn(timeData) {
        return [_c("span", {
          staticClass: "block",
          style: _objectSpread({
            backgroundColor: _vm.bgColor
          }, _vm.blockStyle)
        }, [_vm._v(_vm._s(_vm._f("padZero")(timeData.hours)))]), _c("span", {
          staticClass: "colon",
          style: _objectSpread({
            color: _vm.colonColor
          }, _vm.colonStyle)
        }, [_vm._v(_vm._s(_vm.colonText))]), _c("span", {
          staticClass: "block",
          style: _objectSpread({
            backgroundColor: _vm.bgColor
          }, _vm.blockStyle)
        }, [_vm._v(_vm._s(_vm._f("padZero")(timeData.minutes)))]), _c("span", {
          staticClass: "colon",
          style: _objectSpread({
            color: _vm.colonColor
          }, _vm.colonStyle)
        }, [_vm._v(_vm._s(_vm.colonText))]), _c("span", {
          staticClass: "block",
          style: _objectSpread({
            backgroundColor: _vm.bgColor
          }, _vm.blockStyle)
        }, [_vm._v(_vm._s(_vm._f("padZero")(timeData.seconds)))]), _c("span", {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.millisecond,
            expression: "millisecond"
          }],
          staticClass: "colon",
          style: _objectSpread({
            color: _vm.colonColor
          }, _vm.colonStyle)
        }, [_vm._v(_vm._s(_vm.colonText))]), _c("span", {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.millisecond && _vm.msTwoLetter,
            expression: "millisecond && msTwoLetter"
          }],
          staticClass: "block",
          style: _objectSpread({
            backgroundColor: _vm.bgColor
          }, _vm.blockStyle)
        }, [_vm._v(_vm._s(_vm._f("formatMs")(timeData.milliseconds)))]), _c("span", {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.millisecond && !_vm.msTwoLetter,
            expression: "millisecond && !msTwoLetter"
          }],
          staticClass: "block",
          style: _objectSpread({
            backgroundColor: _vm.bgColor
          }, _vm.blockStyle)
        }, [_vm._v(_vm._s(String(timeData.milliseconds).substring(0, 2)))])];
      }
    } : {
      key: "default",
      fn: function fn(timeData) {
        return [_c("div", {
          staticClass: "block split-digit-0",
          style: _objectSpread({
            backgroundColor: _vm.bgColor
          }, _vm.blockStyle)
        }, [_vm._v(" " + _vm._s(_vm._f("splitTime")(timeData.hours, 0)) + " ")]), _c("div", {
          staticClass: "block split-digit-1",
          style: _objectSpread({
            backgroundColor: _vm.bgColor
          }, _vm.blockStyle)
        }, [_vm._v(" " + _vm._s(_vm._f("splitTime")(timeData.hours, 1)) + " ")]), _c("div", {
          staticClass: "colon",
          style: _objectSpread({
            color: _vm.colonColor
          }, _vm.colonStyle)
        }, [_vm._v("时")]), _c("div", {
          staticClass: "block split-digit-0",
          style: _objectSpread({
            backgroundColor: _vm.bgColor
          }, _vm.blockStyle)
        }, [_vm._v(" " + _vm._s(_vm._f("splitTime")(timeData.minutes, 0)) + " ")]), _c("div", {
          staticClass: "block split-digit-1",
          style: _objectSpread({
            backgroundColor: _vm.bgColor
          }, _vm.blockStyle)
        }, [_vm._v(" " + _vm._s(_vm._f("splitTime")(timeData.minutes, 1)) + " ")]), _c("div", {
          staticClass: "colon",
          style: _objectSpread({
            color: _vm.colonColor
          }, _vm.colonStyle)
        }, [_vm._v("分")]), _c("div", {
          staticClass: "block split-digit-0",
          style: _objectSpread({
            backgroundColor: _vm.bgColor
          }, _vm.blockStyle)
        }, [_vm._v(" " + _vm._s(_vm._f("splitTime")(timeData.seconds, 0)) + " ")]), _c("div", {
          staticClass: "block split-digit-1",
          style: _objectSpread({
            backgroundColor: _vm.bgColor
          }, _vm.blockStyle)
        }, [_vm._v(" " + _vm._s(_vm._f("splitTime")(timeData.seconds, 1)) + " ")]), _c("div", {
          staticClass: "colon",
          style: _objectSpread({
            color: _vm.colonColor
          }, _vm.colonStyle)
        }, [_vm._v("秒")])];
      }
    }], null, true)
  });
};

var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };