var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "com-goods-gallery"
  }, [_c("van-swipe", {
    attrs: {
      autoplay: -1,
      "indicator-color": "#f44",
      "show-indicators": false,
      loop: false,
      width: 146
    }
  }, _vm._l(_vm.goodsList, function (good, index) {
    return _c("van-swipe-item", {
      key: index,
      on: {
        click: function click($event) {
          return _vm.goodClick(good, index);
        }
      }
    }, [_c("div", {
      staticClass: "P_ul_li box sbImg"
    }, [_c("div", {
      staticStyle: {
        width: "100%",
        background: "whitesmoke",
        position: "relative"
      }
    }, [_c("div", {
      staticStyle: {
        position: "relative"
      }
    }, [_c("van-image", {
      staticClass: "phImg",
      attrs: {
        src: good.imgUrl,
        "lazy-load": false
      },
      scopedSlots: _vm._u([{
        key: "loading",
        fn: function fn() {
          return [_c("van-loading", {
            attrs: {
              type: "spinner"
            }
          })];
        },
        proxy: true
      }], null, true)
    }), good.stockNum <= 0 ? _c("img", {
      staticClass: "dw_img",
      attrs: {
        src: require("base/assets/img/dw.png")
      }
    }) : _vm._e()], 1)]), _c("p", {
      staticClass: "In_c_r_p"
    }, [_vm._v(_vm._s(good.goodsName))]), _c("div", {
      staticClass: "In_c_r_p1 flex items-center"
    }, [_c("span", {
      staticClass: "In_c_r_p2 mr2"
    }, [_vm._v("￥" + _vm._s(good.price))]), _c("span", {
      staticClass: "In_c_r_p3 fs13"
    }, [_vm._v("￥" + _vm._s(good.originalPrice) + "元")])]), _c("p", {
      staticClass: "In_c_r_p4"
    }, [_vm._v(_vm._s(good.saleDesc))])])]);
  }), 1)], 1);
};

var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };