import "vant/es/dialog/style";
import _Dialog from "vant/es/dialog";
import _objectSpread from "/root/workspace/dev-client-a/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.number.constructor.js";
import { trackUserEvent } from 'base/utils/rp';
import { ImageThemed } from 'components';
import xEnum from 'base/utils/enum';
import { mapState } from 'vuex';
export default {
  name: 'GoodsSeckillItemThemed',
  components: {
    ImageThemed: ImageThemed
  },
  filters: {
    formatSaleDesc: function formatSaleDesc(value) {
      if (!value) {
        return '';
      }

      return String(value).replace('兑换热度', '兑换热度');
    }
  },
  props: {
    source: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    index: {
      type: Number,
      default: 0
    },
    type: {
      type: Number,
      default: 0
    },
    pageSource: {
      type: Number,
      default: function _default() {
        return xEnum.ENUM_PAGE_SOURCE.UNKNOWN.getValue();
      }
    },
    onClickItem: {
      type: Function,
      default: function _default() {}
    }
  },
  computed: _objectSpread({}, mapState({
    pId: function pId(state) {
      return state.appState.pId;
    },
    userId: function userId(state) {
      return state.user.userId;
    }
  })),
  methods: {
    onClick: function onClick() {
      var source = this.source,
          index = this.index;

      if (source.stockNum <= 0) {
        _Dialog.alert({
          message: '非常遗憾，本商品已抢完，您可以挑选其它商品',
          confirmButtonText: '好的'
        });

        return;
      }

      this.$router.push({
        path: "/goodsProfile/".concat(source.id),
        query: {
          type: this.type,
          activityType: 'seckill',
          pageSource: this.pageSource
        }
      });
      this.onClickItem(source, index);
      var eventInfo = {
        pId: this.pId,
        userId: this.userId,
        goodsId: source.id,
        goodsName: source.goodsName,
        goodsIndex: index,
        goodsGroupId: this.type
      };
      trackUserEvent({
        eventType: 'goodsClick',
        eventInfo: eventInfo
      });
    }
  }
};