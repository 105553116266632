var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "capsule-area px2",
    on: {
      click: _vm.onClick
    }
  }, [_c("img", {
    staticClass: "fit",
    attrs: {
      src: _vm.renderBrandElement({
        id: "homeCapsule.banner"
      }),
      alt: ""
    }
  })]);
};

var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };