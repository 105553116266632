import { render, staticRenderFns } from "./payOnDeliveryDialog.vue?vue&type=template&id=6dc7d857&scoped=true&"
import script from "./payOnDeliveryDialog.vue?vue&type=script&lang=js&"
export * from "./payOnDeliveryDialog.vue?vue&type=script&lang=js&"
import style0 from "./payOnDeliveryDialog.vue?vue&type=style&index=0&id=6dc7d857&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6dc7d857",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/workspace/dev-client-a/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6dc7d857')) {
      api.createRecord('6dc7d857', component.options)
    } else {
      api.reload('6dc7d857', component.options)
    }
    module.hot.accept("./payOnDeliveryDialog.vue?vue&type=template&id=6dc7d857&scoped=true&", function () {
      api.rerender('6dc7d857', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/payOnDeliveryDialog/payOnDeliveryDialog.vue"
export default component.exports