import _objectSpread from "/root/workspace/dev-client-a/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { mapState } from 'vuex';
import { eventBus } from 'base/utils/eventBus';
export default {
  name: 'QrcodeGuideDialog',
  components: {},
  props: {
    visible: {
      type: Boolean,
      default: function _default() {
        return false;
      }
    },
    title: {
      type: String,
      default: function _default() {
        return '关注公众号';
      }
    },
    pubName: {
      type: String,
      default: ''
    },
    pubImage: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {};
  },
  computed: _objectSpread({}, mapState({
    isInWechat: function isInWechat(state) {
      return state.appState.isInWechat;
    }
  })),
  created: function created() {},
  methods: {
    close: function close() {
      this.$emit('update:visible', false);
    },
    previeWxPubImage: function previeWxPubImage() {// wechatSdk.previewImage(imageUrl, [imageUrl])
    },
    copyWxPubAndOpenWechat: function copyWxPubAndOpenWechat() {
      eventBus.$emit('copy-to-clipboard', {
        content: this.pubName,
        silent: false,
        onSuccess: function onSuccess() {
          console.log('copy success');
          location.href = 'weixin://';
        }
      });
    }
  }
};