var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return !_vm.hidden && _vm.points.activePoints > 0 ? _c("div", {
    staticClass: "com-point-left-mask top",
    style: {
      top: "".concat(_vm.topOffset, "px")
    }
  }, [_vm._v(" 我的" + _vm._s(_vm.globalPointName)), _c("span", [_vm._v(_vm._s(_vm.points.activePoints))])]) : _vm._e();
};

var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };