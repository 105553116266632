import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.number.to-fixed.js";
import "core-js/modules/es.number.constructor.js";

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    directives: [{
      name: "track",
      rawName: "v-track:exposure",
      arg: "exposure"
    }],
    staticClass: "time-limit-item flex p2",
    attrs: {
      "track-params": JSON.stringify(_vm.getEventInfo(_vm.source, _vm.index))
    },
    on: {
      click: _vm.onClick
    }
  }, [_c("van-image", {
    staticClass: "left-part flex-none",
    attrs: {
      src: _vm.source.imgUrl,
      "lazy-load": true
    },
    scopedSlots: _vm._u([{
      key: "loading",
      fn: function fn() {
        return [_c("van-loading", {
          attrs: {
            type: "spinner"
          }
        })];
      },
      proxy: true
    }])
  }), _c("div", {
    staticClass: "hint absolute"
  }, [_vm._v("等价全额返还")]), _c("div", {
    staticClass: "right-part flex flex-column justify-between flex-auto ml2"
  }, [_c("div", {
    staticClass: "description"
  }, [_vm._v(_vm._s(_vm.source.goodsName))]), _c("div", {
    staticClass: "sales flex items-center"
  }, [_c("img", {
    staticClass: "icon",
    attrs: {
      src: require("base/assets/img/returnScore/flame.png")
    }
  }), _vm._v(" " + _vm._s(_vm._f("sails")(_vm.source.saleDesc)) + " ")]), _c("div", {
    staticClass: "bottom-part flex justify-end"
  }, [_c("div", {
    staticClass: "buy-btn flex items-center px2 py1 white animate__animated animate__infinite animate__pulse animate__fast"
  }, [_vm._v(" 等价全返"), _c("span", {
    staticStyle: {
      "text-decoration": "line-through"
    }
  }, [_vm._v("￥" + _vm._s(Number(_vm.source.price).toFixed(2)))])])])])], 1);
};

var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };