import Vue from 'vue';
import Vuex from 'vuex';
import user from './modules/user';
import appState from './modules/appState';
import buryState from './modules/bury';
import orderList from 'base/store/modules/orderList';
import statistics from 'base/store/modules/statistics';
import recommend from './modules/recommend';
import getters from './getters';
Vue.use(Vuex);
var store = new Vuex.Store({
  modules: {
    user: user,
    appState: appState,
    orderList: orderList,
    statistics: statistics,
    recommend: recommend,
    buryState: buryState
  },
  getters: getters
});
export default store;