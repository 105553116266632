var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "flex justify-between items-center px2 service-bg"
  }, _vm._l(_vm.sourceList, function (item) {
    return _c("IconTag", {
      key: item.text,
      attrs: {
        icon: item.iconClass,
        text: item.text,
        size: item.fontSize + "px",
        "icon-size": item.iconSize + "px",
        color: item.color
      }
    });
  }), 1);
};

var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };