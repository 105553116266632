import "vant/es/toast/style";
import _Toast from "vant/es/toast";
import _objectSpread from "/root/workspace/dev-client-a/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.number.constructor.js";
import { mapGetters } from 'vuex';
import xEnum from 'base/utils/enum';
import { trackEvent } from 'base/utils/rp';
import IconFont from 'components/IconFont';
import { eventBus } from 'base/utils/eventBus';
export default {
  components: {
    IconFont: IconFont
  },
  props: {
    trafficGuideEnterImg: {
      type: String,
      default: ''
    },
    trafficGuidePopImg: {
      type: String,
      default: ''
    },
    trafficGuideType: {
      type: Number,
      default: 0
    }
  },
  data: function data() {
    return {
      pubName: this.renderBrandElement({
        id: 'weChat.publicAccount'
      }),
      popupVisible: false,
      qrcode: this.renderBrandElement({
        id: 'weChat.wxPubImage',
        type: 'image'
      })
    };
  },
  computed: _objectSpread({}, mapGetters({
    appState: 'appState'
  })),
  methods: {
    onClickTrafficGuideEnterImg: function onClickTrafficGuideEnterImg() {
      if (this.trafficGuideType === xEnum.ENUM_TRAFFIC_GUIDE_TYPE.DEFAULT.value) {
        this.popupVisible = true;
      } else {
        this.$emit('on-click-traffic-guide-enter-img');
      }
    },
    onSaveQrcode: function onSaveQrcode() {
      trackEvent({
        category: "\u6C89\u6DC0-\u516C\u4F17\u53F7",
        action: '保存二维码',
        opt_label: "\u603B\u6570"
      });
      this.wxPreview();
    },
    wxPreview: function wxPreview() {
      if (this.appState.isInWechat) {
        trackEvent({
          category: "\u6C89\u6DC0-\u516C\u4F17\u53F7",
          action: '保存二维码',
          opt_label: "\u5FAE\u4FE1\u5185"
        });

        if (typeof WeixinJSBridge == 'undefined') {
          trackEvent({
            category: "\u6C89\u6DC0-\u516C\u4F17\u53F7",
            action: '保存二维码',
            opt_label: "\u5FAE\u4FE1\u5185Bridge\u672A\u521D\u59CB\u5316"
          });
        } else {
          var url = window.location.origin + this.qrcode;
          window.WeixinJSBridge.invoke('imagePreview', {
            urls: [url],
            current: url
          });
        }
      }
    },
    copyWxpub: function copyWxpub() {
      trackEvent({
        category: "\u6C89\u6DC0-\u516C\u4F17\u53F7",
        action: '复制公众号',
        opt_label: "\u603B\u6570"
      });
      eventBus.$emit('copy-to-clipboard', {
        content: this.pubName,
        silent: false,
        onSuccess: function onSuccess() {
          trackEvent({
            category: "\u6C89\u6DC0-\u516C\u4F17\u53F7",
            action: '复制公众号',
            opt_label: "\u6210\u529F"
          });

          _Toast.success('复制成功');

          location.href = 'weixin://';
        }
      });
    }
  }
};