var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "slide-grid-bar flex flex-column items-center"
  }, [_c("div", {
    staticClass: "slide-grid flex",
    on: {
      scroll: _vm.handleScroll
    }
  }, _vm._l(_vm.gridList, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "grid-item py2 flex flex-column items-center justify-center",
      on: {
        click: function click($event) {
          return _vm.itemClick(item, index);
        }
      }
    }, [_c("img", {
      attrs: {
        src: item.img,
        alt: ""
      }
    }), _c("div", {
      staticClass: "fs14 color_text center"
    }, [_vm._v(_vm._s(item.businessKey.title))]), _c("div", {
      staticClass: "fs12 color_tips center"
    }, [_vm._v(_vm._s(item.businessKey.subTitle))])]);
  }), 0), _c("div", {
    staticClass: "scroll-bar mb1"
  }, [_c("div", {
    staticClass: "thumb relative",
    style: {
      width: _vm.thumbWidth + "px",
      left: _vm.thumbLeft + "px"
    }
  })])]);
};

var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };