import store from '@/store';
export default {
  name: 'PaySuccessfulDialog',
  data: function data() {
    return {
      logo: this.renderBrandElement({
        id: 'global.logo',
        type: 'image'
      }) || require(process.env.VUE_APP_GLOBAL_LOGO),
      visible: false,
      delayer: null
    };
  },
  computed: {
    returnDialogOption: function returnDialogOption() {
      return store.state.appState.orderSuccessfulDialogOption;
    }
  },
  mounted: function mounted() {
    var _this = this;

    this.visible = true;
    this.delayer = setTimeout(function () {
      _this.hide();
    }, this.returnDialogOption.duration);
  },
  destroyed: function destroyed() {
    clearTimeout(this.delayer);
  },
  methods: {
    hide: function hide() {
      this.visible = false;
    },
    clickItem: function clickItem() {
      this.hide();
      this.onClick();
    }
  }
};