var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "FootBar"
  }, [_c("div", {
    staticStyle: {
      height: "60px",
      clear: "both"
    }
  }), _c("van-tabbar", {
    staticClass: "tabbar",
    attrs: {
      "safe-area-inset-bottom": true,
      "active-color": "#d92e21",
      "inactive-color": "#acacac"
    },
    on: {
      change: _vm.onTabChange
    },
    model: {
      value: _vm.footBarActive,
      callback: function callback($$v) {
        _vm.footBarActive = $$v;
      },
      expression: "footBarActive"
    }
  }, [_vm._l(_vm.tabbarList, function (item, index) {
    return _c("van-tabbar-item", {
      key: index,
      attrs: {
        replace: "",
        to: item.to,
        name: item.to.path,
        badge: item.enableBadge && _vm.unpayOrderCount > 0 ? _vm.unpayOrderCount : ""
      },
      scopedSlots: _vm._u([{
        key: "icon",
        fn: function fn(props) {
          return [!props.active ? _c("img", {
            style: {
              height: item.to.path === "/primary1" ? "51px" : "28px"
            },
            attrs: {
              width: item.to.path === "/primary1" ? "75px" : "28px",
              src: item.to.path === "/primary1" ? _vm.tabbarCenterImage : item.inactiveImg
            }
          }) : _vm._e(), props.active ? _c("img", {
            style: {
              height: item.to.path === "/primary1" ? "51px" : "28px"
            },
            attrs: {
              width: item.to.path === "/primary1" ? "75px" : "28px",
              src: item.to.path === "/primary1" ? _vm.tabbarCenterImage : item.activeImg
            }
          }) : _vm._e()];
        }
      }], null, true)
    }, [item.to.path !== "/primary1" ? _c("span", [_vm._v(_vm._s(item.text))]) : _vm._e()]);
  }), _c("div", {
    staticClass: "point-center",
    style: {
      display: _vm.isShowFootBarTip ? "block" : "none"
    }
  }, [_c("img", {
    attrs: {
      width: "136px",
      height: "34px",
      src: _vm.tabbarFloatImg
    }
  })])], 2)], 1);
};

var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };