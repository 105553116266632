import _objectSpread from "/root/workspace/dev-client-a/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "con",
    style: _objectSpread({}, _vm.barStyle),
    on: {
      click: _vm.toSearchPage
    }
  }, [_c("div", {
    staticClass: "search-bar flex"
  }, [_c("div", {
    staticClass: "input-area relative"
  }, [_c("IconFont", {
    staticClass: "icon",
    attrs: {
      size: "20px",
      icon: "iconchaxun",
      color: "#7d7d7d"
    }
  }), _c("input", {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.keyWords,
      expression: "keyWords"
    }],
    attrs: {
      type: "text",
      maxlength: "15",
      placeholder: _vm.recommendWord
    },
    domProps: {
      value: _vm.keyWords
    },
    on: {
      focus: _vm.inputFocus,
      blur: _vm.inputBlur,
      input: function input($event) {
        if ($event.target.composing) return;
        _vm.keyWords = $event.target.value;
      }
    }
  })], 1), _c("div", {
    staticClass: "btn-area bg_primary",
    on: {
      click: _vm.searchGoods
    }
  }, [_vm._v("搜索")])])]);
};

var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };