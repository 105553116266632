var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "container fixed flex items-center justify-center",
    class: "".concat(_vm.shadow && "shadow")
  }, [_c("div", {
    staticClass: "bg_primary white h6 button",
    style: _vm.styleWrap,
    on: {
      click: function click($event) {
        return _vm.$emit("click");
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.title) + " ")])]);
};

var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };