var screenWidth = window.screen.width;
var screenHeight = window.screen.height;
var widthHeightRatio = 5 / 6;
export default {
  name: 'PayOnDeliveryDialog',
  directives: {
    // 【解决滑动穿透问题】该指令的注意点是必须使用v-if来开启关闭弹窗，因为该指令依赖于dom的插入和注销，使用v-show肯定是不行的
    fixed: {
      // inserted 被绑定元素插入父节点时调用
      inserted: function inserted() {
        var scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
        document.body.style.cssText += 'position:fixed;width:100%;top:-' + scrollTop + 'px;';
      },
      // unbind 指令与元素解绑时调用
      unbind: function unbind() {
        var body = document.body;
        body.style.position = '';
        var top = body.style.top;
        document.documentElement.scrollTop = -parseInt(top);
        document.body.scrollTop = -parseInt(top);
        body.style.top = '';
      }
    }
  },
  props: {},
  data: function data() {
    return {
      visible: true,
      screenHeight: screenHeight,
      imageWidth: screenWidth * (176 / 302),
      imageHeight: screenWidth * (176 / 302) / widthHeightRatio,
      isPackageClose: true
    };
  },
  computed: {},
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    closeDialog: function closeDialog() {
      this.visible = false;
    },
    onClickBtn: function onClickBtn() {
      this.closeDialog();
      this.onClick();
    }
  }
};