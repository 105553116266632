import "vant/es/dialog/style";
import _Dialog from "vant/es/dialog";
import _objectSpread from "/root/workspace/dev-client-a/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.array.slice.js";
import { mapState } from 'vuex';
import { getRecommendGoods } from 'base/api';
import xEnum from 'base/utils/enum';
import { trackUserEvent } from 'base/utils/rp';
import { sendQuickTrackingEvent, EventType } from 'base/utils/quickTracking';
export default {
  props: {
    goodsGroupType: {
      type: Number,
      default: xEnum.ENUM_GOODS_GROUP_TYPE.RATE_GROUP.getValue()
    }
  },
  data: function data() {
    return {
      goodsList: [],
      itemType: this.renderBrandElement({
        id: 'goodsGallery.itemType'
      }),
      recommendGoodsSource: {
        goodsId: '',
        goodsName: ''
      }
    };
  },
  computed: _objectSpread({}, mapState({
    pId: function pId(state) {
      return state.appState.pId;
    },
    userId: function userId(state) {
      return state.user.userId;
    }
  })),
  created: function created() {// this.reload()
  },
  methods: {
    goodClick: function goodClick(goods, index, swipeIndex) {
      if (goods.stockNum <= 0) {
        _Dialog.alert({
          message: '非常遗憾，本商品已兑完，您可以挑选其它商品',
          confirmButtonText: '好的'
        });

        return;
      }

      this.$router.push({
        path: "/goodsProfile/".concat(goods.id),
        query: {
          type: this.goodsGroupType,
          activityType: this.itemType
        }
      });
      var eventInfo = {
        pId: this.pId,
        userId: this.userId,
        goodsId: goods.id,
        score: goods === null || goods === void 0 ? void 0 : goods.score,
        goodsIndex: index,
        goodsGroupId: this.goodsGroupType,
        webUrl: window.location.href
      };
      trackUserEvent({
        eventType: xEnum.ENUM_RP_EVENT_TYPE.GOODS_DETAIL_RECOMMEND_CLICK.value,
        eventInfo: eventInfo
      });
      var goodsGroupType = this.goodsGroupType,
          recommendGoodsSource = this.recommendGoodsSource;
      sendQuickTrackingEvent({
        eventName: 'ducuecmc_hc_waterfalls_clk',
        eventType: EventType.Click,
        pageName: 'dacuecmc_all_page',
        params: {
          goods_score: goods === null || goods === void 0 ? void 0 : goods.score,
          goods_group_type: goodsGroupType,
          goods_name: goods.goodsName,
          goods_id: goods.id,
          goods_index: index,
          display_price: goods.price,
          display_original_price: goods.originalPrice,
          goods_recommend_source: recommendGoodsSource.goodsId,
          goods_recommend_source_goods_name: recommendGoodsSource.goodsName,
          recommend_swiper_index: swipeIndex,
          goods_source_waterfall: '商品详情页-推荐',
          goods_category_id: goods.categoryId
        }
      });
    },
    reload: function reload(id, sourceGoodsName) {
      var _this = this;

      var params = {
        pId: this.pId,
        goodsId: id,
        userId: this.userId || null
      };
      this.recommendGoodsSource = {
        goodsId: id,
        goodsName: sourceGoodsName
      };
      getRecommendGoods(params).then(function (response) {
        if (response.code === 200) {
          _this.goodsList = _.chain(response.data).slice(0, 12).chunk(6).value();
        }

        if (_this.goodsList.length) {
          _this.$emit('show-goods-gallery', true);
        }
      });
    },
    getEventInfo: function getEventInfo(goods, index, swipeIndex) {
      return {
        eventType: xEnum.ENUM_RP_EVENT_TYPE.GOODS_DETAIL_RECOMMEND_CARD_EP.value,
        eventInfo: {
          pId: this.pId,
          userId: this.userId,
          goodsId: goods.id,
          score: goods === null || goods === void 0 ? void 0 : goods.score,
          goodsIndex: index,
          goodsGroupId: this.goodsGroupType,
          webUrl: window.location.href,
          goodsPrice: goods.price,
          goodsName: goods.goodsName,
          goodsRecommendSource: this.recommendGoodsSource.goodsId,
          goodsRecommendSourceGoodsName: this.recommendGoodsSource.goodsName,
          recommendSwipeIndex: swipeIndex,
          goodsCategoryId: goods.categoryId
        }
      };
    }
  }
};