import "core-js/modules/es.string.starts-with.js";
import _ from 'lodash';
export default {
  name: 'HomeCapsuleThemed',
  props: {
    navigatePath: {
      type: String,
      default: function _default() {
        return '';
      }
    }
  },
  data: function data() {
    return {};
  },
  methods: {
    onClick: function onClick() {
      if (_.startsWith(this.navigatePath, 'http')) {
        window.location.href = this.navigatePath;
      } else {
        this.$router.push({
          path: this.navigatePath
        });
      }
    }
  }
};