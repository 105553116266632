var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("transition", {
    attrs: {
      name: "fade"
    }
  }, [_vm.visible ? _c("div", {
    directives: [{
      name: "fixed",
      rawName: "v-fixed"
    }],
    staticClass: "mask",
    attrs: {
      id: "payOnDeliveryDialog"
    }
  }, [_c("div", {
    staticClass: "dialog-content flex flex-column"
  }, [_c("div", {
    staticClass: "relative flex justify-center"
  }, [_vm.isPackageClose ? _c("div", {
    on: {
      click: function click($event) {
        _vm.isPackageClose = false;
      }
    }
  }, [_c("img", {
    staticClass: "package animate__animated animate__shakeX animate__repeat-2",
    attrs: {
      width: 300,
      src: _vm.popupImgs[0]
    }
  })]) : _c("div", {
    on: {
      click: function click($event) {
        _vm.visible = false;
      }
    }
  }, [_c("img", {
    attrs: {
      width: 300,
      src: _vm.popupImgs[1]
    }
  })])]), !_vm.isPackageClose ? _c("div", {
    staticClass: "close-btn",
    attrs: {
      id: "close"
    },
    on: {
      click: _vm.closeDialog
    }
  }, [_c("van-icon", {
    attrs: {
      size: "30",
      name: "close"
    }
  })], 1) : _vm._e()])]) : _vm._e()]);
};

var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };