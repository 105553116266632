var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("transition", {
    attrs: {
      name: "fade"
    }
  }, [_vm.visible ? _c("div", {
    staticClass: "mask"
  }, [_c("div", {
    staticClass: "dialog-content flex flex-column",
    style: {
      width: "".concat(_vm.imageWidth, "px"),
      height: "".concat(_vm.imageHeight, "px")
    }
  }, [_c("div", {
    staticClass: "relative",
    style: {
      height: "".concat(_vm.imageHeight, "px")
    }
  }, [_c("img", {
    attrs: {
      width: _vm.imageWidth,
      height: _vm.imageHeight,
      src: require("base/assets/img/centerWineDialogImg.png")
    }
  }), _c("div", {
    staticClass: "to-cod-btn absolute",
    staticStyle: {
      height: "40px"
    },
    on: {
      click: _vm.onClickBtn
    }
  })]), _c("div", {
    staticClass: "close-btn",
    attrs: {
      id: "close"
    },
    on: {
      click: _vm.closeDialog
    }
  }, [_c("van-icon", {
    attrs: {
      size: "30",
      name: "close"
    }
  })], 1)])]) : _vm._e()]);
};

var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };