var render = function render() {
  var _vm$tipFloatConfig, _vm$tipFloatConfig2;

  var _vm = this,
      _c = _vm._self._c;

  return _c("div", [_c("div", {
    ref: "floatBtn",
    staticClass: "float-icon-con",
    class: {
      isSettlement: _vm.isSettlement
    },
    on: {
      click: _vm.onShare
    }
  }, [_c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isShare && !_vm.appState.isInWechat && !_vm.isExpire,
      expression: "isShare && !appState.isInWechat && !isExpire"
    }],
    style: {
      "background-image": "url(".concat((_vm$tipFloatConfig = _vm.tipFloatConfig) === null || _vm$tipFloatConfig === void 0 ? void 0 : _vm$tipFloatConfig.img, ")"),
      "background-size": "cover",
      width: "60px",
      height: "60px"
    }
  }), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isExpire,
      expression: "isExpire"
    }],
    class: _vm.isShake ? ["animate__animated", "animate__tada", "animate__infinite", "expire", "flex"] : "expire",
    style: {
      "background-image": "url(".concat((_vm$tipFloatConfig2 = _vm.tipFloatConfig) === null || _vm$tipFloatConfig2 === void 0 ? void 0 : _vm$tipFloatConfig2.img, ")")
    }
  })]), _c("WechatShareMask", {
    attrs: {
      "is-share": _vm.maskVisible
    },
    on: {
      "update:isShare": function updateIsShare($event) {
        _vm.maskVisible = $event;
      },
      "update:is-share": function updateIsShare($event) {
        _vm.maskVisible = $event;
      }
    }
  })], 1);
};

var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };