var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    ref: "container",
    staticClass: "container",
    class: {
      "fade-in": !_vm.isAnimated
    }
  }, [_c("van-image", {
    attrs: {
      src: "".concat(_vm.imgBaseUrl, "/884420777607168")
    },
    scopedSlots: _vm._u([{
      key: "loading",
      fn: function fn() {
        return [_c("ImageThemed", {
          attrs: {
            width: 100,
            "image-id": "goodsNormalItem.loading"
          }
        })];
      },
      proxy: true
    }])
  }), _c("div", {
    staticClass: "inner-container"
  }, _vm._l(_vm.source.goodsList, function (item, index) {
    return _c("div", {
      directives: [{
        name: "track",
        rawName: "v-track:exposure",
        arg: "exposure"
      }],
      key: index,
      staticClass: "goods-item",
      attrs: {
        "track-params": _vm.getEventInfo(item, index)
      },
      on: {
        click: function click($event) {
          return _vm.goGoodsDetail(item);
        }
      }
    }, [_c("van-image", {
      staticClass: "goods-pic",
      attrs: {
        src: item.imgUrl
      },
      scopedSlots: _vm._u([{
        key: "loading",
        fn: function fn() {
          return [_c("ImageThemed", {
            attrs: {
              width: 100,
              "image-id": "goodsNormalItem.loading"
            }
          })];
        },
        proxy: true
      }], null, true)
    }), _c("div", {
      staticClass: "goods-name text-ellipsis"
    }, [_vm._v(_vm._s(item.goodsName))])], 1);
  }), 0)], 1);
};

var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };