import "vant/es/dialog/style";
import _Dialog from "vant/es/dialog";
import _objectSpread from "/root/workspace/dev-client-a/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.number.constructor.js";
import { trackUserEvent } from 'base/utils/rp';
import { IconFont } from 'components';
import { mapState } from 'vuex';
import xEnum from 'base/utils/enum';
import { sendQuickTrackingEvent, EventType } from 'base/utils/quickTracking';
export default {
  name: 'GoodsTimeLimitItem',
  components: {
    IconFont: IconFont
  },
  props: {
    source: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    index: {
      type: Number,
      default: 0
    },
    type: {
      type: Number,
      default: 0
    },
    onClickItem: {
      type: Function,
      default: function _default() {}
    }
  },
  computed: _objectSpread({}, mapState({
    pId: function pId(state) {
      return state.appState.pId;
    },
    userId: function userId(state) {
      return state.user.userId;
    }
  })),
  methods: {
    onClick: function onClick() {
      var source = this.source,
          index = this.index,
          type = this.type;

      if (source.stockNum <= 0) {
        _Dialog.alert({
          message: '非常遗憾，本商品已兑完，请等待下场',
          confirmButtonText: '好的'
        });

        return;
      }

      this.$router.push({
        path: "/goodsProfile/".concat(source.id),
        query: {
          type: this.type,
          activityType: 'timeLimit'
        }
      });
      this.onClickItem(source, index);
      var eventInfo = {
        pId: this.pId,
        userId: this.userId,
        goodsId: source.id,
        score: source === null || source === void 0 ? void 0 : source.score,
        goodsIndex: index,
        goodsGroupId: this.type,
        webUrl: window.location.href
      };
      trackUserEvent({
        eventType: xEnum.ENUM_RP_EVENT_TYPE.GOODS_TIME_LIMIT_LIST_CLICK.value,
        eventInfo: eventInfo
      });
      sendQuickTrackingEvent({
        eventName: 'ducuecmc_hc_waterfalls_clk',
        eventType: EventType.Click,
        pageName: 'dacuecmc_all_page',
        params: {
          goods_score: source === null || source === void 0 ? void 0 : source.score,
          goods_group_type: type,
          goods_name: source.goodsName,
          goods_id: source.id,
          goods_index: index,
          display_price: source.price,
          display_original_price: source.originalPrice,
          goods_source_waterfall: '限时秒杀',
          goods_category_id: source.categoryId
        }
      });
    },
    getEventInfo: function getEventInfo(goods, index) {
      return {
        eventType: xEnum.ENUM_RP_EVENT_TYPE.GOODS_TIME_LIMIT_LIST_EP.value,
        eventInfo: {
          pId: this.pId,
          userId: this.userId,
          goodsId: goods.id,
          score: goods === null || goods === void 0 ? void 0 : goods.score,
          goodsIndex: index,
          goodsGroupId: this.type,
          webUrl: window.location.href,
          goodsPrice: goods.price,
          goodsName: goods.goodsName,
          goodsCategoryId: goods.categoryId
        }
      };
    }
  }
};