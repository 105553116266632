import { IconFont } from 'components'; // 这个文件的下面这个备注的引入方式 在dev环境正常 在生产环境 会导致 vue render函数报错
// import { sendQuickTrackingEvent, EventType } from 'base/utils/quickTracking'

import utils from 'base/utils';
export default {
  name: 'SearchBar',
  components: {
    IconFont: IconFont
  },
  props: {
    recommendWord: {
      type: String,
      default: ''
    },
    focusSearchBar: {
      type: Boolean,
      default: false
    },
    barStyle: {
      type: Object,
      default: function _default() {}
    }
  },
  data: function data() {
    return {
      keyWords: ''
    };
  },
  computed: {},
  methods: {
    toSearchPage: function toSearchPage() {
      this.$emit('to-search-page');
    },
    searchGoods: function searchGoods() {
      this.$emit('click-search', this.keyWords);
    },
    inputFocus: function inputFocus() {
      this.$emit('focus');
      utils.sendQuickTrackingEvent({
        eventName: 'ducuecmc_sp_search_icon_clk',
        eventType: utils.EventType.Click,
        pageName: 'dacuecmc_search_page',
        params: {}
      });
    },
    inputBlur: function inputBlur() {
      this.$emit('blur');
    },
    setKeyword: function setKeyword(keyword) {
      this.keyWords = keyword;
    }
  }
};