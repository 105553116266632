import _objectSpread from "/root/workspace/dev-client-a/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "waterfall-wrapper",
    style: {
      "column-gap": _vm.gap[1]
    }
  }, _vm._l(_vm.colData, function (col, i) {
    return _c("div", {
      key: i,
      ref: "cols",
      refInFor: true,
      staticClass: "col-wrapper",
      style: {
        "row-gap": _vm.gap[0]
      }
    }, _vm._l(col, function (row, j) {
      return _c("div", {
        key: j,
        staticClass: "row-wrapper flex",
        class: [_vm.animation]
      }, [_vm._t("default", null, {
        source: _objectSpread(_objectSpread({}, row), {}, {
          i: i,
          j: j
        })
      })], 2);
    }), 0);
  }), 0);
};

var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };