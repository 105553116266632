var screenWidth = window.screen.width;
var widthHeightRatio = 5 / 6;
export default {
  name: 'CenterWineDialog',
  props: {},
  data: function data() {
    return {
      visible: true,
      imageWidth: screenWidth * (250 / 320),
      imageHeight: screenWidth * (250 / 320) / widthHeightRatio
    };
  },
  computed: {},
  created: function created() {},
  methods: {
    closeDialog: function closeDialog() {
      this.visible = false;
      window.localStorage.setItem('centerWineDialogIsShowed', true);
    },
    onClickBtn: function onClickBtn() {
      this.closeDialog();
      this.onClick();
    }
  }
};