import "core-js/modules/es.number.constructor.js";
import { IconFont } from 'components';
export default {
  name: 'TabFilter',
  components: {
    IconFont: IconFont
  },
  props: {
    activeTabIndex: {
      type: Number,
      default: 0
    },
    tabs: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    isShowArcIndicator: {
      type: Boolean,
      default: function _default() {
        return false;
      }
    },
    isShowHomeTopTab: {
      type: Boolean,
      default: function _default() {
        return false;
      }
    },
    inActiveStyle: {
      type: Object,
      default: function _default() {}
    },
    styleWrap: {
      type: Object,
      default: function _default() {}
    },
    styleTab: {
      type: Object,
      default: function _default() {}
    },
    // TODO: 以下两个自定义属性不能同时传true，后期需优化
    hasNormalBubbleBg: {
      type: Boolean,
      default: function _default() {
        return false;
      }
    },
    hasRainbowBubbleBg: {
      type: Boolean,
      default: function _default() {
        return false;
      }
    },
    styleActiveIndicator: {
      type: Object,
      default: function _default() {}
    },
    styleTabTitle: {
      type: Object,
      default: function _default() {}
    },
    styleActiveSubtitle: {
      type: Object,
      default: function _default() {}
    },
    styleActiveTabTitle: {
      type: Object,
      default: function _default() {}
    },
    enableDivider: {
      type: Boolean,
      default: function _default() {
        return false;
      }
    },
    enableSlide: {
      type: Boolean,
      default: function _default() {
        return false;
      }
    },
    tabWidth: {
      type: Object,
      default: function _default() {}
    },
    moveLeftDistance: {
      type: Object,
      default: function _default() {}
    }
  },
  data: function data() {
    return {
      activeTab: this.tabs[0],
      lastTabActived: false
    };
  },
  created: function created() {},
  methods: {
    onTabChange: function onTabChange(tab, index) {
      this.activeTab = tab;
      this.$emit('on-tab-change', index);

      if (index === this.tabs.length - 1) {
        this.lastTabActived = true;
      } else {
        this.lastTabActived = false;
      }
    },
    changeTab: function changeTab(index) {
      this.activeTab = this.tabs[index];
    },
    getRainbowBgByIndex: function getRainbowBgByIndex(index) {
      return require("base/assets/img/tabBg/tab_active_".concat(index, ".png"));
    }
  }
};