import { render, staticRenderFns } from "./GoodsListThemed.vue?vue&type=template&id=39b6c926&scoped=true&"
import script from "./GoodsListThemed.vue?vue&type=script&lang=js&"
export * from "./GoodsListThemed.vue?vue&type=script&lang=js&"
import style0 from "./GoodsListThemed.vue?vue&type=style&index=0&id=39b6c926&lang=less&"
import style1 from "./GoodsListThemed.vue?vue&type=style&index=1&id=39b6c926&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39b6c926",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/workspace/dev-client-a/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('39b6c926')) {
      api.createRecord('39b6c926', component.options)
    } else {
      api.reload('39b6c926', component.options)
    }
    module.hot.accept("./GoodsListThemed.vue?vue&type=template&id=39b6c926&scoped=true&", function () {
      api.rerender('39b6c926', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/widgets/GoodsListThemed.vue"
export default component.exports