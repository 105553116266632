import _objectSpread from "/root/workspace/dev-client-a/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.concat.js";
import { trackEvent } from 'base/utils/rp';
import { mapState } from 'vuex';
import utils from 'base/utils';
import mock from 'base/utils/mock';
import { CountDown, NoticeBar } from 'components';
import { sendQuickTrackingEvent, EventType } from 'base/utils/quickTracking';
export default {
  name: 'PointCenterBanner',
  components: {
    CountDown: CountDown,
    NoticeBar: NoticeBar
  },
  data: function data() {
    return {
      countDownTime: dayjs(dayjs().endOf('day')).valueOf(),
      buyInfoList: [],
      pointNotice: '积分即将到期，为避免失效，请尽快使用！'
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState({
    points: function points(state) {
      return state.user.points;
    },
    topPointShowMoney: function topPointShowMoney(state) {
      return state.appState.topPointShowMoney;
    }
  })), {}, {
    topBanner: function topBanner() {
      return this.renderBrandElement({
        id: 'primary.topBanner'
      });
    },
    activityRules: function activityRules() {
      return this.renderBrandElement({
        id: 'global.activityRules'
      });
    }
  }),
  created: function created() {
    var _this = this;

    this.buyInfoList = _.chain(Array(10)).map(function () {
      var familyName = mock.familyNames[_.random(mock.familyNames.length - 1)];

      var second = _.random(1, 9);

      var goodName = mock.goodsName[_.random(mock.goodsName.length - 1)];

      var userIcon = mock.avatars[_.random(mock.avatars.length - 1)];

      return {
        avatars: userIcon,
        desc: "".concat(familyName, "**\u5728").concat(second, "\u5206\u949F\u524D").concat(_this.globalExchangeWord, "\u4E86").concat(goodName)
      };
    }).value();
  },
  methods: {
    showRulePopup: function showRulePopup() {
      trackEvent({
        category: '点击-jf中心-规则栏点击'
      });
      this.trackEventBannerClkHandler('规则');
      this.$emit('rule-click');
    },
    toBuy: function toBuy() {
      utils.scroll('#stickyZone', 150);
      sendQuickTrackingEvent({
        eventName: 'ducuecmc_points_center_redeem_clk',
        eventType: EventType.Click,
        pageName: 'dacuecmc_points_center_page',
        params: {
          place_name: '积分页-立即兑换按钮'
        }
      });
    },
    clickBanner: function clickBanner() {
      utils.scroll('#stickyZone', 150); // 积分页资源位-顶部banner

      var topBanner = this.topBanner;
      sendQuickTrackingEvent({
        eventName: 'ducuecmc_points_center_place_clk',
        eventType: EventType.Click,
        pageName: 'dacuecmc_points_center_page',
        params: {
          place_type: '无',
          place_name: '积分页顶部banner',
          place_addr: 1,
          place_detail: topBanner
        }
      });
    },
    trackEventBannerClkHandler: function trackEventBannerClkHandler(str) {
      sendQuickTrackingEvent({
        eventName: 'ducuecmc_points_center_banner_clk',
        eventType: EventType.Click,
        pageName: 'dacuecmc_points_center_page',
        params: {
          place_addr: str
        }
      });
    }
  }
};