import _defineProperty from "/root/workspace/dev-client-a/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "vant/es/notice-bar/style";
import _NoticeBar from "vant/es/notice-bar";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.number.constructor.js";
export default {
  name: 'NoticeBar',
  components: _defineProperty({}, _NoticeBar.name, _NoticeBar),
  props: {
    enableVerticalScroll: {
      type: Boolean,
      default: function _default() {
        return false;
      }
    },
    speed: {
      type: Number,
      default: function _default() {
        return 60;
      }
    },
    scrollable: {
      type: Boolean,
      default: function _default() {
        return false;
      }
    },
    leftIcon: {
      type: String,
      default: function _default() {
        return 'volume-o';
      }
    },
    noticeList: {
      type: [String, Array],
      default: function _default() {
        return '' || [];
      }
    },
    noticeBarStyle: {
      type: Object,
      default: function _default() {}
    }
  },
  data: function data() {
    return {};
  }
};