var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _vm.visible ? _c("div", {
    staticClass: "coupon-entry animate__animated animate__tada animate__infinite fixed flex-column items-center right-0 mr3",
    on: {
      click: _vm.goDownloadApp
    }
  }, [_c("div", {
    staticClass: "background-image",
    style: {
      "background-image": "url(".concat(_vm.downloadAppImg, ")")
    }
  }), _c("SuspendShareThemed", {
    ref: "suspendShare",
    attrs: {
      "is-share": false,
      "share-event-name": "分享-双十一超级红包",
      "share-text": _vm.shareText,
      "share-hint": "分享给好友后，即可解锁双十一超级红包！"
    }
  })], 1) : _vm._e();
};

var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };