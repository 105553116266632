import _defineProperty from "/root/workspace/dev-client-a/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "vant/es/action-sheet/style";
import _ActionSheet from "vant/es/action-sheet";
import "vant/es/skeleton/style";
import _Skeleton from "vant/es/skeleton";

var _components;

import "core-js/modules/es.function.name.js";
import "core-js/modules/es.regexp.constructor.js";
import "core-js/modules/es.regexp.dot-all.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.sticky.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.string.match.js";
import xEnum from 'base/utils/enum';
import { sendQuickTrackingEvent, EventType } from 'base/utils/quickTracking';
export default {
  name: 'MorePurchaseCard',
  components: (_components = {}, _defineProperty(_components, _Skeleton.name, _Skeleton), _defineProperty(_components, _ActionSheet.name, _ActionSheet), _components),
  props: {
    goods: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    changeable: {
      type: Boolean,
      default: false
    },
    mainProductInfo: {
      type: Object,
      default: function _default() {}
    },
    pointsName: {
      type: String,
      default: '积分'
    },
    orderId: {
      type: String,
      default: ''
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      selected: false,
      detailsShow: false,
      currentIndex: 0,
      goodsEpArr: []
    };
  },
  computed: {
    isCostPoints: function isCostPoints() {
      var _this$goods$this$curr;

      // TODO
      return ((_this$goods$this$curr = this.goods[this.currentIndex]) === null || _this$goods$this$curr === void 0 ? void 0 : _this$goods$this$curr.costPoints) > 0;
    },
    goodsSpec: function goodsSpec() {
      return this.goods[this.currentIndex];
    }
  },
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    lazyLoader: function lazyLoader(dom) {
      var regExp = new RegExp(/(<img )([^>]*)(src=")([^"]*")([^>]*)(>)/, 'g');
      dom = dom.replace(regExp, function (match, p1, p2, p3, p4, p5, p6) {
        return p1 + p2 + 'data-src="' + p4 + p5 + p6;
      });
      return dom;
    },
    openDetails: function openDetails() {
      var _mainProductInfo$good;

      this.detailsShow = true;
      var mainProductInfo = this.mainProductInfo,
          subProductInfor = this.goodsSpec;
      sendQuickTrackingEvent({
        eventName: 'ducuecmc_orderconfirm_ssmgoods_clk',
        eventType: EventType.Click,
        pageName: 'ducuecmc_orderconfirm_page',
        params: {
          goods_category_id: subProductInfor === null || subProductInfor === void 0 ? void 0 : subProductInfor.categoryId,
          ssmsource_goods_id: mainProductInfo.goodsId,
          ssmsource_goods_name: mainProductInfo.goodsName,
          ssmsource_goods_category_id: (_mainProductInfo$good = mainProductInfo.goodsVo) === null || _mainProductInfo$good === void 0 ? void 0 : _mainProductInfo$good.categoryId,
          ssmsource_goods_group_type: mainProductInfo.goodGroupType,
          ssmsource_display_price: mainProductInfo.price,
          ssmsource_display_original_price: mainProductInfo.originalPrice,
          ssmsource_sku_id: mainProductInfo.goodsSpecId,
          goods_name: subProductInfor.goodsName,
          goods_group_type: -1,
          order_id: this.orderId,
          goods_id: subProductInfor.goodsId,
          display_price: subProductInfor.salePrice,
          display_original_price: subProductInfor.originalPrice,
          sku_id: subProductInfor.id,
          sku_name: subProductInfor.goodsSpecName
        }
      });
    },
    selectChanged: function selectChanged(value) {
      if (value) {
        this.$emit('selected', this.goodsSpec);
      } else {
        this.$emit('selected', null);
      }
    },
    clearSelected: function clearSelected() {
      this.selected = null;
      this.$emit('selected', null);
    },
    changeCurrentIndex: function changeCurrentIndex() {
      if (this.currentIndex === this.goods.length - 1) {
        this.currentIndex = 0;
      } else {
        this.currentIndex++;
      }

      this.selected = false;
      this.$emit('selected', null);
    },
    extractImgAttributes: function extractImgAttributes(string) {
      var pattern = /<img\s+style="([^"]*)"\s+src="([^"]*)"\s*>/g;
      var matches = string.match(pattern);
      var imgObjects = [];

      if (matches) {
        for (var i = 0; i < matches.length; i++) {
          var match = matches[i];
          var style = match.match(/style="([^"]*)"/)[1];
          var src = match.match(/src="([^"]*)"/)[1];
          var imgObject = {
            src: src,
            style: style
          };
          imgObjects.push(imgObject);
        }
      }

      return imgObjects;
    },
    getEventInfo: function getEventInfo() {
      var _this$mainProductInfo, _this$mainProductInfo2, _this$mainProductInfo3, _this$mainProductInfo4, _this$mainProductInfo5, _this$mainProductInfo6, _this$mainProductInfo7, _this$mainProductInfo8, _this$goodsSpec, _this$goodsSpec2, _this$goodsSpec3, _this$goodsSpec4, _this$goodsSpec5, _this$goodsSpec6, _this$goodsSpec7;

      return {
        eventType: xEnum.ENUM_RP_EVENT_TYPE.CONVENIENT_BUY_GOODS_EP.value,
        eventInfo: {
          ssmsource_goods_id: (_this$mainProductInfo = this.mainProductInfo) === null || _this$mainProductInfo === void 0 ? void 0 : _this$mainProductInfo.goodsId,
          ssmsource_goods_name: (_this$mainProductInfo2 = this.mainProductInfo) === null || _this$mainProductInfo2 === void 0 ? void 0 : _this$mainProductInfo2.goodsName,
          ssmsource_goods_category_id: (_this$mainProductInfo3 = this.mainProductInfo) === null || _this$mainProductInfo3 === void 0 ? void 0 : (_this$mainProductInfo4 = _this$mainProductInfo3.goodsVo) === null || _this$mainProductInfo4 === void 0 ? void 0 : _this$mainProductInfo4.categoryId,
          ssmsource_goods_group_type: (_this$mainProductInfo5 = this.mainProductInfo) === null || _this$mainProductInfo5 === void 0 ? void 0 : _this$mainProductInfo5.goodGroupType,
          ssmsource_display_price: (_this$mainProductInfo6 = this.mainProductInfo) === null || _this$mainProductInfo6 === void 0 ? void 0 : _this$mainProductInfo6.price,
          ssmsource_display_original_price: (_this$mainProductInfo7 = this.mainProductInfo) === null || _this$mainProductInfo7 === void 0 ? void 0 : _this$mainProductInfo7.originalPrice,
          ssmsource_sku_id: (_this$mainProductInfo8 = this.mainProductInfo) === null || _this$mainProductInfo8 === void 0 ? void 0 : _this$mainProductInfo8.goodsSpecId,
          goodsName: (_this$goodsSpec = this.goodsSpec) === null || _this$goodsSpec === void 0 ? void 0 : _this$goodsSpec.goodsName,
          goodsGroupId: -1,
          orderId: this.orderId,
          goodsCategoryId: (_this$goodsSpec2 = this.goodsSpec) === null || _this$goodsSpec2 === void 0 ? void 0 : _this$goodsSpec2.categoryId,
          goodsId: (_this$goodsSpec3 = this.goodsSpec) === null || _this$goodsSpec3 === void 0 ? void 0 : _this$goodsSpec3.goodsId,
          goodsPrice: (_this$goodsSpec4 = this.goodsSpec) === null || _this$goodsSpec4 === void 0 ? void 0 : _this$goodsSpec4.salePrice,
          display_original_price: (_this$goodsSpec5 = this.goodsSpec) === null || _this$goodsSpec5 === void 0 ? void 0 : _this$goodsSpec5.originalPrice,
          goodsSpecId: (_this$goodsSpec6 = this.goodsSpec) === null || _this$goodsSpec6 === void 0 ? void 0 : _this$goodsSpec6.id,
          skuName: (_this$goodsSpec7 = this.goodsSpec) === null || _this$goodsSpec7 === void 0 ? void 0 : _this$goodsSpec7.goodsSpecName
        }
      };
    }
  }
};