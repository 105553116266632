import _objectSpread from "/root/workspace/dev-client-a/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import _defineProperty from "/root/workspace/dev-client-a/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "vant/es/notice-bar/style";
import _NoticeBar from "vant/es/notice-bar";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.number.constructor.js";
import { mapState } from 'vuex';
export default {
  name: 'PointNoticeMaskThemed',
  components: _defineProperty({}, _NoticeBar.name, _NoticeBar),
  props: {
    /**
     * 滚动到某个位置隐藏该组件，当为0时则不隐藏
     */
    hiddenOffset: {
      type: Number,
      default: 0
    },
    jinriBackground: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      hidden: false,
      enable: this.renderBrandElement({
        id: 'pointNoticeMask.enable',
        type: 'boolean'
      }),
      expireTime: ''
    };
  },
  computed: _objectSpread({}, mapState({
    points: function points(state) {
      return state.user.points;
    },
    isValidPoint: function isValidPoint(state) {
      if (!state.user.points || !state.user.points.expireTime) {
        return false;
      }

      if (state.user.points.expirePoints <= 0) {
        return false;
      }

      if (dayjs().isAfter(state.user.points.expireTime)) {
        return false;
      }

      return true;
    }
  })),
  created: function created() {
    var now = dayjs();
    var countDownTime = dayjs(dayjs().endOf('day')).diff(now, 'seconds') * 1000;
    this.countDownTime = countDownTime;
  },
  mounted: function mounted() {
    window.addEventListener('scroll', this.handleScroll, true);
  },
  destroyed: function destroyed() {
    window.removeEventListener('scroll', this.handleScroll, true);
  },
  methods: {
    handleScroll: function handleScroll(e) {
      if (this.hiddenOffset === 0) {
        return;
      }

      var scrollTop = e.target.documentElement && e.target.documentElement.scrollTop || e.target.body && e.target.body.scrollTop; // console.log(scrollTop)

      if (scrollTop > 200) {
        this.hidden = true;
      } else {
        this.hidden = false;
      }
    }
  }
};