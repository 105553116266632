import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.set.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
var buryState = {
  state: {
    exposureDomSet: new Set(),
    exposureLimitForMorePurchaseCard: [] //处理顺手买商品重复曝光

  },
  mutations: {
    SET_EXPOSURE_DOM_SET: function SET_EXPOSURE_DOM_SET(state, dom) {
      if (state.exposureDomSet.has(dom)) return;
      state.exposureDomSet.add(dom);
    },
    CLEAR_EXPOSURE_DOM_SET: function CLEAR_EXPOSURE_DOM_SET(state) {
      state.exposureDomSet.clear();
      state.exposureLimitForMorePurchaseCard = [];
    },
    SET_MORE_PURCHASE_ITEM: function SET_MORE_PURCHASE_ITEM(state, item) {
      var hasItem = state.exposureLimitForMorePurchaseCard.includes(item);
      if (hasItem) return;
      state.exposureLimitForMorePurchaseCard.push(item);
    }
  },
  actions: {}
};
export default buryState;