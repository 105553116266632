import "core-js/modules/es.array.concat.js";

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "contact-card-container flex justify-between items-center bg-white rounded-large px2",
    class: _vm.editable && !_vm.addressInvalid && "readonly"
  }, [_vm.editable && _vm.addressInvalid ? _c("div", {
    staticClass: "py1"
  }, [_c("van-form", {
    on: {
      submit: _vm.onSubmit
    }
  }, [_c("van-field", {
    staticClass: "field",
    attrs: {
      error: false,
      required: "",
      label: "收货人姓名",
      placeholder: "收货人姓名",
      rules: [{
        required: true,
        message: "请输入收货人姓名"
      }]
    },
    model: {
      value: _vm.form.receiverName,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "receiverName", $$v);
      },
      expression: "form.receiverName"
    }
  }), _c("van-field", {
    staticClass: "field",
    attrs: {
      required: "",
      type: "tel",
      label: "收货人手机号码",
      placeholder: "收货人手机号码",
      rules: [{
        required: true,
        message: "请输入收货人手机号码"
      }, {
        pattern: /^1[3-9][0-9]\d{8}$/,
        message: "手机号码格式错误"
      }]
    },
    model: {
      value: _vm.form.phone,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "phone", $$v);
      },
      expression: "form.phone"
    }
  }), _c("van-field", {
    staticClass: "field",
    attrs: {
      readonly: "",
      clickable: "",
      required: "",
      autosize: "",
      type: "textarea",
      rows: "1",
      label: "省市区县",
      placeholder: "点击选择省市区县街道",
      rules: [{
        required: true,
        message: "请选择省市区县街道"
      }]
    },
    on: {
      click: function click($event) {
        _vm.areaPopup = true;
      }
    },
    model: {
      value: _vm.form.address,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "address", $$v);
      },
      expression: "form.address"
    }
  }), _c("van-field", {
    staticClass: "field",
    attrs: {
      required: "",
      label: "详细地址",
      placeholder: "小区楼栋/乡村名称",
      autosize: "",
      type: "textarea",
      rows: "1",
      rules: [{
        required: true,
        message: "请输入小区楼栋/乡村名称"
      }]
    },
    model: {
      value: _vm.form.addressDetail,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "addressDetail", $$v);
      },
      expression: "form.addressDetail"
    }
  })], 1)], 1) : _vm._e(), !_vm.addressInvalid ? _c("div", {
    staticClass: "flex-auto py1_5"
  }, [_c("div", {
    staticClass: "flex-auto"
  }, [_c("div", {
    staticClass: "flex items-end"
  }, [_c("div", {
    staticClass: "fs18 bold color_title"
  }, [_vm._v(_vm._s(_vm.receiverName))]), _c("div", {
    staticClass: "ml1 fs18 bold color_title"
  }, [_vm._v(_vm._s(_vm.phone))])]), _c("div", {
    staticClass: "mt1 fs16 text-ellipsis color_title"
  }, [_vm._v(" " + _vm._s("".concat(_vm.addressProvince).concat(_vm.addressCity).concat(_vm.addressArea).concat(_vm.addressDetail)) + " ")])])]) : _vm._e(), _vm.editable && !_vm.addressInvalid ? _c("div", {
    staticClass: "flex-none",
    on: {
      click: _vm.onEdit
    }
  }, [_c("IconFont", {
    attrs: {
      size: "24px",
      icon: "iconbianji",
      color: "#999999"
    }
  })], 1) : _vm._e(), _c("van-overlay", {
    attrs: {
      show: _vm.showEditForm
    },
    on: {
      click: function click($event) {
        _vm.showEditForm = false;
      }
    }
  }, [_c("div", {
    staticClass: "addressDiv",
    on: {
      click: function click($event) {
        $event.stopPropagation();
      }
    }
  }, [_c("div", {
    staticClass: "header pt2 px2"
  }, [_c("div", {
    staticClass: "addressClose",
    on: {
      click: function click($event) {
        _vm.showEditForm = false;
      }
    }
  }, [_c("IconFont", {
    attrs: {
      size: "24px",
      icon: "iconguanbi1",
      color: "#222222"
    },
    nativeOn: {
      click: function click($event) {
        _vm.popupVisible = false;
      }
    }
  })], 1), _c("div", {
    staticClass: "my2 flex items-center justify-center"
  }, [_c("div", {
    staticClass: "title fs17 color_black_222"
  }, [_vm._v("修改收货地址")])]), _vm.isInWechat ? _c("div", {
    staticClass: "flex justify-between items-center",
    on: {
      click: _vm.importWxAddress
    }
  }, [_c("div", {
    staticClass: "flex items-center"
  }, [_c("van-image", {
    attrs: {
      width: "24",
      height: "24",
      src: "".concat(_vm.imgBaseUrl, "/890935459708928")
    }
  }), _c("div", {
    staticClass: "ml1 title fs14 color_black_333"
  }, [_vm._v("导入微信地址")])], 1), _c("IconFont", {
    attrs: {
      size: "12px",
      icon: "iconpageleft",
      color: "#333333"
    }
  })], 1) : _vm._e()]), _c("van-form", {
    staticClass: "px1",
    on: {
      submit: _vm.onSubmit
    }
  }, [_c("van-field", {
    staticClass: "field",
    attrs: {
      required: "",
      label: "收货人姓名",
      placeholder: "收货人姓名",
      rules: [{
        required: true,
        message: "请输入收货人姓名"
      }]
    },
    model: {
      value: _vm.form.receiverName,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "receiverName", $$v);
      },
      expression: "form.receiverName"
    }
  }), _c("van-field", {
    staticClass: "field",
    attrs: {
      required: "",
      type: "tel",
      label: "手机号码",
      placeholder: "收货人手机号码",
      rules: [{
        required: true,
        message: "请输入收货人手机号码"
      }, {
        pattern: /^1[3-9][0-9]\d{8}$/,
        message: "手机号码格式错误"
      }]
    },
    model: {
      value: _vm.form.phone,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "phone", $$v);
      },
      expression: "form.phone"
    }
  }), _c("van-field", {
    staticClass: "field",
    class: {
      "express-fee-not-validated": _vm.isExpressFeeNotEqual
    },
    attrs: {
      readonly: "",
      clickable: "",
      required: "",
      label: "省市区县",
      placeholder: "点击选择省市区县街道",
      autosize: "",
      type: "textarea",
      rows: "1",
      rules: [{
        required: true,
        message: "请选择省市区县街道"
      }]
    },
    on: {
      click: function click($event) {
        _vm.areaPopup = true;
      }
    },
    model: {
      value: _vm.form.address,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "address", $$v);
      },
      expression: "form.address"
    }
  }), _c("van-field", {
    staticClass: "field",
    attrs: {
      required: "",
      label: "详细地址",
      placeholder: "详细地址",
      autosize: "",
      type: "textarea",
      rows: "1",
      rules: [{
        required: true,
        message: "请输入详细地址"
      }]
    },
    model: {
      value: _vm.form.addressDetail,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "addressDetail", $$v);
      },
      expression: "form.addressDetail"
    }
  }), _c("div", {
    staticClass: "mt3"
  }), _c("van-button", {
    staticClass: "btn bg_primary",
    attrs: {
      round: "",
      block: "",
      type: "danger",
      size: "large",
      "native-type": "submit",
      loading: _vm.isUpdateAddressLoading,
      "loading-text": "修改地址中..."
    }
  }, [_vm._v(" 保存 ")])], 1)], 1)]), _c("van-popup", {
    attrs: {
      position: "bottom"
    },
    model: {
      value: _vm.areaPopup,
      callback: function callback($$v) {
        _vm.areaPopup = $$v;
      },
      expression: "areaPopup"
    }
  }, [_c("van-picker", {
    attrs: {
      "show-toolbar": "",
      columns: _vm.addressList
    },
    on: {
      cancel: function cancel($event) {
        _vm.areaPopup = false;
      },
      confirm: _vm.areaConfirmFn
    }
  })], 1)], 1);
};

var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };