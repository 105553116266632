var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "base64-avatar-container",
    style: {
      width: _vm.width + "px",
      height: _vm.height + "px"
    }
  }, [_vm.src && _vm.src !== "" ? _c("van-image", {
    style: _vm.imgStyle,
    attrs: {
      width: _vm.width,
      height: _vm.height,
      round: _vm.round,
      src: "data:image/jpeg;base64, ".concat(_vm.src)
    }
  }) : _c("img", {
    class: {
      round: _vm.round
    },
    style: _vm.imgStyle,
    attrs: {
      width: _vm.width,
      height: _vm.height,
      src: require("base/assets/img/my/userPhoto.png")
    }
  })], 1);
};

var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };