var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "activity-footbar-container"
  }, [_c("van-tabbar", {
    attrs: {
      "safe-area-inset-bottom": true,
      "active-color": _vm.activeColor,
      "inactive-color": _vm.inactiveColor
    },
    on: {
      change: _vm.onTabChange
    },
    model: {
      value: _vm.footBarActive,
      callback: function callback($$v) {
        _vm.footBarActive = $$v;
      },
      expression: "footBarActive"
    }
  }, _vm._l(_vm.tabbarList, function (item, index) {
    return _c("van-tabbar-item", {
      key: index,
      attrs: {
        to: {
          path: item.path,
          query: _vm.$route.query
        },
        name: item.path
      },
      on: {
        click: function click($event) {
          return _vm.onTabClick(item, index);
        }
      },
      scopedSlots: _vm._u([{
        key: "icon",
        fn: function fn() {
          return [_c("IconFont", {
            attrs: {
              size: "27px",
              icon: item.icon
            }
          })];
        },
        proxy: true
      }], null, true)
    }, [_c("span", [_vm._v(_vm._s(item.text))])]);
  }), 1)], 1);
};

var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };