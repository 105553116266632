var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("transition", {
    attrs: {
      name: "fade"
    }
  }, [_vm.visible ? _c("div", {
    staticClass: "mask",
    on: {
      click: _vm.onClickBtn,
      touchmove: function touchmove($event) {
        $event.preventDefault();
      }
    }
  }, [_c("div", {
    staticClass: "dialog-content"
  }, [_vm.isPresentAgain ? _c("div", {
    staticClass: "text bold absolute"
  }, [_vm._v(" 再次获得 "), _c("span", [_vm._v(_vm._s(_vm.morePoints))]), _vm._v(" 分 ")]) : _vm._e(), !_vm.isPresentAgain && !_vm.pointTemplateImg ? _c("div", {
    staticClass: "text bold absolute"
  }, [_vm._v(" 获得 "), _c("span", [_vm._v(_vm._s(_vm.activePoints))]), _vm._v(" 分 ")]) : _vm._e(), _c("img", {
    staticClass: "image",
    attrs: {
      src: _vm.dislogOptions.bgImage
    }
  }), _c("div", {
    staticClass: "close-btn",
    attrs: {
      id: "close"
    },
    on: {
      click: _vm.closeDialog
    }
  }, [_c("van-icon", {
    attrs: {
      size: "30",
      name: "close"
    }
  })], 1)])]) : _vm._e()]);
};

var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };