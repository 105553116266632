import "core-js/modules/es.number.constructor.js";
export default {
  name: 'ImageThemed',
  props: {
    src: {
      type: String,
      default: function _default() {
        return null;
      }
    },
    width: {
      type: [Number, String],
      default: function _default() {
        return '100%';
      }
    },
    height: {
      type: [Number, String],
      default: function _default() {
        return '100%';
      }
    },
    imageClassName: {
      type: String,
      default: function _default() {
        return '';
      }
    },
    imageId: {
      type: String,
      default: function _default() {
        return null;
      }
    }
  },
  data: function data() {
    return {};
  },
  computed: {
    imageSrc: function imageSrc() {
      if (this.imageId) {
        return this.renderBrandElement({
          id: this.imageId,
          type: 'image'
        });
      } else {
        return this.src;
      }
    }
  },
  methods: {}
};