var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "progress-container"
  }, [_c("div", {
    staticClass: "progress-item",
    style: {
      width: _vm.percentage + "%"
    }
  })]);
};

var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };