import "core-js/modules/es.json.stringify.js";

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    directives: [{
      name: "track",
      rawName: "v-track:exposure",
      arg: "exposure"
    }],
    staticClass: "com-container flex items-center justify-between mt1",
    attrs: {
      "track-params": JSON.stringify(_vm.getEventInfo("首页金刚区位置"))
    }
  }, _vm._l(_vm.kingKongData, function (item, index) {
    var _item$businessKey;

    return _c("div", {
      key: index,
      staticClass: "flex flex-column items-center",
      on: {
        click: function click($event) {
          return _vm.kingKongClick(item, index);
        }
      }
    }, [_c("img", {
      staticClass: "entry-icon",
      attrs: {
        src: item.img
      }
    }), _c("div", [_vm._v(_vm._s((_item$businessKey = item.businessKey) === null || _item$businessKey === void 0 ? void 0 : _item$businessKey.title))])]);
  }), 0);
};

var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };