import "vant/es/notify/style";
import _Notify from "vant/es/notify";
import "core-js/modules/es.object.to-string.js";
import axios from 'axios';
import utils from 'base/utils';
import Cookies from 'js-cookie';
import retryRequest from 'base/utils/retryRequest';
axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'; // 创建axios实例

var STAGING_BASE_API = Cookies.get('STAGING_BASE_API');
var baseUrl = null;

if (process.env === 'staging' && STAGING_BASE_API && STAGING_BASE_API !== 'null') {
  baseUrl = STAGING_BASE_API;
} else {
  baseUrl = process.env.VUE_APP_BASE_API;
}

var service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: baseUrl,
  // 超时
  timeout: 30000
}); // request拦截器

service.interceptors.request.use(function (config) {
  // if (getToken()) {
  //   config.headers['Authorization'] = 'Bearer ' + getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
  // }
  var signature;

  var method = _.lowerCase(config.method);

  if (method === 'get' || method === 'delete') {
    var params = config.params || {};
    params.nonce = utils.randomString(8), params.timestamp = new Date().getTime(); // 这两种参数剔除。即不给后端传，也不参与签名。因为在 url 中，会变为字符串，行为不可控。

    params = _.omitBy(params, function (param) {
      return param === null || param === 'null' || param === undefined || param === 'undefined';
    });
    config.params = params;
    signature = utils.genSignature(params);
  } else {
    var body = config.data;
    var nonce = utils.randomString(8);
    var timestamp = new Date().getTime();

    if (body instanceof FormData) {
      body.append('nonce', nonce);
      body.append('timestamp', timestamp);
      config.data = body;
      signature = utils.genSignature({
        nonce: nonce,
        timestamp: timestamp
      });
    } else {
      // 根不能为数组，否则生成签名可能出错。
      // _.isObject([])竟然为 true
      var _params = _.isObject(body) && !_.isArray(body) ? body : {};

      _params.nonce = nonce;
      _params.timestamp = timestamp;
      config.data = _params;
      signature = utils.genSignature(_params);
    }
  }

  config.headers['Accept-Locale'] = signature;
  return config;
}, function (error) {
  console.log(error);
  Promise.reject(error);
}); // 响应拦截器

service.interceptors.response.use(function (res) {
  var _res$data = res.data,
      code = _res$data.code,
      message = _res$data.message; // code=5101 appId和openId不匹配

  if (code !== 200 && code !== 5101) {
    // console.log(res)
    message && _Notify({
      message: message,
      type: 'warning',
      duration: 3 * 1000
    }); // return Promise.reject('error')
  } // TODO: 后端的 code 不应该是 200


  if (message === '客观别急,请稍后再试') {
    _Notify({
      message: message,
      type: 'warning',
      duration: 3 * 1000
    });
  }

  return res.data;
}, function (error) {
  console.log('err' + error);

  if ((error.message === 'Request aborted' || error.message === 'Network Error') && error.config.url.indexOf('/api/queryRealOrder') > -1) {
    return;
  }

  var config = error.config;

  if (process.env.VUE_APP_RETRY_BASE_URL && config) {
    var retryBaseUrl = process.env.VUE_APP_RETRY_BASE_URL;
    config.baseURL = retryBaseUrl;

    if (config.data) {
      //Content-Type 是 application/json，Axios 会自动将 JavaScript 对象格式的 data 序列化成 JSON 字符
      config.data = JSON.parse(config.data);
    }

    return new Promise(function (resolve) {
      resolve(retryRequest(config));
    });
  } else {
    _Notify({
      message: error.message,
      type: 'danger',
      duration: 5 * 1000
    });

    return Promise.reject(error);
  }
});
export default service;