var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "select-payment-container relative"
  }, [_c("van-radio-group", {
    model: {
      value: _vm.payment,
      callback: function callback($$v) {
        _vm.payment = $$v;
      },
      expression: "payment"
    }
  }, [_vm._l(_vm.paymentList, function (payment, index) {
    return _c("div", {
      key: index,
      staticClass: "pay",
      on: {
        click: function click($event) {
          return _vm.onChange(payment.value);
        }
      }
    }, [_c("div", {
      staticClass: "payBox"
    }, [_c("img", {
      staticClass: "payImg",
      attrs: {
        src: payment.icon
      }
    }), _c("ul", {
      staticClass: "payTxt"
    }, [_c("li", {
      staticClass: "payt1 flex items-center"
    }, [_vm._v(" " + _vm._s(payment.label) + " "), index === 0 ? _c("span", {
      staticClass: "fs12",
      staticStyle: {
        color: "#e54635"
      }
    }, [_vm._v("(推荐)")]) : _vm._e(), _c("div", [_c("span", {
      staticClass: "color_tips fs12"
    }, [_vm._v(_vm._s(payment.tip))])])])]), _c("ul", {
      staticClass: "paytj"
    }, [_c("van-radio", {
      staticStyle: {
        float: "right"
      },
      attrs: {
        name: payment.value,
        "checked-color": "#e00"
      }
    })], 1)])]);
  }), _vm.appState.isInAliPay || _vm.appState.isInWechat ? _c("div", {
    staticClass: "p2 fs12 flex items-center justify-center color_tips"
  }, [_vm.appState.isInAliPay ? _c("span", [_vm._v("微信")]) : _vm._e(), _vm.appState.isInWechat ? _c("span", [_vm._v("支付宝")]) : _vm._e(), _c("span", [_vm._v("支付，请复制链接后在浏览器打开")])]) : _vm._e()], 2)], 1);
};

var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };