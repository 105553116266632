var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("transition", {
    attrs: {
      name: "fade"
    }
  }, [_vm.visible ? _c("div", {
    staticClass: "notify-con p2",
    on: {
      click: _vm.clickItem
    }
  }, [_c("div", {
    staticClass: "header flex items-center justify-between"
  }, [_c("div", {
    staticClass: "title flex items-center"
  }, [_c("img", {
    staticClass: "logo",
    attrs: {
      src: _vm.logo,
      alt: ""
    }
  }), _c("div", {
    staticClass: "fs14 ml1",
    staticStyle: {
      color: "#666666"
    }
  }, [_vm._v("消息通知")])]), _c("div", {
    staticClass: "fs13"
  }, [_vm._v("现在")])]), _c("div", {
    staticClass: "fs15 mt2 content flex justify-between"
  }, [_c("div", {
    staticClass: "desc flex items-center"
  }, [_c("div", [_vm._v(_vm._s(_vm.returnDialogOption.content))])]), _c("div", {
    staticClass: "btn mt1 flex items-center justify-center white bg_primary"
  }, [_vm._v("立即领取")])])]) : _vm._e()]);
};

var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };