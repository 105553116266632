import "core-js/modules/es.number.constructor.js";
import utils from 'base/utils';
import { IconFont } from 'components';
import { trackEvent, trackUserEvent } from 'base/utils/rp';
import xEnum from 'base/utils/enum';
export default {
  name: 'BackTop',
  components: {
    IconFont: IconFont
  },
  props: {
    visibleOffsetTop: {
      type: Number,
      default: function _default() {
        return 1000;
      }
    },
    animationTime: {
      type: Number,
      default: function _default() {
        return 200;
      }
    },
    targetDomName: {
      type: String,
      default: function _default() {
        return '#app';
      }
    },
    currentPageName: {
      type: String,
      default: '默认'
    },
    goods: {
      type: Object,
      default: function _default() {}
    }
  },
  data: function data() {
    return {
      isVisible: false
    };
  },
  mounted: function mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed: function destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    goTop: function goTop() {
      var _this$goods;

      utils.scroll(this.targetDomName, this.animationTime);
      trackEvent({
        category: "\u70B9\u51FB-\u8FD4\u56DE\u9876\u90E8-".concat(this.currentPageName),
        action: '总数'
      });
      trackUserEvent({
        eventType: xEnum.ENUM_RP_EVENT_TYPE.GOODS_DETAIL_BACK_TO_TOP.value,
        eventInfo: {
          pId: this.$store.state.appState.pId,
          userId: this.$store.state.user.userId,
          goodsId: (_this$goods = this.goods) === null || _this$goods === void 0 ? void 0 : _this$goods.goodsId,
          goodsGroupId: this.$route.query.type,
          webUrl: window.location.href
        }
      });
    },
    handleScroll: function handleScroll() {
      var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      this.isVisible = scrollTop >= this.visibleOffsetTop;
    }
  }
};