var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("transition", {
    attrs: {
      name: "fade"
    }
  }, [_vm.visible ? _c("div", {
    staticClass: "mask"
  }, [_c("div", {
    staticClass: "dialog-content"
  }, [_c("span", {
    staticClass: "close-dialog",
    on: {
      click: _vm.closeDialog
    }
  }, [_c("IconFont", {
    attrs: {
      size: "15px",
      icon: "iconqingchu",
      color: "#cdcdcd"
    }
  })], 1), !_vm.hidePoint ? _c("div", {
    staticClass: "title flex items-center center"
  }, [_c("div", {
    staticClass: "points-icon mr1 flex"
  }, [_c("IconFont", {
    attrs: {
      size: "40px",
      icon: "iconjifen1",
      color: "#efb336"
    }
  })], 1), _c("div", {
    staticClass: "title-con"
  }, [_vm.shareHint ? _c("div", {
    staticClass: "txt"
  }, [_vm._v(_vm._s(_vm.shareHint))]) : _c("div", [_c("div", {
    staticClass: "txt"
  }, [_vm._v("分享给好友，送你500" + _vm._s(_vm.globalPointName))])])])]) : _vm._e(), _vm.shareImage ? _c("div", {
    staticClass: "center"
  }, [_c("img", {
    attrs: {
      width: "200",
      src: _vm.shareImage
    }
  })]) : _vm._e(), _c("div", {
    staticClass: "copy-desc mt2"
  }, [_c("div", {}, [_vm._v("分享口令已复制")]), _c("div", {
    staticClass: "mt1"
  }, [_vm._v(_vm._s(_vm.shareCopyDesc))])]), _c("div", {
    staticClass: "share-btn",
    on: {
      click: _vm.clickCopyLink
    }
  }, [_c("IconFont", {
    attrs: {
      size: "20px",
      icon: "iconweixin",
      color: "#fff"
    }
  }), _c("span", {
    staticClass: "btn-desc ml1"
  }, [_vm._v(_vm._s(_vm.shareBtnDesc))])], 1)])]) : _vm._e()]);
};

var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };