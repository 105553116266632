import _typeof from "/root/workspace/dev-client-a/node_modules/@babel/runtime/helpers/esm/typeof.js";
import _createForOfIteratorHelper from "/root/workspace/dev-client-a/node_modules/@babel/runtime/helpers/esm/createForOfIteratorHelper.js";
import _slicedToArray from "/root/workspace/dev-client-a/node_modules/@babel/runtime/helpers/esm/slicedToArray.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.url.js";
import "core-js/modules/web.url-search-params.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.regexp.constructor.js";
import "core-js/modules/es.regexp.dot-all.js";
import "core-js/modules/es.regexp.sticky.js";
import "core-js/modules/es.string.search.js";
import "core-js/modules/es.string.match.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.regexp.test.js";
import "core-js/modules/es.map.js";
import "core-js/modules/web.dom-collections.for-each.js";
import _ from 'lodash';
import md5 from 'crypto-js/md5';
import brand from 'base/brands';
export function download(data, fileName) {
  if (!data) {
    return;
  }

  var blob = new Blob([data]); // let blob = data

  if ('download' in document.createElement('a')) {
    // 不是IE浏览器
    var url = window.URL.createObjectURL(blob);
    var link = document.createElement('a');
    link.style.display = 'none';
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link); // 下载完成移除元素

    window.URL.revokeObjectURL(url); // 释放掉blob对象
  } else {
    // IE 10+
    window.navigator.msSaveBlob(blob, fileName);
  }
} // https://stackoverflow.com/questions/7951326/how-to-save-a-base64-image-to-users-disk-using-javascript
// 先用 axios 获取到图片的 blob，然后再转成 base64 下载。

export function saveBlobAsFile(blob, fileName) {
  var reader = new FileReader();

  reader.onloadend = function () {
    var base64 = reader.result;
    var link = document.createElement('a');
    document.body.appendChild(link); // for Firefox

    link.setAttribute('href', base64);
    link.setAttribute('download', fileName);
    link.click();
  };

  reader.readAsDataURL(blob);
}
export function compareAppVersion(remoteVersion, localVersion) {
  function toNum(a) {
    a = a ? a.toString() : '0'; //也可以这样写 var c=a.split(/\./);

    var c = a.split('.');
    var numPlace = ['', '0', '00', '000', '0000'],
        r = numPlace.reverse();

    for (var i = 0; i < c.length; i++) {
      var len = c[i].length;
      c[i] = r[len] + c[i];
    }

    var res = c.join('');
    return res;
  }

  var rvNum = toNum(remoteVersion);
  var lvNum = toNum(localVersion);

  if (rvNum > lvNum) {
    return 1;
  } else if (rvNum == lvNum) {
    return 0;
  } else {
    return -1;
  }
}
export function waitFor(ms) {
  return new Promise(function (resolve) {
    setTimeout(function () {
      resolve();
    }, ms);
  });
}
export function getUrlParam(name) {
  var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
  var search = decodeURIComponent(window.location.search);
  var r = search.substr(1).match(reg);
  if (r != null) return r[2];
  return null;
}
export function setImmediateInterval(callback, interval) {
  callback();
  return setInterval(function () {
    return callback();
  }, interval);
}
export function isInWeixn() {
  var ua = navigator.userAgent.toLowerCase();

  if (ua.match(/MicroMessenger/i) == 'micromessenger') {
    return true;
  } else {
    return false;
  }
}
export function noEmoji(str, s) {
  if (!str) {
    return '';
  }

  var reg = /[^\u0020-\u007E\u00A0-\u00BE\u2E80-\uA4CF\uF900-\uFAFF\uFE30-\uFE4F\uFF00-\uFFEF\u0080-\u009F\u2000-\u201f\u2026\u2022\u20ac\r\n]/g;

  if (str.match(reg)) {
    return str.replace(reg, s || '');
  }

  return str;
}
/**
 * 滚动
 * @param {*} o 目标元素
 * @param {*} t 滚动时间
 * @param {*} containerSelector 外部可滚动容器的选择器，默认是 id 为 app 的 div。
 */
// eslint-disable-next-line no-unused-vars

export function scroll(o) {
  var t = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 500;
  var containerSelector = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'body';
  var topOffset = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 0;
  //   let top = document.documentElement.scrollTop || document.body.scrollTop
  //   // 实现滚动效果
  //   const timeTop = setInterval(() => {
  //   document.body.scrollTop = document.documentElement.scrollTop = top -= 50
  //     if (top <= targetOffsetTop) {
  //       clearInterval(timeTop)
  //     }
  //   }, 10)
  // 获取目标的 offsetTop
  // css选择器是从 1 开始计数，我们是从 0 开始，所以要 +1
  var dom = document.querySelector(o);

  if (!dom) {
    return;
  }

  var targetOffsetTop = dom.offsetTop; // 获取当前 offsetTop
  // 华为手机的document.documentElement.scrollTop一直是 0

  var scrollTop = document.documentElement.scrollTop || document.body.scrollTop; // 定义一次跳 50 个像素，数字越大跳得越快，但是会有掉帧得感觉，步子迈大了会扯到蛋

  var time = 1000 / 60;
  var count = t / time; // console.info(targetOffsetTop, scrollTop)

  var STEP = Math.abs(targetOffsetTop - scrollTop) / count; // console.log('init', scrollTop, targetOffsetTop)

  /**
   * 防止页面中有图片导致位置计算错误，重新计算一次
   */

  setTimeout(function () {
    var recheckTargetOffsetTop = dom.offsetTop; // console.log('recheck滚动', targetOffsetTop, recheckTargetOffsetTop)

    if (recheckTargetOffsetTop !== targetOffsetTop) {
      scroll(o, t);
    }
  }, 500); // 判断是往下滑还是往上滑
  // console.info('滚动：', scrollTop, targetOffsetTop)

  if (scrollTop > targetOffsetTop) {
    // 往上滑
    smoothUp();
  } else {
    // 往下滑
    smoothDown();
  } // 定义往下滑函数


  function smoothDown() {
    // 如果当前 scrollTop 小于 targetOffsetTop 说明视口还没滑到指定位置
    // console.log('down', scrollTop, targetOffsetTop)
    if (scrollTop < targetOffsetTop) {
      // 如果和目标相差距离大于等于 STEP 就跳 STEP
      // 否则直接跳到目标点，目标是为了防止跳过了。
      if (targetOffsetTop - scrollTop >= STEP) {
        scrollTop += STEP;
      } else {
        scrollTop = targetOffsetTop;
      }

      document.body.scrollTop = scrollTop + topOffset;
      document.documentElement.scrollTop = scrollTop + topOffset; // 屏幕在绘制下一帧时会回调传给 requestAnimationFrame 的函数
      // 关于 requestAnimationFrame 可以自己查一下，在这种场景下，相比 setInterval 性价比更高

      requestAnimationFrame(smoothDown);
    }
  } // 定义往上滑函数


  function smoothUp() {
    // console.log('up',scrollTop, targetOffsetTop)
    if (scrollTop > targetOffsetTop) {
      if (scrollTop - targetOffsetTop >= STEP) {
        scrollTop -= STEP;
      } else {
        scrollTop = targetOffsetTop;
      }

      document.body.scrollTop = scrollTop;
      document.documentElement.scrollTop = scrollTop;
      requestAnimationFrame(smoothUp);
    }
  }
}

function compressImage(_ref) {
  var content = _ref.content,
      file = _ref.file;
  var recvDataHandler = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : function () {};
  var maxImageSize = 2 * 1024 * 1024; // 最大2M

  var base64Img = content;

  if (base64Img.length === 0 || base64Img.length < maxImageSize) {
    recvDataHandler(file);
    return;
  }

  var compressRatio = maxImageSize / base64Img.length;
  var img = new Image();
  /**
   * 图片处理一定要放在onload事件中，否则第一次上传该图片会获取不到图片内容
   */

  img.onload = function () {
    var canvas = document.createElement('canvas'); // let canvas = document.getElementById("canvas");

    var ctx = canvas.getContext('2d');
    var width = Math.round(img.width * compressRatio);
    var height = Math.round(img.height * compressRatio); // console.log(compressRatio, img.width, img.height, width, height)

    canvas.width = width;
    canvas.height = height; // 铺底色

    ctx.fillStyle = '#fff';
    ctx.fillRect(0, 0, width, height);
    ctx.drawImage(img, 0, 0, width, height); //进行最小压缩

    canvas.toBlob(recvDataHandler, 'image/jpeg', 0.5);
  };

  img.src = base64Img;
}

export function replaceUrlPid(pId) {
  var url = new URL(window.location.href);
  var searchParams = new URLSearchParams(url.search.slice(1));
  searchParams.delete('pId');
  searchParams.append('pId', pId);
  return url.origin + url.pathname + '?' + searchParams.toString();
}
export function genSignature(params) {
  var token = 'fuck-your-mother-three-thousand-times-apes-not-kill-apes';

  var paramStr = _.chain(params).toPairs().sortBy(0).reject(function (pair) {
    var _pair = _slicedToArray(pair, 2),
        v = _pair[1]; // 复杂的类型，不参与签名，否则规则复杂，测试困难。
    // undefined 也不参与签名


    return _.isObject(v) || _.isArray(v) || _.isUndefined(v);
  }).map(function (pair) {
    var _pair2 = _slicedToArray(pair, 2),
        k = _pair2[0],
        v = _pair2[1];

    return "".concat(k, "=").concat(v);
  }).join('&').value(); // let signature = hmacMd5(paramStr, token).toString()


  var signature = md5(paramStr + token).toString(); // console.info({paramStr, signature})

  return signature;
}
export function randomString(len) {
  var charSet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  return _.sampleSize(charSet, len).toString().replace(/,/g, '');
}
export function deleteUrlParams(link, params, defaultUrl) {
  try {
    var url = new URL(link);
    var searchParams = new URLSearchParams(url.search.slice(1));

    var _iterator = _createForOfIteratorHelper(params),
        _step;

    try {
      for (_iterator.s(); !(_step = _iterator.n()).done;) {
        var pName = _step.value;
        searchParams.delete(pName);
      }
    } catch (err) {
      _iterator.e(err);
    } finally {
      _iterator.f();
    }

    return url.origin + url.pathname + '?' + searchParams.toString();
  } catch (err) {
    console.log(err); // 如果出现浏览器兼容问题，则直接返回默认链接

    return defaultUrl;
  }
}
var inBrowser = typeof window !== 'undefined' && window !== null;
export function checkIntersectionObserver() {
  if (inBrowser && 'IntersectionObserver' in window && 'IntersectionObserverEntry' in window && 'intersectionRatio' in window.IntersectionObserverEntry.prototype) {
    // Minimal polyfill for Edge 15's lack of `isIntersecting`
    // See: https://github.com/w3c/IntersectionObserver/issues/211
    if (!('isIntersecting' in window.IntersectionObserverEntry.prototype)) {
      Object.defineProperty(window.IntersectionObserverEntry.prototype, 'isIntersecting', {
        get: function get() {
          return this.intersectionRatio > 0;
        }
      });
    }

    return true;
  }

  return false;
}
export function checkJson(jsonStr) {
  if (typeof jsonStr === 'string') {
    try {
      var jsonObj = JSON.parse(jsonStr);

      if (jsonObj && _typeof(jsonObj) === 'object') {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  }
}
/**
 * 除法计算
 */

export function divisionCalculate(m, n) {
  var pieNum = parseFloat(m / n);
  var result = null;

  if (isFloatNum(pieNum)) {
    //商数部分
    var a = Math.floor(pieNum); //余数部分

    var b = parseInt(m % n); //返回商数 余数

    result = [a, b];
  } else {
    return [0, 0];
  }

  return result;
} //判断是否为浮点数

function isFloatNum(obj) {
  var reg = /^(-?\d+)(\.\d+)?$/;

  if (reg.test(obj)) {
    return true;
  }

  return false;
}
/**
 * 获取url中携带所有参数
 */


export function getUrlAllParams() {
  var Params = new Object(); //  获取 url 路径 '?' 以及后面的全部参数

  var url = location.search; //  去掉 ‘？’ ，拿到参数

  var strs = url.substr(1); //  使用 split 让不同参数分离 ，strs 是数组形式

  var str = strs.split('&');

  for (var i = 0; i < str.length; i++) {
    Params[str[i].split('=')[0]] = str[i].split('=')[1];
  }

  return Params;
}
export function isWebUrl(url) {
  var reg = /^(((ht|f)tps?):\/\/)?([^!@#$%^&*?.\s-]([^!@#$%^&*?.\s]{0,63}[^!@#$%^&*?.\s])?\.)+[a-z]{2,6}\/?/;

  if (reg.test(url)) {
    return true;
  }

  return false;
} // 生成随机值

export function getSessionId() {
  var num = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 6;
  var hashArr = String(new Date().getTime()).split('');

  for (var i = 0; i < num; i++) {
    var item = Math.floor(Math.random() * 10);
    hashArr[i] = item;
  }

  return hashArr.join('');
}
export function getAddressCodeMap(addressList) {
  var provinceToCityMap = new Map();
  addressList.forEach(function (province) {
    var cityToAreaMap = new Map();
    province.children.forEach(function (city) {
      var areaMap = new Map(city.children.map(function (area) {
        return [area.text, area.code];
      }));
      cityToAreaMap.set(city.text, areaMap);
    });
    provinceToCityMap.set(province.text, cityToAreaMap);
  });
  return provinceToCityMap;
}
export function initWebFavicon() {
  var iconUrl = brand.renderBrandElement({
    id: 'global.logo',
    type: 'image'
  });
  var link = document.querySelector("link[rel*='icon']") || document.createElement('link');
  link.type = 'image/x-icon';
  link.rel = 'icon';
  link.href = iconUrl;
  document.getElementsByTagName('head')[0].appendChild(link);
}

function extractPathAndId(url) {
  var pattern = /^\/([^/]+)\/(\d+)$/;
  var match = url.match(pattern);

  if (match) {
    // match[0] 是整个匹配的字符串，match[1] 是第一个捕获组（{path}），match[2] 是第二个捕获组（{id}）
    return "/".concat(match[1]);
  } else {
    // 如果不匹配，返回 null 或其他适当的值
    return url;
  }
}

import { sendQuickTrackingEvent, EventType } from './quickTracking';
export default {
  download: download,
  saveBlobAsFile: saveBlobAsFile,
  compareAppVersion: compareAppVersion,
  waitFor: waitFor,
  getUrlParam: getUrlParam,
  setImmediateInterval: setImmediateInterval,
  isInWeixn: isInWeixn,
  noEmoji: noEmoji,
  scroll: scroll,
  compressImage: compressImage,
  replaceUrlPid: replaceUrlPid,
  genSignature: genSignature,
  randomString: randomString,
  deleteUrlParams: deleteUrlParams,
  checkIntersectionObserver: checkIntersectionObserver,
  checkJson: checkJson,
  divisionCalculate: divisionCalculate,
  getUrlAllParams: getUrlAllParams,
  isWebUrl: isWebUrl,
  getSessionId: getSessionId,
  getAddressCodeMap: getAddressCodeMap,
  initWebFavicon: initWebFavicon,
  sendQuickTrackingEvent: sendQuickTrackingEvent,
  EventType: EventType,
  extractPathAndId: extractPathAndId
};