import "vant/es/dialog/style";
import _Dialog from "vant/es/dialog";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.number.constructor.js";
import xEnum from 'base/utils/enum';
import { sendQuickTrackingEvent, EventType } from 'base/utils/quickTracking';
export default {
  name: 'GoodsReturnScoreItem',
  filters: {
    sails: function sails(value) {
      value = _.replace(value, '兑换', '当前');
      return value;
    }
  },
  props: {
    source: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    index: {
      type: Number,
      default: 0
    },
    type: {
      type: Number,
      default: 0
    },
    onClickItem: {
      type: Function,
      default: function _default() {}
    }
  },
  methods: {
    onClick: function onClick() {
      var source = this.source,
          index = this.index,
          type = this.type;

      if (source.stockNum <= 0) {
        _Dialog.alert({
          message: '非常遗憾，本商品已兑完，您可以挑选其它商品',
          confirmButtonText: '好的'
        });

        return;
      }

      this.$router.push({
        path: "/goodsProfile/".concat(source.id),
        query: {
          type: this.type,
          activityType: 'returnScore'
        }
      });
      this.onClickItem(source, index);
      sendQuickTrackingEvent({
        eventName: 'ducuecmc_hc_waterfalls_clk',
        eventType: EventType.Click,
        pageName: 'dacuecmc_all_page',
        params: {
          goods_score: source === null || source === void 0 ? void 0 : source.score,
          goods_group_type: type,
          goods_name: source.goodsName,
          goods_id: source.id,
          goods_index: index,
          display_price: source.price,
          display_original_price: source.originalPrice,
          goods_source_waterfall: '等价全额返',
          goods_category_id: source.categoryId
        }
      });
    },
    getEventInfo: function getEventInfo(goods, index) {
      return {
        eventType: xEnum.ENUM_RP_EVENT_TYPE.GOODS_RETURN_SCORE_LIST_EP.value,
        eventInfo: {
          pId: this.pId,
          userId: this.userId,
          goodsId: goods.id,
          score: goods === null || goods === void 0 ? void 0 : goods.score,
          goodsIndex: index,
          goodsGroupId: this.type,
          webUrl: window.location.href,
          goodsPrice: goods.price,
          goodsName: goods.goodsName,
          goodsCategoryId: goods.categoryId
        }
      };
    }
  }
};