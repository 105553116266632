var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "com-container"
  }, [_c("img", {
    class: _vm.imageClassName,
    attrs: {
      width: _vm.width,
      height: _vm.height,
      src: _vm.imageSrc
    }
  })]);
};

var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };