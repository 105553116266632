var getters = {
  userId: function userId(state) {
    return state.user.userId;
  },
  isLogin: function isLogin(state) {
    return state.user.isLogin;
  },
  points: function points(state) {
    return state.user.points;
  },
  signDetail: function signDetail(state) {
    return state.user.signDetail;
  },
  signDetails: function signDetails(state) {
    return state.user.signDetails;
  },
  appState: function appState(state) {
    return state.appState;
  }
};
export default getters;