var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    class: "iconfont " + _vm.icon,
    style: {
      fontSize: _vm.size,
      // ios 上面，color 只有此处才生效。安卓是下面的 use 上加，就可以生效。
      color: _vm.color
    }
  });
};

var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };