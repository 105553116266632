import axios from 'axios';
var BASE_URL = 'https://exapi.svipduihuan.com/api/'; // const BASE_URL = 'http://192.168.1.155:8081/api/'

var srvApi = axios.create({
  // timeout: 10000,
  baseURL: BASE_URL
}); // srvApi.interceptors.request.use(function (config) {
//   let rootState = store.getState()
//   let { dexAccount: { address } } = rootState
//   // config.headers.common['x-user-wallet-addr'] = '0x07f452344909f601aa8f9167e673d432404735d9'
//   config.headers.common['x-user-wallet-addr'] = address
//   return config
// })

var postRegInfo = function postRegInfo(params) {
  return srvApi.post("quick/v1/regInfo", params);
};

var getRankingList = function getRankingList(params) {
  return srvApi.get("quick/v1/dtk/rankings", {
    params: params
  });
};

var getNineGoodsList = function getNineGoodsList(params) {
  return srvApi.get("quick/v1/dtk/nineGoods", {
    params: params
  });
};

var getGoodsList = function getGoodsList(params) {
  return srvApi.get("quick/v1/dtk/goods", {
    params: params
  });
};

var getPrivilegeLink = function getPrivilegeLink(params) {
  return srvApi.get("quick/v1/dtk/privilegeLink", {
    params: params
  });
};

var getChannelByPid = function getChannelByPid(pId) {
  return srvApi.get("web/v1/channels/pId/".concat(pId));
};

var getChannelDrawPrizeList = function getChannelDrawPrizeList(pId, clientId) {
  return srvApi.get("web/v1/channels/pId/".concat(pId, "/drawPrizes"), {
    params: {
      clientId: clientId
    }
  });
};

var getChannelAdvertisementList = function getChannelAdvertisementList(pId) {
  return srvApi.get("web/v1/channels/pId/".concat(pId, "/advertisements"));
};

var getAbAllocationByAbTests = function getAbAllocationByAbTests(params) {
  return srvApi.post("web/v1/abTests/abAllocations", params);
};

export default {
  postRegInfo: postRegInfo,
  getRankingList: getRankingList,
  getNineGoodsList: getNineGoodsList,
  getGoodsList: getGoodsList,
  getPrivilegeLink: getPrivilegeLink,
  getChannelByPid: getChannelByPid,
  getChannelDrawPrizeList: getChannelDrawPrizeList,
  getChannelAdvertisementList: getChannelAdvertisementList,
  getAbAllocationByAbTests: getAbAllocationByAbTests
};