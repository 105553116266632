var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "announce-bar bg-white mb2 mx2 br10 bold"
  }, [_c("div", {
    staticClass: "color_title"
  }, [_vm._v(_vm._s(_vm.brand) + "商城提醒：您的" + _vm._s(_vm.globalPointName) + "今日即将全部过期作废！")]), _c("div", {
    staticClass: "flex items-start justify-between mt12px"
  }, [_c("div", {
    staticClass: "color_6c"
  }, [_vm._v(_vm._s(_vm.globalPointName) + "余额：")]), _c("div", {
    staticClass: "content",
    staticStyle: {
      color: "#ff0000"
    }
  }, [_vm._v(_vm._s(_vm.points.activePoints))])]), _vm._m(0), _c("div", {
    staticClass: "flex items-start justify-between mt12px"
  }, [_c("div", {
    staticClass: "color_6c"
  }, [_vm._v("20000" + _vm._s(_vm.globalPointName) + "：")]), _c("div", {
    staticClass: "content"
  }, [_vm._v("希尔顿同款冬被、蓝牙耳机、负离子吹风机、每日坚果礼盒；")])]), _c("div", {
    staticClass: "flex items-start justify-between mt12px"
  }, [_c("div", {
    staticClass: "color_6c"
  }, [_vm._v("40000" + _vm._s(_vm.globalPointName) + "：")]), _c("div", {
    staticClass: "content"
  }, [_vm._v("高档红酒、扫地机器人、保暖衣套装、加厚不锈钢蒸锅；")])]), _c("div", {
    staticClass: "flex items-start justify-between mt12px"
  }, [_c("div", {
    staticClass: "color_6c"
  }, [_vm._v("60000" + _vm._s(_vm.globalPointName) + "：")]), _c("div", {
    staticClass: "content"
  }, [_vm._v("茅台、暖风机、名牌腕表、护肤品套装、航拍无人机；")])]), _vm._m(1), _vm._m(2)]);
};

var staticRenderFns = [function () {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "flex items-start justify-between mt12px"
  }, [_c("div", {
    staticClass: "color_6c"
  }, [_vm._v("可换购以下商品：")])]);
}, function () {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "flex items-start justify-between mt12px"
  }, [_c("div", {
    staticClass: "color_6c"
  }, [_vm._v("热兑品牌：")]), _c("div", {
    staticClass: "content"
  }, [_vm._v("美的、茅台、五粮液、创维、康佳...更多大牌好礼点击下方")])]);
}, function () {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "flex justify-between items-center mt12px"
  }, [_c("div", {
    staticClass: "btn flex items-center justify-center detail"
  }, [_vm._v("查看详情")]), _c("div", {
    staticClass: "btn flex items-center justify-center go"
  }, [_vm._v("立即兑换")])]);
}];
render._withStripped = true;
export { render, staticRenderFns };