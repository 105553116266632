import request from 'base/utils/request'; // 查询商品列表

export function getGoodList(query) {
  return request({
    url: '/api/v4/goodsList',
    method: 'get',
    params: query
  }).then(function (response) {
    // if (process.env.NODE_ENV === 'development') {
    //   if (response.code === 200) {
    //     let goodsList = response.data.goodsList;
    //     // 对象存储做了白名单，本地调试时，为了方便统一写死图片
    //     goodsList = goodsList.map(goods => {
    //       goods.imgUrl = 'http://img.yitanjj.com/uploadimg/2020416/1ee342f3n.jpg'
    //       return goods
    //     })
    //     response.data.goodsList = goodsList
    //   }
    // }
    return response;
  });
} // 查询商品详情

export function getGoodDetail(query) {
  return request({
    url: '/api/v4/goodsDetail',
    method: 'get',
    params: query
  }).then(function (response) {
    // if (process.env.NODE_ENV === 'development') {
    //   if (response.code === 200) {
    //     let goods = response.data;
    //     // 对象存储做了白名单，本地调试时，为了方便统一写死图片
    //     goods.bannerImgList = goods.bannerImgList.map(img => 'http://img.yitanjj.com/uploadimg/2020416/1ee342f3n.jpg')
    //     goods.detailImgList = goods.detailImgList.map(img => 'http://img.yitanjj.com/uploadimg/2020416/1ee342f3n.jpg')
    //     response.data = goods
    //   }
    // }
    return response;
  });
} // 旧版本登录接口

export function userLogin(data) {
  return request({
    url: '/api/mall/user/login',
    method: 'post',
    data: data
  });
} // 新版本登录接口

export function login(data) {
  return request({
    url: '/api/v7/user/login',
    method: 'post',
    data: data
  });
} // 获取渠道信息

export function getProject(id) {
  return request({
    url: '/project/' + id,
    method: 'get'
  });
} // 获取jf

export function getPoints(userId, pId) {
  return request({
    url: '/api/v8/mall/user/points',
    method: 'get',
    params: {
      userId: userId,
      pId: pId
    }
  });
} // 获取jf

export function presentPointsAgain(data) {
  return request({
    url: '/api/user/addPoint',
    method: 'post',
    data: data
  });
} // 获取订单信息

export function getOrderDetail(query) {
  return request({
    url: '/api/orderDetail',
    method: 'get',
    params: query
  });
} // 订单支付状态查询

export function getOrderPayStatus(orderId) {
  return request({
    url: "/api/queryRealOrder/".concat(orderId),
    method: 'get'
  }).then(function (response) {
    return response;
  });
} // 查询订单

export function getOrderList(query) {
  return request({
    url: '/api/queryOrder',
    method: 'get',
    params: query
  }).then(function (response) {
    // if (process.env.NODE_ENV === 'development') {
    //   if (response.code === 200) {
    //     let orders = response.data;
    //     // 对象存储做了白名单，本地调试时，为了方便统一写死图片
    //     orders = orders.map(order => {
    //       order.goods.imgUrl = 'http://img.yitanjj.com/uploadimg/2020416/1ee342f3n.jpg'
    //       return order
    //     })
    //     response.data = orders
    //   }
    // }
    return response;
  });
} // 保存订单

export function saveOrder(data) {
  return request({
    url: '/api/v4/saveOrder',
    method: 'post',
    data: data
  });
} // 物流详情

export function expressPath(id) {
  return request({
    url: '/api/expressPath/' + id,
    method: 'get'
  });
} // 修改地址

export function updateAddress(data) {
  return request({
    url: '/api/updateAddress',
    method: 'post',
    data: data
  });
}
export function updateMultiAddress(data) {
  return request({
    url: '/api/multi_order/updateAddress',
    method: 'post',
    data: data
  });
}
export function getExpressFee(params) {
  return request({
    url: '/api/multi_order/getExpressFee',
    method: 'get',
    params: params
  });
} // 修改订单状态

export function updateOrderStatus(params) {
  return request({
    url: '/api/updateOrderStatus',
    method: 'post',
    params: params
  });
} // 发送验证码

export function codeSend(phone) {
  return request({
    url: '/code/send/' + phone,
    method: 'get'
  });
} // 获取商品评论

export function getGoodsComment(id) {
  return request({
    url: '/api/good/comment/' + id,
    method: 'get'
  });
} // 签到

export function signIn(id) {
  return request({
    url: '/api/signIn/' + id,
    method: 'get'
  });
} // 签到查询

export function getSignInDetail(params) {
  return request({
    url: '/api/signIn',
    method: 'get',
    params: params
  });
} // 退款

export function refund(params) {
  return request({
    url: '/api/refund',
    method: 'post',
    params: params
  });
} // 获取立返jf

export function getRefundPoints(userId) {
  return request({
    url: '/api/refundPoints',
    method: 'get',
    params: {
      userId: userId
    }
  });
} // 根据订单状态获取订单数量

export function getOrderCountWithStatus(_ref) {
  var userId = _ref.userId;
  return request({
    url: '/api/v5/queryOrderCount',
    method: 'get',
    params: {
      userId: userId
    }
  });
} // 获取用户未支付订单总数

export function getUnpayOrderCount(_ref2) {
  var userId = _ref2.userId;
  return request({
    url: '/api/queryOrderForCount',
    method: 'get',
    params: {
      userId: userId
    }
  });
} // 获取用户订单总数

export function getOrderTotalCount(_ref3) {
  var userId = _ref3.userId,
      phone = _ref3.phone;
  return request({
    url: '/api/orderTotalCount',
    method: 'get',
    params: {
      userId: userId,
      phone: phone
    }
  });
} // 提交投诉

export function postComplaint(data) {
  return request({
    url: '/api/complaint',
    method: 'post',
    data: data
  });
} // 提交评论

export function postComment(data) {
  return request({
    url: '/api/orderComment',
    method: 'post',
    data: data
  });
} // 上传图片

export function uploadImage(file) {
  var formData = new FormData();
  formData.append('file', file);
  return request('/api/img/upload', {
    method: 'post',
    data: formData
  });
} // 绑定/修改手机号

export function bindUpdatePhone(params) {
  return request({
    url: '/api/bindUpdatePhone',
    method: 'post',
    params: params
  });
} // 获取验证码

export function sendCaptcha(phone, uuid) {
  var isSliderCode = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
  var imageCode = arguments.length > 3 ? arguments[3] : undefined;
  return request({
    url: "/api/v6/code/send",
    method: 'get',
    params: {
      phone: phone,
      uuid: uuid,
      isSliderCode: isSliderCode,
      imageCode: imageCode
    }
  });
} // 校验验证码

export function checkCaptcha(params) {
  return request({
    url: '/api/v6/code/check',
    method: 'post',
    params: params
  });
} // 新增查询订单白名单手机号

export function postUserWhitePhone(params) {
  return request({
    url: '/api/userWhitePhone/add',
    method: 'post',
    params: params
  });
} // 判断指定用户是否属于“查询订单白名单手机号”

export function getUserWhitePhoneIsExist(params) {
  return request({
    url: '/api/userWhitePhone/isExist',
    method: 'get',
    params: params
  });
} // 获取渠道配置

export function getProjectConfig(params) {
  return request({
    url: '/api/projectConfig',
    method: 'get',
    params: params
  });
}
export function getJssdkConfig(url) {
  var urlEncoded = window.encodeURIComponent(url);
  return request({
    url: '/wxPay/createSignature',
    method: 'get',
    params: {
      url: urlEncoded
    }
  });
}
/**
 * 获取砍价详情
 */

export function getBargainDetail(taskId) {
  return request({
    url: '/api/bargainDetail',
    method: 'get',
    params: {
      taskId: taskId
    }
  });
}
/**
 * 获取用户在指定砍价任务中的砍价次数
 */

export function getUserBargainCount(userId, taskId) {
  return request({
    url: '/api/countBargainRecord',
    method: 'get',
    params: {
      userId: userId,
      taskId: taskId
    }
  });
}
/**
 * 发起砍价活动
 */

export function createBargain(userId, goodsId) {
  return request({
    url: '/api/createBargain',
    method: 'post',
    params: {
      userId: userId,
      goodsId: goodsId
    }
  });
}
/**
 * 帮人砍价
 */

export function executeBargain(userId, taskId) {
  return request({
    url: '/api/executeBargain',
    method: 'post',
    params: {
      userId: userId,
      taskId: taskId
    }
  });
}
/**
 * 获取用户最新进行中砍价任务
 */

export function getUserLatestBargain(userId) {
  return request({
    url: '/api/latestBargain',
    method: 'get',
    params: {
      userId: userId
    }
  });
}
/**
 * 获取用户砍价任务列表，包括进行中和砍价成功的任务
 */

export function getUserBargainList(userId) {
  return request({
    url: '/api/listBargain',
    method: 'get',
    params: {
      userId: userId
    }
  });
}
/**
 * 获取某个砍价任务的砍价记录
 */

export function getBargainRecordList(params) {
  return request({
    url: '/api/listBargainRecord',
    method: 'get',
    params: params
  });
}
/**
 * 获取某个砍价任务的晒单记录
 */

export function getBargainShowList(params) {
  return request({
    url: '/api/listBargainShow',
    method: 'get',
    params: params
  });
}
/**
 * 分享赠送jf
 */

export function shareAddPoints(userId, pId) {
  return request({
    url: '/api/shareAddPoints',
    method: 'get',
    params: {
      userId: userId,
      pId: pId
    }
  });
}
/**
 * 获取默认搜索值
 */

export function getDefaultKeyword() {
  return request({
    url: '/api/search/defaultKeyword',
    method: 'get'
  });
}
/**
 * 商品搜索
 */

export function goodsSearch(params) {
  return request({
    url: "/api/search/goodsSearch",
    method: 'get',
    params: params
  });
}
/**
 * 获取图形验证码
 */

export function fetchImageCaptcha() {
  return request({
    url: "/api/captcha",
    method: 'get'
  });
}
/**
 * 获取商品列表（分页）
 */

export function getPagingGoodsList(query) {
  return request({
    url: "/api/v9/goodsList",
    method: 'get',
    params: query
  });
}
/**
 * 保存退订号码
 */

export function addCancelPhone(params) {
  return request({
    url: "/api/addCancelPhone",
    method: 'post',
    params: params
  });
}
export function getAdvertiseConfig(params) {
  return request({
    url: "/api/webAdvertiseConfig",
    method: 'get',
    params: params
  });
}
/**
 * 获取地址地区json
 */

export function getAddressTree() {
  return request({
    url: "/api/getAddress",
    method: 'get'
  });
}
/**
 * 获取七鱼客服config
 */

export function getCustomerConfig() {
  return request({
    url: '/api/kefuConfig',
    method: 'get'
  });
}
export function getTrafficGuideConfig(params) {
  return request({
    url: "/api/trafficGuideConfig",
    method: 'get',
    params: params
  });
}
export function getGoodsGroupConfig(params) {
  return request({
    url: "/api/goodsGroupConfig",
    method: 'get',
    params: params
  });
}
export function getWxSchema(params) {
  return request({
    url: "/wxPay/wxUrlScheme",
    method: 'get',
    params: params
  });
}
/**
 * 支付宝h5支付
 */

export function h5PayAlipay(params) {
  return request({
    url: "/aliPay/wapPay",
    method: 'post',
    params: params
  });
}
/**
 * 银联h5支付
 */

export function h5CloudPay(params) {
  return request({
    url: "/cloudPay/h5Pay",
    method: 'post',
    params: params
  });
}
/**
 * 获取小程序跳转短链
 * @param  {} params
 */

export function getMpUrlScheme(params) {
  return request({
    url: "/api/wxUrlScheme",
    method: 'get',
    params: params
  });
}
/**
 * 获取活动页配置
 * @param  {} pageId
 */

export function getActivityPageConfigs(pageId) {
  return request({
    url: "/api/webActivityPageConfig",
    method: 'get',
    params: {
      pageId: pageId
    }
  });
}
export function getBuryPointConfig() {
  return request({
    url: '/api/buryPointConfig',
    method: 'get'
  });
}
/**
 * 获取商详页推荐商品列表
 * @param  {} params
 */

export function getRecommendGoods(params) {
  return request({
    url: '/api/v9/goodsRecommendList',
    method: 'get',
    params: params
  });
}
/**
 * 再次购买
 * @param  {} data
 */

export function reOrder(data) {
  return request({
    url: '/api/v4/reOrder',
    method: 'post',
    data: data
  });
}
/**
 * 申请退款
 * @param {} data
 */

export function refundSubmit(data) {
  return request({
    url: '/api/refundRequest',
    method: 'post',
    data: data
  });
} // 获取物流类型

export function getExpressType() {
  return request({
    url: '/api/expressTypes',
    method: 'get'
  });
}
/**
 * 填写寄回物流单号
 *
 */

export function submitExpressInfor(data) {
  return request({
    url: '/api/updateReturnGoodsExpress',
    method: 'post',
    data: data
  });
}
/*
 * IP获取地址
 */

export function getAddressByIp() {
  return request({
    url: '/api/getAddress'
  });
}
/**
 * @description:获取顺手买活动商品
 * @param {{ projectId: string, goodsSpecId: string }} params
 * @return {*}
 */

export function getConvenientBuyingGoods(params) {
  return request({
    url: '/activity/getConvenientBuyingGoods',
    params: params
  });
}
/**
 * @description: 保存顺手买订单
 * @param {} data
 * @return {*}
 */

export function saveConvenientlyBuyOrder(data) {
  return request({
    url: '/api/multi_order/saveOrder',
    method: 'post',
    data: data
  });
}
export function wxH5MergePay(data) {
  return request({
    url: '/wxPay/merge/h5Pay',
    method: 'post',
    data: data
  });
}
export function aliH5MergePay(data) {
  return request({
    url: '/aliPay/merge/wapPay',
    method: 'post',
    data: data
  });
}
export function queryOrderDetailByMergeOrderId(mergeOrderId) {
  return request({
    url: '/api/merge/orderDetail',
    method: 'get',
    params: {
      mergeOrderId: mergeOrderId
    }
  });
}
/**
 * 获取商品优惠配置
 * @param  {} goodsId
 */

export function getGoodsCouponConfig(goodsId) {
  return request({
    url: '/api/goodsCouponConfig',
    method: 'get',
    params: {
      goodsId: goodsId
    }
  });
}