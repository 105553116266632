export default {
  name: 'IconTag',
  props: {
    text: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: function _default() {
        return 'black';
      }
    },
    icon: {
      type: String,
      default: ''
    },
    width: {
      type: String,
      default: ''
    },
    height: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: '14px'
    },
    iconSize: {
      type: String,
      default: '14px'
    },
    flexBasis: {
      type: String,
      default: '100%'
    },
    textAlign: {
      type: String,
      default: 'center'
    }
  }
};