var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _vm.pId ? _c("div", {
    staticClass: "mt2 guess-your-favor",
    class: _vm.className
  }, [_c("div", {
    staticClass: "fs14 flex items-center justify-center my2 color_title",
    staticStyle: {
      width: "100%"
    }
  }, [_vm._v("— 您可能也喜欢 —")]), _c("GoodsWaterfallList", {
    ref: "waterfallGoodsList",
    attrs: {
      name: "猜你喜欢",
      "p-id": _vm.pId,
      type: _vm.groupTypeIds[_vm.activeTabIndex].groupTypeId
    }
  })], 1) : _vm._e();
};

var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };