export default {
  name: 'WechatShareMask',
  props: {
    visible: {
      type: Boolean,
      default: function _default() {
        return false;
      }
    },
    shareImage: {
      type: String,
      default: function _default() {
        return require('base/assets/img/wxShareMask.png');
      }
    }
  },
  data: function data() {
    return {};
  },
  methods: {
    clearMask: function clearMask() {
      this.$emit('update:visible', false);
    }
  }
};