import "core-js/modules/es.object.to-string.js";
// vm.$mount(el) => document.body.appendChild();
// Vue.extend() 继承 【子类继承父类的方法】
// 父类 Vue--Vue.extend--子类 定制化的 Vue 构造函数
import Vue from 'vue';
import GetCouponDialog from './getCouponDialog';
var Customized = Vue.extend(GetCouponDialog);
export var getCouponDialog = function getCouponDialog() {
  var option = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return new Promise(function (resolve, reject) {
    var res = new Customized({
      data: function data() {
        return {
          duration: option.duration || 1000,
          onClose: typeof option.onClose === 'function' ? option.onClose : function () {}
        };
      },
      methods: {
        resolve: resolve,
        reject: reject
      }
    }).$mount(); // console.log(res.$el);

    document.body.appendChild(res.$el);
  });
};