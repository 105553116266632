import "core-js/modules/es.number.constructor.js";
export default {
  name: 'ProgressBar',
  props: {
    percentage: {
      // 当前进度（即进度条宽度）
      type: Number,
      default: 0
    }
  },
  data: function data() {
    return {};
  }
};