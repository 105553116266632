var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "Layout"
  }, [_c("keep-alive", [_c("router-view")], 1), _vm.showBanner ? _c("div", {
    staticClass: "add-to-desktop",
    on: {
      click: _vm.openQuickApp
    }
  }, [_c("div", {
    staticClass: "flex items-center justify-start"
  }, [_c("img", {
    staticClass: "logo",
    attrs: {
      src: _vm.imgLogo
    }
  }), _c("div", [_vm._v("点击添加图标到桌面，享百亿补贴!")])]), _c("div", {
    staticClass: "close-btn",
    on: {
      click: function click($event) {
        $event.preventDefault();
        return _vm.onCloseBanner.apply(null, arguments);
      }
    }
  }, [_c("img", {
    attrs: {
      src: _vm.imgClose
    }
  })])]) : _vm._e(), _vm.showFootBar ? _c("FootBarThemed") : _vm._e()], 1);
};

var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };