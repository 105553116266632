import _objectSpread from "/root/workspace/dev-client-a/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { mapState } from 'vuex';
export default {
  data: function data() {
    return {
      brand: this.renderBrandElement({
        id: 'global.brand'
      })
    };
  },
  computed: _objectSpread({}, mapState({
    points: function points(state) {
      return state.user.points;
    }
  }))
};