import "core-js/modules/es.object.to-string.js";
// vm.$mount(el) => document.body.appendChild();
// Vue.extend() 继承 【子类继承父类的方法】
// 父类 Vue--Vue.extend--子类 定制化的 Vue 构造函数
import Vue from 'vue';
import ServiceRuleDialog from './serviceRuleDialog';
import store from '@/store';
var Customized = Vue.extend(ServiceRuleDialog);
export var serviceRuleDialog = function serviceRuleDialog() {
  return new Promise(function (resolve, reject) {
    var res = new Customized({
      data: function data() {},
      methods: {
        resolve: resolve,
        reject: reject
      },
      store: store
    }).$mount(); // console.log(res.$el);

    document.body.appendChild(res.$el);
  });
};