import { IconFont } from 'components';
import { shareAddPoints } from 'base/api';
import store from '@/store';
export default {
  name: 'ShareDialog',
  components: {
    IconFont: IconFont
  },
  props: {},
  data: function data() {
    return {
      visible: true
    };
  },
  computed: {},
  created: function created() {},
  methods: {
    closeDialog: function closeDialog() {
      this.visible = false;
    },
    hide: function hide() {
      this.visible = false;
    },
    clickCopyLink: function clickCopyLink() {
      // 此dialog中没有注入store,不能使用map state，所以需要使用store来获取
      shareAddPoints(store.state.user.userId, store.state.appState.pId);
      this.hide();
      this.resolve();
    }
  }
};