import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.function.name.js";
import Vue from 'vue';
import GoodsNormalItem from 'widgets/ListItem/GoodsNormalItem';
import VirtualList from 'vue-virtual-scroll-list';
import xEnum from 'base/utils/enum';
var DirectRenderItem = Vue.component('DirectRenderItem', {
  functional: true,
  // eslint-disable-next-line vue/require-prop-types
  props: ['key', 'source', 'dataComponent', 'extraProps', 'class', 'style'],
  render: function render(createElement, context) {
    // context.props = {
    //   ...context.props,
    //   ...context.props.extraProps,
    //   ...context.data,
    //   style: context.props.itemStyle,
    //   class: context.props.itemClass,
    // }
    return createElement(context.props.dataComponent, context, context.props.children);
  }
});
export default {
  name: 'GoodsListThemed',
  components: {
    VirtualList: VirtualList,
    DirectRenderItem: DirectRenderItem
  },
  props: {
    itemStyle: {
      type: Object,
      default: function _default() {}
    },
    dataKey: {
      type: String,
      default: function _default() {
        return 'id';
      }
    },
    name: {
      type: String,
      default: function _default() {
        return 'GoodsListThemed';
      }
    },
    type: {
      type: Number,
      default: 0
    },
    pId: {
      type: String,
      default: ''
    },
    styleWrap: {
      type: Object,
      default: function _default() {}
    },
    styleLoadingText: {
      type: Object,
      default: function _default() {}
    },
    goodsList: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    goodsItem: {
      type: Object,
      default: function _default() {
        return GoodsNormalItem;
      }
    },
    pageSource: {
      type: Number,
      default: function _default() {
        return xEnum.ENUM_PAGE_SOURCE.UNKNOWN.getValue();
      }
    },
    // 列表每行有多少个单元
    cellPerRow: {
      type: Number,
      default: function _default() {
        return 2;
      }
    },
    loading: {
      type: Boolean,
      default: false
    },
    keyWords: {
      type: String,
      default: ''
    },
    showRankTag: {
      type: Boolean,
      default: function _default() {
        return false;
      }
    },
    useVirtualList: {
      type: Boolean,
      default: true
    },
    showPlaceholderItem: {
      type: Boolean,
      default: function _default() {
        return true;
      }
    }
  },
  data: function data() {
    return {
      scrollTop: true
    };
  },
  computed: {
    _loading: function _loading() {
      // 不能用 !this.goodsList判断，因为this.goodsList外面传进来的值默认永远为[]
      // return !this.goodsList || this.loading
      return this.loading;
    }
  },
  created: function created() {},
  destroyed: function destroyed() {
    clearTimeout(this.delayer);
  },
  methods: {
    onClickItem: function onClickItem(source, index) {
      this.$store.dispatch('enterGoodsProfile', {
        module: this.name,
        index: index,
        type: this.type,
        goods: source
      });
      this.$emit('click-item', source, this.type);
    },
    onClickMore: function onClickMore() {
      var path = this.renderBrandElement({
        id: 'goodsList.moreItemPath'
      });

      if (this.$route.path !== path) {
        this.$router.push({
          path: path,
          params: {
            scrollTop: this.scrollTop
          }
        });
      }

      this.$emit('click-more');
    }
  }
};