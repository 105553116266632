import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.number.constructor.js";

var render = function render() {
  var _vm$tileData$, _vm$tileData$2, _vm$tileData$3;

  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "com-container flex justify-between mx2"
  }, [_c("div", {
    directives: [{
      name: "track",
      rawName: "v-track:exposure",
      arg: "exposure"
    }],
    staticClass: "porcelain-left",
    class: "".concat(_vm.hasTwoPorcelainItem ? "two-porcelain-height" : "three-porcelain-height"),
    style: {
      "background-image": "url(".concat((_vm$tileData$ = _vm.tileData[0]) === null || _vm$tileData$ === void 0 ? void 0 : _vm$tileData$.img, ")")
    },
    attrs: {
      "track-params": JSON.stringify(_vm.getEventInfo("首页瓷片位置"))
    },
    on: {
      click: function click($event) {
        return _vm.tileClick(_vm.tileData[0], 0);
      }
    }
  }, [_c("div", {
    staticClass: "point-bg flex items-center"
  }, [_c("img", {
    staticStyle: {
      width: "12px",
      height: "13px"
    },
    attrs: {
      src: require("base/assets/img/icon/point@2x.png")
    }
  }), _c("div", {
    staticClass: "text-white text-12"
  }, [_vm._v(_vm._s(_vm.points.activePoints))])])]), _c("div", {
    staticClass: "porcelain-right"
  }, [_c("div", {
    class: "".concat(_vm.hasTwoPorcelainItem ? "two-porcelain-height" : "three-porcelain-right"),
    style: {
      "background-image": "url(".concat((_vm$tileData$2 = _vm.tileData[1]) === null || _vm$tileData$2 === void 0 ? void 0 : _vm$tileData$2.img, ")")
    },
    on: {
      click: function click($event) {
        return _vm.tileClick(_vm.tileData[1], 1);
      }
    }
  }, [_c("CountDown", {
    staticClass: "count-down",
    attrs: {
      "bg-color": "#FFCCCC",
      "colon-color": "#D42323",
      "font-color": "#D42323",
      "block-style": {
        fontWeight: 700,
        backgroundImage: "".concat(_vm.countDownBg),
        padding: "1px 3px"
      },
      "colon-style": {
        fontWeight: 700,
        margin: "0 1px"
      },
      "end-time": Number(_vm.countDownEnd)
    }
  })], 1), !_vm.hasTwoPorcelainItem ? _c("div", {
    staticClass: "three-porcelain-right",
    style: {
      "background-image": "url(".concat((_vm$tileData$3 = _vm.tileData[2]) === null || _vm$tileData$3 === void 0 ? void 0 : _vm$tileData$3.img, ")")
    },
    on: {
      click: function click($event) {
        return _vm.tileClick(_vm.tileData[2], 2);
      }
    }
  }) : _vm._e()])]);
};

var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };