import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.json.stringify.js";

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "wrapper-con"
  }, [_c("div", {
    staticClass: "more-purchase-card-con mx2 mt2 px-[10px] pt-[28px] pb-[10px] relative"
  }, [_vm.goods.length > 1 && _vm.changeable ? _c("div", {
    staticClass: "absolute flex items-center gap-1 right-[10px] top-[5px] z-10"
  }, [_c("van-image", {
    attrs: {
      width: "12px",
      height: "12px",
      radius: 4,
      src: "".concat(_vm.imgBaseUrl, "/892935502036992"),
      fit: "cover"
    }
  }), _c("div", {
    staticClass: "text-[12px] text-[#EB713A]",
    on: {
      click: _vm.changeCurrentIndex
    }
  }, [_vm._v("换一换")])], 1) : _vm._e(), _c("div", {
    directives: [{
      name: "track",
      rawName: "v-track:exposure",
      arg: "exposure"
    }],
    key: "".concat(_vm.goodsSpec.goodsId, "_").concat(_vm.goodsSpec.id),
    staticClass: "flex justify-between items-center",
    attrs: {
      "track-params": JSON.stringify(_vm.getEventInfo())
    },
    on: {
      click: _vm.openDetails
    }
  }, [_c("div", {
    staticClass: "flex gap-[10px]"
  }, [_c("van-image", {
    attrs: {
      width: "65px",
      height: "65px",
      radius: 4,
      src: _vm.goodsSpec.goodsImg,
      fit: "cover"
    }
  }), _c("div", {
    staticClass: "flex flex-col justify-between w-[200px]"
  }, [_c("van-skeleton", {
    attrs: {
      round: "",
      loading: false,
      row: 3,
      "row-width": [160, 130, 180]
    }
  }, [_c("div", {
    staticClass: "text-[14px] text-[#5B1907] two-line-ellipsis"
  }, [_vm._v(_vm._s(_vm.goodsSpec.goodsName))]), _c("div", {
    staticClass: "text-[12px] text-[#BDA098]"
  }, [_vm._v("规格：" + _vm._s(_vm.goodsSpec.goodsSpecName))]), _c("div", {
    staticClass: "flex items-end"
  }, [_vm.isCostPoints ? _c("span", {
    staticClass: "text-[14px] text-[#E54635]"
  }, [_vm._v(" " + _vm._s(_vm.goodsSpec.costPoints) + " 积分 + ")]) : _vm._e(), _c("span", {
    staticClass: "text-[12px] text-[#E54635] mr-[10px]"
  }, [_vm._v(_vm._s(_vm.goodsSpec.salePrice) + "元")]), _c("span", {
    staticClass: "text-[12px] text-[#BDA098] line-through"
  }, [_vm._v(_vm._s(_vm.goodsSpec.originalPrice) + "元")])])])], 1)], 1), _c("div", {
    on: {
      click: function click($event) {
        $event.stopPropagation();
      }
    }
  }, [_c("van-checkbox", {
    attrs: {
      disabled: _vm.loading,
      "checked-color": "#E54635"
    },
    on: {
      change: _vm.selectChanged
    },
    model: {
      value: _vm.selected,
      callback: function callback($$v) {
        _vm.selected = $$v;
      },
      expression: "selected"
    }
  })], 1)])]), _c("div", {
    staticClass: "detail-action-sheet"
  }, [_c("van-action-sheet", {
    attrs: {
      closeable: false
    },
    model: {
      value: _vm.detailsShow,
      callback: function callback($$v) {
        _vm.detailsShow = $$v;
      },
      expression: "detailsShow"
    }
  }, [_c("div", {
    staticClass: "details-wrapper flex flex-col items-center relative bg-[#f2f2f2] max-h-[80vh] pt-[15px] px-[10px] gap-[13px]"
  }, [_c("div", {
    staticClass: "title"
  }, [_c("van-image", {
    attrs: {
      width: "78px",
      height: "18px",
      src: "".concat(_vm.imgBaseUrl, "/892941160153089"),
      fit: "cover"
    }
  })], 1), _c("van-image", {
    staticClass: "absolute top-[10px] right-[10px]",
    attrs: {
      width: "30px",
      height: "30px",
      src: "".concat(_vm.imgBaseUrl, "/892941160153088"),
      fit: "cover"
    },
    on: {
      click: function click($event) {
        _vm.detailsShow = false;
      }
    }
  }), _c("div", {
    staticClass: "details-con w-full flex flex-col pb-[20px] gap-[10px] max-h-[80vh] overflow-y-scroll"
  }, [_c("img", {
    staticClass: "w-[calc(100vw - 20px)] rounded-[10px]",
    attrs: {
      src: _vm.goodsSpec.bannerImgList[0],
      fit: "cover"
    }
  }), _c("div", {
    staticClass: "flex flex-col justify-between gap-[4px] w-full bg-white rounded-[10px] p-[10px]"
  }, [_c("div", {
    staticClass: "flex justify-between"
  }, [_c("div", {
    staticClass: "flex items-end text-[#E54635]"
  }, [_vm.isCostPoints ? _c("span", {
    staticClass: "text-[18px]"
  }, [_vm._v(_vm._s(_vm.goodsSpec.costPoints) + "积分")]) : _vm._e(), _vm.isCostPoints ? _c("span", {
    staticClass: "text-[12px]"
  }, [_vm._v("+")]) : _vm._e(), _c("span", {
    staticClass: "text-[12px]"
  }, [_vm._v(_vm._s(_vm.goodsSpec.salePrice) + "元")])]), _c("div", {
    staticClass: "flex items-center gap-[2px]"
  }, [_c("van-image", {
    attrs: {
      width: "12px",
      height: "12px",
      src: "".concat(_vm.imgBaseUrl, "/892941673955328"),
      fit: "cover"
    }
  }), _c("div", {
    staticClass: "text-[12px] text-[#999999]"
  }, [_vm._v(_vm._s(_vm.goodsSpec.saleDesc))])], 1)]), _c("div", {
    staticClass: "text-[14px] text-[#333333] font-bold two-line-ellipsis"
  }, [_vm._v(_vm._s(_vm.goodsSpec.goodsName))]), _c("div", {
    staticClass: "text-[14px] text-[#999999]"
  }, [_vm._v("加购规格：" + _vm._s(_vm.goodsSpec.goodsSpecName))])]), _c("div", {
    staticClass: "flex flex-col"
  }, [_c("div", {
    staticClass: "flex justify-center text-[14px] text-[#4d4d4d] my-[10px]"
  }, [_vm._v("— 商品详情 —")]), _vm._l(_vm.extractImgAttributes(_vm.goodsSpec.goodsDescription), function (item) {
    return _c("img", {
      key: item.src,
      style: item.style,
      attrs: {
        src: item.src
      }
    });
  })], 2)])], 1)])], 1)]);
};

var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };