var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return !_vm.appState.isInWechat ? _c("div", {
    staticClass: "qrcode-area mt2 center flex"
  }, [_c("van-image", {
    attrs: {
      src: _vm.trafficGuideEnterImg
    },
    on: {
      click: _vm.onClickTrafficGuideEnterImg
    }
  }), _c("van-popup", {
    staticClass: "tutorial-modal bg-transparent",
    attrs: {
      "close-on-click-overlay": false
    },
    model: {
      value: _vm.popupVisible,
      callback: function callback($$v) {
        _vm.popupVisible = $$v;
      },
      expression: "popupVisible"
    }
  }, [_c("a", {
    attrs: {
      href: _vm.trafficGuidePopImg,
      download: _vm.pubName
    },
    on: {
      click: _vm.onSaveQrcode
    }
  }, [_c("van-image", {
    staticClass: "tutorial",
    attrs: {
      src: _vm.trafficGuidePopImg,
      width: "13.2rem"
    }
  })], 1), _c("div", {
    staticClass: "close-btn absolute"
  }, [_c("IconFont", {
    attrs: {
      size: "20px",
      icon: "iconguanbi1",
      color: "white"
    },
    nativeOn: {
      click: function click($event) {
        _vm.popupVisible = false;
      }
    }
  })], 1)])], 1) : _vm._e();
};

var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };