import _objectSpread from "/root/workspace/dev-client-a/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import { mapGetters, mapState } from 'vuex';
import xEnum from 'base/utils/enum';
export default {
  props: {
    enableWxPay: {
      type: Boolean,
      default: false
    },
    enableAliPay: {
      type: Boolean,
      default: false
    },
    enableCloudPay: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      xEnum: xEnum,
      ENUM_PAYMENT_TYPE: xEnum.ENUM_PAYMENT_TYPE,
      payment: xEnum.ENUM_PAYMENT_TYPE.ALI.getValue(),
      showArrivePay: false,
      defaultWxPay: xEnum.DEFAULT_PAY_TYPE.WEIXIN.getValue(),
      defaultAlipayPay: xEnum.DEFAULT_PAY_TYPE.ALIPAY.getValue()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState({
    defaultPayType: function defaultPayType(state) {
      return state.appState.defaultPayType;
    }
  })), mapGetters({
    appState: 'appState'
  })), {}, {
    wxPayment: function wxPayment() {
      return {
        label: '微信支付',
        value: xEnum.ENUM_PAYMENT_TYPE.WX.getValue(),
        icon: 'https://img.bj0yx.com/901544176779265',
        tip: '安全方便',
        enable: this.enableWxPay && !this.appState.isInAliPay
      };
    },
    aliPayment: function aliPayment() {
      return {
        label: '支付宝支付',
        value: xEnum.ENUM_PAYMENT_TYPE.ALI.getValue(),
        icon: 'https://img.bj0yx.com/901544176779264',
        tip: '支持花呗分期',
        enable: this.enableAliPay && !this.appState.isInWechat
      };
    },
    yinlianPayment: function yinlianPayment() {
      return {
        label: '云闪付',
        value: xEnum.ENUM_PAYMENT_TYPE.CLOUD.getValue(),
        icon: 'https://img.bj0yx.com/905338958643200',
        tip: '方便快捷',
        enable: this.enableCloudPay && !this.appState.isInAliPay && !this.appState.isInWechat
      };
    },
    paymentList: function paymentList() {
      var list = [];
      if (this.defaultPayType === this.defaultWxPay) list = [this.wxPayment, this.aliPayment, this.yinlianPayment];else if (this.defaultPayType === this.defaultAlipayPay) list = [this.aliPayment, this.wxPayment, this.yinlianPayment];else list = [this.yinlianPayment, this.wxPayment, this.aliPayment];
      list = list.filter(function (item) {
        return item.enable;
      });
      if (list.length) this.onChange(list[0].value);
      return list;
    }
  }),
  methods: {
    onChange: function onChange(payment) {
      this.payment = payment;
      this.$emit('on-change', payment);
    }
  }
};