import _objectSpread from "/root/workspace/dev-client-a/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "vant/es/uploader/style";
import _Uploader from "vant/es/uploader";
import "vant/es/cell-group/style";
import _CellGroup from "vant/es/cell-group";
import "vant/es/cell/style";
import _Cell from "vant/es/cell";
import "vant/es/image-preview/style";
import _ImagePreview from "vant/es/image-preview";
import "vant/es/picker/style";
import _Picker from "vant/es/picker";
import "vant/es/rate/style";
import _Rate from "vant/es/rate";
import "vant/es/divider/style";
import _Divider from "vant/es/divider";
import "vant/es/checkbox/style";
import _Checkbox from "vant/es/checkbox";
import "vant/es/radio-group/style";
import _RadioGroup from "vant/es/radio-group";
import "vant/es/radio/style";
import _Radio from "vant/es/radio";
import "vant/es/overlay/style";
import _Overlay from "vant/es/overlay";
import "vant/es/area/style";
import _Area from "vant/es/area";
import "vant/es/field/style";
import _Field from "vant/es/field";
import "vant/es/form/style";
import _Form from "vant/es/form";
import "vant/es/popup/style";
import _Popup from "vant/es/popup";
import "vant/es/tag/style";
import _Tag from "vant/es/tag";
import "vant/es/button/style";
import _Button from "vant/es/button";
import "vant/es/loading/style";
import _Loading from "vant/es/loading";
import "vant/es/icon/style";
import _Icon from "vant/es/icon";
import "vant/es/switch/style";
import _Switch from "vant/es/switch";
import "vant/es/image/style";
import _Image from "vant/es/image";
import "vant/es/count-down/style";
import _CountDown from "vant/es/count-down";
import "vant/es/swipe-item/style";
import _SwipeItem from "vant/es/swipe-item";
import "vant/es/swipe/style";
import _Swipe from "vant/es/swipe";
import "vant/es/dialog/style";
import _Dialog from "vant/es/dialog";
import "vant/es/sticky/style";
import _Sticky from "vant/es/sticky";
import "vant/es/list/style";
import _List from "vant/es/list";
import "/root/workspace/dev-client-a/node_modules/core-js/modules/es.array.iterator.js";
import "/root/workspace/dev-client-a/node_modules/core-js/modules/es.promise.js";
import "/root/workspace/dev-client-a/node_modules/core-js/modules/es.object.assign.js";
import "/root/workspace/dev-client-a/node_modules/core-js/modules/es.promise.finally.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";

/* eslint-disable camelcase */
import Vue from 'vue';
import App from './App.vue';
import store from './store';
import VueRouter from 'vue-router';
import router from './router';
import './layout/rem';
import Cookies from 'js-cookie';
import 'basscss/css/basscss.min.css';
import 'animate.css';
import 'base/assets/styles/css/customAnimate.css';
import 'base/assets/styles/css/common.less';
import 'base/assets/styles/css/color.css';
import 'base/assets/font/iconfont.css';
import 'tailwindcss/tailwind.css'; // import '@/base/assets/styles/tailwindPresets/components/index.scss'

import { getBuryPointConfig } from 'base/api'; // 全局引入UI库 vant
// import 'base/plugins/vant'

import brand from 'base/brands';
import utils from 'base/utils';
import Exposure from 'base/utils/exposure';
import VueLazyload from 'vue-lazyload';
import { updatePidWhenRouteChange } from './utils/userState';
import { listenUnloadEvents } from 'base/utils/rp';
import { sendPageTrackEvent } from 'base/utils/quickTracking';
import SlsTracker from '@aliyun-sls/web-track-browser';
import xEnum from 'base/utils/enum';
Vue.use(require('vue-wechat-title'));
Vue.use(_List);
Vue.use(_Sticky);
Vue.use(_Dialog);
Vue.use(_Swipe);
Vue.use(_SwipeItem);
Vue.use(_CountDown);
Vue.use(_Image);
Vue.use(_Switch);
Vue.use(_Icon);
Vue.use(_Loading);
Vue.use(_Button);
Vue.use(_Tag);
Vue.use(_Popup);
Vue.use(_Form);
Vue.use(_Field);
Vue.use(_Area);
Vue.use(_Overlay);
Vue.use(_RadioGroup).use(_Radio);
Vue.use(_Checkbox);
Vue.use(_Divider);
Vue.use(_Rate);
Vue.use(_Picker);
Vue.use(_ImagePreview);
Vue.use(_Cell);
Vue.use(_CellGroup);
Vue.use(_Uploader);
Vue.config.productionTip = false;
Vue.use(VueLazyload, {
  preload: 1.3,
  //预加载的宽高
  attempt: 3,
  //尝试加载的次数
  listenEvents: ['scroll', 'wheel', 'mousewheel', 'resize', 'animationend', 'transitionend', 'touchmove'] //你想让vue监听的事件

});
Vue.prototype.basicImagePath = 'base/assets/';
Vue.prototype.cookie = Cookies;
Vue.prototype.renderBrandElement = brand.renderBrandElement;
Vue.prototype.globalPointName = brand.renderBrandElement({
  id: 'global.pointName'
});
Vue.prototype.globalExchangeWord = brand.renderBrandElement({
  id: 'global.exchangeWord'
});
Vue.prototype.imgBaseUrl = process.env.VUE_APP_STATIC_BASE_API; // Vue.prototype.GlobalLogo =
//   brand.renderBrandElement({ id: 'global.logo', type: 'image' }) || require(process.env.VUE_APP_GLOBAL_LOGO)

var currentSourceEnum = process.env.VUE_APP_SOURCE_ENUM;
var logStoreName = '';

if (currentSourceEnum == xEnum.ENUM_DATA_REPORT_SOURCES.DACU.getValue() || currentSourceEnum == xEnum.ENUM_DATA_REPORT_SOURCES.QIDESHENG.getValue()) {
  logStoreName = 'mall-log';
} else {
  logStoreName = 'mall-log-test';
}

var opts = {
  host: 'cn-shenzhen.log.aliyuncs.com',
  // 所在地域的服务入口。例如cn-hangzhou.log.aliyuncs.com
  project: 'bigdata-sls-prod',
  // Project名称。
  logstore: logStoreName,
  // Logstore名称。
  time: 10,
  // 发送日志的时间间隔，默认是10秒。
  count: 10,
  // 发送日志的数量大小，默认是10条。
  topic: 'mall',
  // 自定义日志主题。
  source: currentSourceEnum
};
var tracker = new SlsTracker(opts); // 创建SlsTracker对象

if (tracker) {
  window.aliTracker = tracker;
}
/**
 * 覆写vue-router的push方法，默认在query中添加pId参数
 * 用于在路由中显示的添加pId参数，以避免用户复制链接时丢掉pId的问题。
 * 如果后续不需要处理此场景，则可以去掉此方法
 */


var protoRouterPush = VueRouter.prototype.push;

if (protoRouterPush) {
  VueRouter.prototype.push = function push(location) {
    for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
      args[_key - 1] = arguments[_key];
    }

    return protoRouterPush.call.apply(protoRouterPush, [this, _objectSpread(_objectSpread({}, location), {}, {
      query: _objectSpread(_objectSpread({}, location.query), {}, {
        pId: store.state.appState.pId
      })
    })].concat(args));
  };
}

if (process.env.NODE_ENV !== 'production' || utils.getUrlParam('debug') === '1') {
  // 微信的vConsole
  // https://github.com/Tencent/vConsole/blob/dev/doc/tutorial_CN.md
  var VConsole = require('vconsole');

  new VConsole();
}

var startTime = 0;
router.beforeEach(function (to, from, next) {
  // console.log('router.beforeEach  from:', from.path, '; to.path: ', to.path, '; to.query: ', to.query)
  if (from.meta.keepAlive) {
    from.meta.savedPosition = document.documentElement.scrollTop;
  } // console.log(getPagePerformance())


  store.dispatch('getWebFingerPrint');
  var forwardPathStayTime = null;
  var forwardPath = (from === null || from === void 0 ? void 0 : from.path) || ''; // 前向来源页面

  if (startTime) {
    forwardPathStayTime = (Date.now() - startTime) / 1000;
  }

  startTime = Date.now();
  var _window = window,
      aliTracker = _window.aliTracker;

  if (aliTracker) {
    var forwardPathParams = {
      forward_path: forwardPath,
      forward_path_stay_time: forwardPathStayTime
    }; // 加入全局属性

    store.commit('UPDATE_APP_STATE', {
      key: 'forwardPathParams',
      value: forwardPathParams
    });
    var path = to.path;
    var toPath = to.fullPath;
    updatePidWhenRouteChange(path);
    sendPageTrackEvent(path, forwardPathParams, toPath);
  } // #app的 dom 变为可滚动，则每次路由跳转时，滚回顶部。


  var domApp = document.getElementById('app');
  domApp.scrollTop = 0; // next()

  next();
});
router.afterEach(function (to, from) {
  var customToMall = store.state.appState.backToCustomPageConfig.customToMall;

  if (customToMall && from.path === '/pointCustom') {
    // console.log('router.afterEach 置换状态 from:', from.path, '; to.path: ', to.path)
    store.commit('UPDATE_TO_CUSTOM_CONFIG', {
      key: 'customToMall',
      value: false
    });
  }
}); // router.afterEach((to, from) => {
//   wechatSdk.initJssdk([
//     'updateAppMessageShareData',
//     'updateTimelineShareData',
//     'onMenuShareAppMessage',
//     'onMenuShareTimeline'
//   ])
//   let shareUrl = utils.replaceUrlPid(xEnum.ENUM_SHARE_PIDS.DEFAULT.getValue())
//   let renderBrandElement = brand.renderBrandElement
//   let shareMsg = renderBrandElement({ id: 'weChat.shareMsg' })
//   let shareImage = renderBrandElement({ id: 'suspendShare.defaultImage', type: 'image' })
//   wechatSdk.setPageShare(
//     `${shareMsg}`,
//     `点击进入商城，超多优质品牌商品1折起`,
//     window.location.origin + shareImage,
//     shareUrl
//   )
// })

getBuryPointConfig().then(function (rpRes) {
  var _rpRes$data;

  var _ref = (_rpRes$data = rpRes === null || rpRes === void 0 ? void 0 : rpRes.data) !== null && _rpRes$data !== void 0 ? _rpRes$data : {},
      _ref$exposeDuration = _ref.exposeDuration,
      exposeDuration = _ref$exposeDuration === void 0 ? '300' : _ref$exposeDuration,
      _ref$exposeMinAreaRat = _ref.exposeMinAreaRate,
      exposeMinAreaRate = _ref$exposeMinAreaRat === void 0 ? 0.8 : _ref$exposeMinAreaRat,
      _ref$pushInterval = _ref.pushInterval,
      pushInterval = _ref$pushInterval === void 0 ? '2000' : _ref$pushInterval,
      _ref$queueSize = _ref.queueSize,
      queueSize = _ref$queueSize === void 0 ? 20 : _ref$queueSize,
      _ref$eventTypes = _ref.eventTypes,
      eventTypes = _ref$eventTypes === void 0 ? [] : _ref$eventTypes; // store.commit('UPDATE_BURY_POINT_CONFIG', {
  //   exposeDuration: Number(exposeDuration),
  //   exposeMinAreaRate,
  //   pushInterval: Number(pushInterval),
  //   queueSize,
  //   eventTypes
  // })


  window.localStorage.setItem('buryPointConfig', JSON.stringify({
    exposeDuration: Number(exposeDuration),
    exposeMinAreaRate: Number(exposeMinAreaRate),
    pushInterval: Number(pushInterval),
    queueSize: queueSize,
    eventTypes: eventTypes
  }));
  var exp = new Exposure();
  Vue.directive('track', {
    // 调用指令声明周期钩子函数bind，其他钩子函数请移步官网
    bind: function bind(el, binding) {
      // 获取指令参数
      var arg = binding.arg;
      arg.split('|').forEach(function (item) {
        // 曝光
        if (item === 'exposure') {
          exp.add({
            el: el
          });
        }
      });
    }
  }); // 监听浏览器关闭事件，上传埋点缓存队列数据

  listenUnloadEvents(exp.trackFromLocalStorage());
});
new Vue({
  router: router,
  render: function render(h) {
    return h(App);
  },
  store: store
}).$mount('#app');