import "core-js/modules/es.array.concat.js";
import brand from 'base/brands';
import store from '@/store';
import { sendQuickTrackingEvent, EventType } from 'base/utils/quickTracking';
var screenWidth = window.screen.width;
var widthHeightRatio = 5 / 6;
export default {
  name: 'PresentPointDialog',
  props: {},
  data: function data() {
    return {
      visible: true,
      imageWidth: screenWidth * (300 / 320),
      imageHeight: screenWidth * (300 / 320) / widthHeightRatio,
      textOffset: 165 * (screenWidth / 320),
      fontSize: 16 * (screenWidth / 320),
      pointTemplateImg: this.renderBrandElement({
        id: 'global.presentPointDialog',
        type: 'img'
      }),
      morePoints: store.state.appState.morePoints,
      activePoints: store.state.user.points.activePoints
    };
  },
  computed: {
    dislogOptions: function dislogOptions() {
      if (!this.isPresentAgain) {
        return {
          bgImage: this.pointTemplateImg || "".concat(this.imgBaseUrl, "/875173617598464")
        };
      } else {
        return {
          title: "\u606D\u559C\u518D\u6B21\u83B7\u5F97".concat(this.point),
          subTitle: "".concat(this.pointName, "\u53EF").concat(this.globalExchangeWord, "\u5546\u54C1"),
          bgImage: "".concat(this.imgBaseUrl, "/875173617598464")
        };
      }
    },
    pointName: function pointName() {
      try {
        return this.$store.state.appState.themeConfig['global.pointName'] || brand.renderBrandElement({
          id: 'global.pointName'
        });
      } catch (err) {
        console.log(err);
        return '积分';
      }
    }
  },
  created: function created() {},
  methods: {
    closeDialog: function closeDialog() {
      this.visible = false;

      if (this.isPresentAgain) {
        this.onClick();
      }
    },
    hide: function hide() {
      this.visible = false;
    },
    onClickBtn: function onClickBtn(e) {
      // 积分弹窗
      var getPointType = this.isPresentAgain ? '过期再次赠送积分' : '赠送积分';
      var pointValue = this.isPresentAgain ? this.morePoints : this.activePoints;
      sendQuickTrackingEvent({
        eventName: 'ducuecmc_hc_points_windows_clk',
        eventType: EventType.Click,
        pageName: 'dacuecmc_all_page',
        params: {
          point_value: pointValue,
          get_point_type: getPointType
        }
      }); // console.log(e)

      this.hide(); // 点击关闭按钮不触发后续逻辑

      if (e.target.id !== 'close' && e.target.parentNode.id !== 'close') {
        this.onClick();
      }
    }
  }
};