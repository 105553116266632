import _defineProperty from "/root/workspace/dev-client-a/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "vant/es/action-sheet/style";
import _ActionSheet from "vant/es/action-sheet";
import "core-js/modules/es.function.name.js";
export default {
  name: 'CouponActionSheet',
  components: _defineProperty({}, _ActionSheet.name, _ActionSheet),
  props: {
    couponList: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      visible: false,
      selectCouponIndex: 0
    };
  },
  computed: {},
  created: function created() {},
  methods: {
    show: function show() {
      this.visible = true;
    },
    close: function close() {
      this.visible = false;
    }
  }
};