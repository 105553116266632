import _objectSpread from "/root/workspace/dev-client-a/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.regexp.sticky.js";
import "core-js/modules/es.json.stringify.js";

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _vm.pId ? _c("div", {
    staticClass: "mx2",
    class: _vm.className
  }, [_vm.sticky ? _c("van-sticky", {
    directives: [{
      name: "track",
      rawName: "v-track:exposure",
      arg: "exposure"
    }],
    attrs: {
      id: _vm.stickyId,
      "track-params": JSON.stringify(_vm.getEventInfo("首页商品瀑布流tab"))
    },
    on: {
      scroll: _vm.onStickyScroll
    }
  }, [_c("TabFilter", {
    attrs: {
      "style-tab-title": _objectSpread({
        fontSize: "16px"
      }, _vm.tabFilterTitleColor ? {
        color: _vm.tabFilterTitleColor
      } : {}),
      tabs: _vm.groupTypeIds,
      "in-active-style": {
        background: "transparent"
      },
      "style-active-subtitle": _vm.styleActiveSubtitle,
      "style-wrap": {
        "background-color": _vm.isStickyFixed ? _vm.tabFilterBgColor : "transparent"
      }
    },
    on: {
      "on-tab-change": _vm.onTabChange
    }
  })], 1) : _c("div", {
    directives: [{
      name: "track",
      rawName: "v-track:exposure",
      arg: "exposure"
    }],
    attrs: {
      "track-params": JSON.stringify(_vm.getEventInfo("首页商品瀑布流tab"))
    }
  }, [_c("TabFilter", {
    attrs: {
      "style-tab-title": _objectSpread({
        fontSize: "16px"
      }, _vm.tabFilterTitleColor ? {
        color: _vm.tabFilterTitleColor
      } : {}),
      "style-tab": {
        width: "84px",
        height: "64px"
      },
      "has-normal-bubble-bg": true,
      tabs: _vm.groupTypeIds,
      "in-active-style": {
        background: "transparent"
      },
      "style-active-subtitle": _vm.styleActiveSubtitle,
      "style-wrap": {
        "background-color": _vm.isStickyFixed ? _vm.tabFilterBgColor : "transparent",
        padding: 0
      }
    },
    on: {
      "on-tab-change": _vm.onTabChange
    }
  })], 1), _c("GoodsWaterfallList", {
    ref: "waterfallGoodsList",
    attrs: {
      name: "首页推荐分类",
      "p-id": _vm.pId,
      type: _vm.groupTypeIds[_vm.activeTabIndex].groupTypeId
    }
  })], 1) : _vm._e();
};

var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };