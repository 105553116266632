import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.test.js";
import base from './config.base';
import duiduoduo from './config.duiduoduo';
import duihaoli from './config.duihaoli';
import jinridui from './config.jinridui';
import Vue from 'vue';
var currentBrand = process.env.VUE_APP_BRAND && process.env.VUE_APP_BRAND !== 'undefined' ? process.env.VUE_APP_BRAND : 'base';
var brands = {
  base: base,
  duiduoduo: duiduoduo,
  duihaoli: duihaoli,
  jinridui: jinridui
}; // 后端配置的主题

var serverlessConfig = {};
/**
 * type: 默认string， 支持['object', 'string', 'image']
 */

var renderBrandElement = function renderBrandElement(_ref) {
  var id = _ref.id,
      _ref$type = _ref.type,
      type = _ref$type === void 0 ? 'string' : _ref$type;
  var serverValue = serverlessConfig[id];
  var value = null;

  if (serverValue && serverValue !== '') {
    value = serverValue;
  } else {
    value = brands[currentBrand] ? brands[currentBrand][id] : null;
  }

  if ((type === 'object' || type === 'boolean') && value) {
    try {
      value = JSON.parse(value);
    } catch (err) {// console.log(err)
    }
  }

  var imgRegExp = /\.(png|jpg|gif|jpeg|svg)$/; // 校验图片

  var urlRegExp = /^(http(s?)|):\/\/(.+)$/; // 校验是否为网址

  if (type === 'image' || value && imgRegExp.test(value)) {
    if (value && urlRegExp.test(value)) {
      return value;
    }

    return require("base/assets/img/".concat(value));
  }

  return value;
};

var load = function load(config) {
  serverlessConfig = config || {};
  Vue.prototype.globalPointName = renderBrandElement({
    id: 'global.pointName'
  });
  Vue.prototype.globalExchangeWord = renderBrandElement({
    id: 'global.exchangeWord'
  });
};

export default {
  renderBrandElement: renderBrandElement,
  load: load
};