import _objectSpread from "/root/workspace/dev-client-a/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.number.constructor.js";
import { mapState } from 'vuex';
export default {
  name: 'PointNoticeMask',
  props: {
    /**
     * 滚动到某个位置隐藏该组件，当为0时则不隐藏
     */
    hiddenOffset: {
      type: Number,
      default: 0
    },
    topOffset: {
      type: Number,
      default: 0
    }
  },
  data: function data() {
    return {
      hidden: false
    };
  },
  computed: _objectSpread({}, mapState({
    points: function points(state) {
      return state.user.points;
    }
  })),
  created: function created() {},
  mounted: function mounted() {
    window.addEventListener('scroll', this.handleScroll, true);
  },
  destroyed: function destroyed() {
    window.removeEventListener('scroll', this.handleScroll, true);
  },
  methods: {
    handleScroll: function handleScroll(e) {
      if (this.hiddenOffset === 0) {
        return;
      }

      var scrollTop = e.target.documentElement && e.target.documentElement.scrollTop || e.target.body && e.target.body.scrollTop; // console.log(scrollTop)

      if (scrollTop > 200) {
        this.hidden = true;
      } else {
        this.hidden = false;
      }
    }
  }
};