import { IconTag } from 'components';
export default {
  name: 'ServiceIntro',
  components: {
    IconTag: IconTag
  },
  props: {
    sourceList: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {};
  }
};