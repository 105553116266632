import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.object.to-string.js";
import exapi from 'base/api/exapi';
import brand from 'base/brands';
/* eslint-disable */

var PKG_NAME = 'com.tpyx.quickapp';
var PATH = 'Home';

function initMessage(store) {
  if (!window.system) return;

  window.system.onmessage = function (data) {
    var obj = null;

    try {
      obj = JSON.parse(data);
      var _obj = obj,
          type = _obj.type,
          success = _obj.success,
          content = _obj.content;

      if (!success) {// 如果失败，则 content 返回 {error, code}
        // alert(content.error)
      } else {
        // alert(data)
        if (type === 'hasPackage') {
          store.commit('UPDATE_APP_STATE', {
            key: 'taobaoInstalled',
            value: content.result
          });
        } else if (type === 'getUserInfo') {
          var provider = content.provider,
              regId = content.regId,
              h5UserId = content.userId,
              pId = content.pId;
          exapi.postRegInfo({
            regId: regId,
            h5UserId: h5UserId,
            provider: provider,
            pId: pId
          });
        } else if (type === 'hasShortcut') {
          store.commit('UPDATE_APP_STATE', {
            key: 'hasShortcut',
            value: !content ? true : content.result
          });
        } else if (type === 'createShortcut') {
          store.commit('UPDATE_APP_STATE', {
            key: 'hasShortcut',
            value: true
          });
        } else if (type === 'share') {
          trackEvent('确认分享', {
            action: '总数'
          });
        }
      }
    } catch (err) {// alert(err)
    }
  };
}

var sendMessageToQuickApp = function sendMessageToQuickApp(type, content) {
  try {
    system.postMessage(JSON.stringify({
      type: type,
      content: content
    }));
  } catch (err) {// console.log('不支持快应用')
  }
};

var promisefyGetQuickAppChannelReady = function promisefyGetQuickAppChannelReady() {
  return new Promise(function (resolve, reject) {
    if (!window.channelReady) {
      resolve(false);
    }

    window.channelReady(function (enable) {
      resolve(enable);
    }, function (err) {
      resolve(false);
    });
  });
};

var saveUserIdToQuickApp = function saveUserIdToQuickApp(userId) {
  sendMessageToQuickApp('setUserId', userId);
};

var renderBrandElement = brand.renderBrandElement;
var quickBrand = renderBrandElement({
  id: 'global.quickBrand'
});

var createQuickAppShortcut = function createQuickAppShortcut() {
  sendMessageToQuickApp('createShortcut', {
    disableDefaultPromot: true,
    message: "\u6DFB\u52A0\u300C".concat(quickBrand, "\u300D\u5230\u684C\u9762\uFF0C\u9886100\u5143\u4F18\u60E0\u5238>>")
  });
};
/**
 * 查看是否已创建快应用桌面图标
 */


var isShortcutInstalled = function isShortcutInstalled() {
  sendMessageToQuickApp('hasShortcut');
};

var trackEvent = function trackEvent(id, attr) {
  sendMessageToQuickApp('trackEvent', {
    id: id,
    attr: attr
  });
};

var pickContact = function pickContact() {
  sendMessageToQuickApp('pickContact');
};

var listContact = function listContact() {
  sendMessageToQuickApp('listContact');
};

var sendSms = function sendSms(address, content) {
  sendMessageToQuickApp('sendSms', {
    address: address,
    content: content
  });
};

var openWebview = function openWebview(url) {
  sendMessageToQuickApp('openWebview', {
    url: url
  });
};
/**
 *
 * @param {*} type 'text/html'
 * @param {*} data '<p>test</p>'
 */


var share = function share(type, data) {
  sendMessageToQuickApp('share', {
    type: type,
    data: data
  });
};

var saveToStorage = function saveToStorage(key, value) {
  sendMessageToQuickApp('saveToStorage', {
    key: key,
    value: value
  });
};

var getFromStorage = function getFromStorage(key) {
  sendMessageToQuickApp('getFromStorage', {
    key: key
  });
};

var getUserInfo = function getUserInfo() {
  sendMessageToQuickApp('getUserInfo');
};

var hasPackage = function hasPackage(packageName) {
  sendMessageToQuickApp('hasPackage', {
    packageName: packageName
  });
};

var routerPush = function routerPush(router) {
  sendMessageToQuickApp('routerPush', router);
};

var installPackage = function installPackage(packageName) {
  sendMessageToQuickApp('installPackage', {
    packageName: packageName
  });
};

export default {
  initMessage: initMessage,
  saveUserIdToQuickApp: saveUserIdToQuickApp,
  createQuickAppShortcut: createQuickAppShortcut,
  promisefyGetQuickAppChannelReady: promisefyGetQuickAppChannelReady,
  trackEvent: trackEvent,
  pickContact: pickContact,
  listContact: listContact,
  sendSms: sendSms,
  openWebview: openWebview,
  share: share,
  saveToStorage: saveToStorage,
  getFromStorage: getFromStorage,
  getUserInfo: getUserInfo,
  isShortcutInstalled: isShortcutInstalled,
  hasPackage: hasPackage,
  routerPush: routerPush,
  installPackage: installPackage
};