var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "com-goods-list",
    style: _vm.styleWrap
  }, [_vm._t("header"), _c("div", [_c("VanList", {
    staticClass: "flex flex-wrap justify-between",
    attrs: {
      finished: _vm.finished,
      "loading-text": "商品加载中...",
      "finished-text": "我也是有底线的~"
    },
    on: {
      load: _vm.onLoad
    },
    model: {
      value: _vm.pullLoading,
      callback: function callback($$v) {
        _vm.pullLoading = $$v;
      },
      expression: "pullLoading"
    }
  }, [_vm.goodsItem == "returnScore" ? _c("div", {
    staticStyle: {
      width: "100%"
    }
  }, _vm._l(_vm.goodsList, function (item, index) {
    return _c("GoodsReturnScoreItem", {
      key: index,
      attrs: {
        source: item,
        index: index,
        type: _vm.type,
        "on-click-item": _vm.onClickItem
      }
    });
  }), 1) : _vm._e(), _vm.goodsItem == "timeLimit" ? _c("div", {
    staticStyle: {
      width: "100%"
    }
  }, _vm._l(_vm.goodsList, function (item, index) {
    return _c("GoodsTimeLimitItem", {
      key: index,
      attrs: {
        source: item,
        index: index,
        type: _vm.type,
        "on-click-item": _vm.onClickItem
      }
    });
  }), 1) : _vm._e(), _vm.goodsItem == "default" ? _c("div", {
    staticClass: "flex flex-wrap justify-between"
  }, _vm._l(_vm.goodsList, function (item, index) {
    return _c("GoodsNormalItem", {
      key: index,
      attrs: {
        source: item,
        index: index,
        type: _vm.type,
        "on-click-item": _vm.onClickItem,
        "page-source": _vm.pageSource,
        "item-style": {
          width: "calc((100% - 3%) / 2)"
        }
      }
    });
  }), 1) : _vm._e()]), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.pullLoading,
      expression: "pullLoading"
    }],
    staticClass: "loading-container flex flex-column items-center"
  }, [_c("img", {
    staticStyle: {
      "margin-top": "10vh"
    },
    attrs: {
      width: "100",
      src: _vm.renderBrandElement({
        id: "goodsList.loading",
        type: "image"
      })
    }
  }), _c("span", {
    staticClass: "loading-container-text",
    style: _vm.styleLoadingText
  }, [_vm._v("更多超值商品加载中")])])], 1)], 2);
};

var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };