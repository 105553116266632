import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import GoodsWaterfallList from './GoodsWaterfallList';
import GoodsSeckillItemThemed from './ListItem/GoodsSeckillItemThemed';
import GoodsNormalItem from './ListItem/GoodsNormalItem';
import xEnum from 'base/utils/enum';
var itemMap = {
  point: GoodsNormalItem,
  seckill: GoodsSeckillItemThemed
};
export default {
  components: {
    GoodsWaterfallList: GoodsWaterfallList
  },
  props: {
    name: {
      type: String,
      default: '未知'
    },
    iconName: {
      type: String,
      default: 'icongouwu'
    },
    iconColor: {
      type: String,
      default: '#E15658'
    },
    title: {
      type: String,
      default: '猜你喜欢'
    },
    className: {
      type: String,
      default: ''
    },
    groupTypeIds: {
      type: Array,
      default: function _default() {
        return [{
          groupTypeId: xEnum.ENUM_GOODS_GROUP_TYPE.GUESS_YOUR_FAVOR_DISTRIBUTE_XIHUAN_GROUP.value
        }, {
          groupTypeId: xEnum.ENUM_GOODS_GROUP_TYPE.GUESS_YOUR_FAVOR_DISTRIBUTE_JINGXUAN_GROUP.value
        }, {
          groupTypeId: xEnum.ENUM_GOODS_GROUP_TYPE.GUESS_YOUR_FAVOR_DISTRIBUTE_YANXUAN_GROUP.value
        }, {
          groupTypeId: xEnum.ENUM_GOODS_GROUP_TYPE.GUESS_YOUR_FAVOR_DISTRIBUTE_YOUXUAN_GROUP.value
        }];
      }
    },
    sticky: {
      type: Boolean,
      default: false
    },
    enableAutoScroll: {
      type: Boolean,
      default: true
    },
    tabFilterBgColor: {
      type: String,
      default: 'white'
    },
    tabFilterTitleColor: {
      type: String,
      default: null
    },
    inActiveStyle: {
      type: Object,
      default: function _default() {}
    },
    styleActiveSubtitle: {
      type: Object,
      default: function _default() {}
    },
    styleLoadingText: {
      type: Object,
      default: function _default() {}
    }
  },
  data: function data() {
    return {
      goodsList: [],
      loading: false,
      tabs: this.renderBrandElement({
        id: 'guessYourFavor.tabs',
        type: 'object'
      }),
      activeTabIndex: 0,
      // 猜你喜欢改版，只显示一个类，暂时保留老逻辑
      // groupTypeId: xEnum.ENUM_GOODS_GROUP_TYPE.GUESS_YOUR_FAVOR_DISTRIBUTE_XIHUAN_GROUP.value,
      isStickyFixed: false,
      goodsItem: itemMap[this.renderBrandElement({
        id: 'guessYourFavor.listItemType'
      }) || 'point']
    };
  },
  computed: {
    pId: function pId() {
      return this.$store.state.appState.pId;
    },
    mergedTabs: function mergedTabs() {
      return _.merge(this.tabs, this.groupTypeIds);
    },
    stickyId: function stickyId() {
      return "stickyZone-".concat(this.name);
    }
  },
  created: function created() {
    if (this.pId) {
      this.onTabChange(0, false);
    }
  },
  methods: {
    // async fetchGoodsList(type) {
    //   try{
    //     this.loading = true
    //     const response = await getGoodList({
    //       pId: this.pId,
    //       type,
    //     })
    //     this.goodsList = response.data ? response.data.goodsList : []
    //   } catch (err) {
    //     console.log(err);
    //   }
    //   this.loading = false
    // },
    onClickGoodsListMore: function onClickGoodsListMore() {
      this.$emit('click-more');
    },
    onTabChange: function onTabChange(index) {
      // 每次切换tab都要将finished和pageIndex重置
      this.goodsList = [];
      this.loading = true;

      if (this.$refs.waterfallGoodsList) {
        this.$refs.waterfallGoodsList.resetOnloadSetting();
      }

      this.loading = false;
      this.activeTabIndex = index;
    },
    loadGoodsList: function loadGoodsList(dataList) {
      var _this = this;

      dataList.forEach(function (item) {
        _this.goodsList.push(item);
      });
    },
    onStickyScroll: function onStickyScroll(_ref) {
      var isFixed = _ref.isFixed;
      this.isStickyFixed = isFixed;
    },
    resetOnloadSetting: function resetOnloadSetting() {
      this.$refs.waterfallGoodsList.resetOnloadSetting();
    }
  }
};